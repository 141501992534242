import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import CaddieStream from '../stream/CaddieStream';
import { useHospitalsList } from '../../aceapi/aceComponents';
import { useAceApp } from '../Menu/ReportAppSelector';

export default function ProcedureDataGrid({
    rows,
    pageSize,
    pin,
    extraColumns = [],
    removeColumns = [],
    sortable = true,
    unpinMode = false,
    streaming = false,
    onRowClick = null,
    ...props
}) {
    const { app } = useAceApp();
    const disableHospitalName = app === 'all';
    const isSorted = (name) => props?.sortModel?.find((x) => x.field === name) !== undefined;
    const { data: hospitals } = useHospitalsList({ queryParams: { app } }, { enabled: !disableHospitalName });

    const getHospitalName = (username) => {
        const match = username.match(/^\w+-(\w+)-\d+$/);
        if (match) {
            const acronym = match[1];
            const hospital = (hospitals ?? []).find((x) => x.acronym === acronym);
            if (hospital) {
                return hospital.name;
            }
        }

        return null;
    };

    const resultColumns = [
        {
            field: 'database',
            headerName: 'App',
            flex: 0.2,
            minWidth: 80,
            sortable: sortable || isSorted('database'),
            valueFormatter: (x) => (x.value ? x.value.toUpperCase() : 'UNKNOWN'),
        },
        {
            field: 'product',
            headerName: 'Product',
            flex: 0.2,
            minWidth: 80,
            sortable: sortable || isSorted('product'),
            valueFormatter: (x) => (x.value ? x.value.toUpperCase() : 'UNKNOWN'),
        },
        {
            field: 'patient_id',
            headerName: 'Patient ID',
            flex: 0.7,
            minWidth: 130,
            sortable: isSorted('patient_id'),
        },
        {
            field: 'username',
            headerName: 'User',
            flex: 0.5,
            minWidth: 130,
            sortable: sortable || isSorted('username'),
            renderCell: (x) => (
                <Stack direction='column' spacing={0}>
                    <Typography variant='body2'>{x.value}</Typography>
                    {!disableHospitalName && <Typography variant='caption'>{getHospitalName(x.value)}</Typography>}
                </Stack>
            ),
        },
        ...(Array.isArray(extraColumns)
            ? extraColumns.map((x) => ({
                  field: x.field,
                  headerName: x.headerName,
                  flex: x.flex || 0.5,
                  minWidth: x.minWidth || 130,
                  sortable: sortable || isSorted(x.field),
                  ...(x.valueFormatter ? { valueFormatter: x.valueFormatter } : {}),
                  ...(x.renderCell ? { renderCell: x.renderCell } : {}),
              }))
            : []),
        {
            field: 'start',
            headerName: 'Start',
            flex: 0.5,
            minWidth: 90,
            type: 'number',
            valueFormatter: (x) => (x.value ? new Date(x.value).toLocaleString('en-GB') : 'NULL'),
            sortable: sortable || isSorted('start'),
        },
        ...(streaming
            ? []
            : [
                  {
                      field: 'end',
                      headerName: 'End',
                      flex: 0.5,
                      minWidth: 90,
                      type: 'number',
                      valueFormatter: (x) => (x.value ? new Date(x.value).toLocaleString('en-GB') : 'NULL'),
                      sortable: sortable || isSorted('end'),
                  },
              ]),
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.3,
            minWidth: 80,
            sortable: sortable || isSorted('status'),
        },
        {
            field: 'mode',
            headerName: 'Mode',
            flex: 0.5,
            minWidth: 110,
            sortable: sortable || isSorted('mode'),
        },
        {
            field: 'procedure_id',
            headerName: 'Open',
            flex: 0.2,
            minWidth: 80,
            renderCell: (x) => (
                <Button variant='contained' href={`/${x.row.database}/procedures/${x.value}`}>
                    Open
                </Button>
            ),
            sortable: false,
        },
        ...(Array.isArray(pin?.procs)
            ? [
                  {
                      field: unpinMode ? 'unpin' : 'pin',
                      headerName: unpinMode ? 'Unpin' : 'Pin',
                      flex: 0.2,
                      minWidth: 80,
                      renderCell: (x) => (
                          <Button
                              variant='contained'
                              onClick={() =>
                                  unpinMode || pin.procs.find((y) => y.procedure_id === x.id)
                                      ? pin.removeProcedure(x.id)
                                      : pin.addProcedure(x.row)
                              }
                              color={unpinMode || pin.procs.find((y) => y.procedure_id === x.id) ? 'error' : 'success'}
                          >
                              {unpinMode || pin.procs.find((y) => y.procedure_id === x.id) ? 'Unpin' : 'Pin'}
                          </Button>
                      ),
                      sortable: false,
                  },
              ]
            : []),
        ...(streaming
            ? [
                  {
                      field: 'stream',
                      headerName: 'Stream',
                      flex: 0.5,
                      minWidth: 128,
                      valueGetter: (params) => params.row,
                      renderCell: (x) => (
                          <CaddieStream app={x.value.database} uuid={x.value.procedure_id} expandOnClick />
                      ),
                  },
              ]
            : []),
    ];

    const filteredColumns = resultColumns.filter((x) => !removeColumns.includes(x.field));

    return (
        <DataGrid
            rowHeight={streaming ? 128 : 52}
            rows={rows}
            columns={filteredColumns}
            getRowId={(row) => row.procedure_id}
            autoHeight
            pageSize={pageSize}
            rowsPerPageOptions={[pageSize]}
            disableSelectionOnClick
            onRowClick={onRowClick}
            {...props}
        />
    );
}
