import { SUMMARY_STATS } from '../SummaryMain';
import { useMemo } from 'react';
import { timedelta } from '../utils';

export default function SummaryStat(props) {
    const { params: inputParams, hideDownload = false, ...other } = props;
    const name = other.name ?? inputParams.statType ?? 'counts';
    const type = other.type ?? inputParams.groupType ?? 'user';

    const SummaryComponent = useMemo(() => SUMMARY_STATS[name].component, [name]);

    const params = useMemo(
        () => ({
            showDownload: !hideDownload,
            showTest: !inputParams.test,
            recording: inputParams.recording,
            durationRange: inputParams.duration,
            statuses: inputParams.status,
            modes: inputParams.modes,
            recentOption: inputParams.recentOption,
            fromDate: inputParams.dateFrom?.getTime(),
            toDate: inputParams.dateTo?.getTime(),
            period: inputParams.period ? inputParams.period * timedelta.DAY : null,
            extraColumn: inputParams.extraColumn,
        }),
        [
            hideDownload,
            inputParams.dateFrom,
            inputParams.dateTo,
            inputParams.duration,
            inputParams.extraColumn,
            inputParams.modes,
            inputParams.period,
            inputParams.recentOption,
            inputParams.recording,
            inputParams.status,
            inputParams.test,
        ],
    );

    return <SummaryComponent type={type} params={params} />;
}
