import { AsyncBoundary } from '../../aceapi/utils';
import ForceSelectApp from '../report/ForceSelectApp';
import useStickyState from '../report/useStickyState';
import { useDashboardsList } from '../../aceapi/aceComponents';
import { Skeleton } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import AceDashboard from './AceDashboard';

function AsyncAceDashboardsMain() {
    const { data: dashboards } = useDashboardsList({});
    const [tab, setTab] = useStickyState('aceDashboardsTab', dashboards?.[0]?.id ?? 1);

    return (
        <TabContext value={tab}>
            <TabList onChange={(e, v) => setTab(v)} centered>
                {dashboards?.map(({ id, name }) => (
                    <Tab key={id} value={id} label={name} />
                ))}
            </TabList>
            {dashboards?.map(({ id }) => (
                <TabPanel key={id} value={id}>
                    <AceDashboard dashboard_id={id} />
                </TabPanel>
            ))}
        </TabContext>
    );
}

export default function AceDashboardsMain(props) {
    return (
        <ForceSelectApp>
            <AsyncBoundary fallback={<Skeleton variant='rectangular' height={1200} />}>
                <AsyncAceDashboardsMain {...props} />
            </AsyncBoundary>
        </ForceSelectApp>
    );
}
