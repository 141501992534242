import useFilteredSummaryData from './useFilteredSummaryData';
import { useMemo } from 'react';
import GroupedProcedureTables from '../widgets/GroupedProcedureTables';
import { useExtraList } from '../../../aceapi/aceComponents';
import { useAceApp } from '../../Menu/ReportAppSelector';

export default function SummaryTables({ type, params = {} }) {
    const { app } = useAceApp();
    const { data: extraAnnotations } = useExtraList({ queryParams: { app } });

    const { filteredProcs, filteredGroups } = useFilteredSummaryData({ type, params });

    const { extraColumn } = params;

    const data = useMemo(() => {
        const dtformat = new Intl.DateTimeFormat('en-GB', { timeZone: 'Europe/London', timeStyle: 'long' });
        const get_annotation = (proc, annotation) =>
            extraAnnotations.find((ea) => ea.procedure_id === proc.procedure_id && ea.annotation === annotation)?.[
                'value'
            ] ?? 'Err';

        return filteredGroups
            .map((group) => {
                let groupProcs = filteredProcs.filter((p) => group.users.includes(p.username));
                if (extraColumn >= 1 && extraColumn <= 4) {
                    const key = ['bad_net', 'det_off', 'bad_vm', 'time'][extraColumn - 1];
                    groupProcs = groupProcs.map((proc) => ({
                        ...proc,
                        [key]: extraColumn === 4 ? dtformat.format(proc.start) : get_annotation(proc, key),
                    }));
                }

                return {
                    procs: groupProcs,
                    count: groupProcs.length,
                    ...group,
                };
            })
            .filter((x) => x.count > 0)
            .sort((x, y) => y.count - x.count);
    }, [extraAnnotations, extraColumn, filteredGroups, filteredProcs]);

    return <GroupedProcedureTables data={data} extraColumnId={extraColumn} />;
}
