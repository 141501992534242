import { MetricsGrid, quantitativeMetricsAgainstHistology } from './OpticalDiagnosisUtils';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { Box, Typography } from '@mui/material';

const renderCell = (params) => {
    const val =
        100 *
        (typeof params.value === 'number' && params.value % 1 !== 0
            ? (Math.round(params.value * 100) / 100).toFixed(2)
            : params.value);
    return (
        <Box
            sx={{
                border: '1px solid #e0e0e0',
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                height: 26,
            }}
        >
            <Typography
                sx={{
                    position: 'absolute',
                    lineHeight: '24px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {`${val.toLocaleString()} %`}
            </Typography>
            <Box
                sx={{
                    height: '100%',
                    backgroundColor: val < 30 ? '#f44336' : val <= 70 ? '#efbb5aa3' : '#088208a3',
                    maxWidth: `${val}%`,
                }}
            />
        </Box>
    );
};

const METRICS_COLS = [
    { field: 'Metric', headerName: 'Histology VS', flex: 0.5 },
    { field: 'Overall', headerName: 'Overall', flex: 0.5, renderCell },
    { field: 'Sensitivity', headerName: 'Sensitivity', flex: 0.5, renderCell },
    { field: 'Specificity', headerName: 'Specificity', flex: 0.5, renderCell },
    { field: 'CADDIE Correct', headerName: 'CADDIE Correct', flex: 0.5 },
    { field: 'CADDIE Incorrect', headerName: 'CADDIE Incorrect', flex: 0.5 },
];

/**
 * Compare Histology to CADDIE (ie ACE's .cadx_diagnosis), and Human 2nd OD (ie ACE's .expert_diagnosis)
 */
export function Rawen() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });

    const POLYP_FILTERS = [
        {
            name: 'Overall',
            compute: ({ tp, tn, total }) => (tp + tn) / total,
        },
        {
            name: 'Sensitivity',
            compute: ({ tp, fn }) => tp / (tp + fn),
        },
        {
            name: 'Specificity',
            compute: ({ fp, tn }) => tn / (tn + fp),
        },
        {
            name: 'CADDIE Correct',
            compute: ({ tp, tn }) => tp + tn,
        },
        {
            name: 'CADDIE Incorrect',
            compute: ({ fp, fn }) => fp + fn,
        },
    ];
    const quantitativeMetrics = {
        Caddie: { polypFilter: (polyp) => polyp.cadx_diagnosis },
        'Human 2nd Optical Diagnosis': { polypFilter: (polyp) => polyp.expert_diagnosis },
    };
    const rows = Object.keys(quantitativeMetrics).reduce((acc, Metric, id) => {
        acc[id] = { id, Metric };
        return acc;
    }, []);

    POLYP_FILTERS.forEach(({ name, compute }) => {
        for (let i = 0; i < rows.length; i++) {
            const filter = quantitativeMetrics[rows[i]['Metric']].polypFilter;
            const metrics = quantitativeMetricsAgainstHistology(explore.ace_histo, filter, 'adenoma', 'non-adenoma');
            rows[i][name] = compute(metrics);
        }
    });
    return <MetricsGrid name='Overall' rows={rows} columns={METRICS_COLS} />;
}
