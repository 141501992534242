import Grid from '@mui/material/Grid';
import { SelectElement } from 'react-hook-form-mui';

export default function PreAssessmentTypeSelect({ caseTypes, onChange }) {
    return (
        <Grid container spacing={3} sx={{ width: '100%' }}>
            <Grid item xs={12}>
                <SelectElement
                    name='case_type'
                    label='Case Assessment Type'
                    options={caseTypes}
                    sx={{ width: 'calc(max(25%, 200px))' }}
                    onChange={onChange}
                />
            </Grid>
        </Grid>
    );
}
