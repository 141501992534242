import { withAsyncBoundary } from '../../../aceapi/utils';
import { useAssessmentsCasesRead, useProceduresLatest, useProceduresRead } from '../../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import useCaseMgr from '../hooks/useCaseMgr';
import { useAceApp } from '../../Menu/ReportAppSelector';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import ProcedureDataGrid from '../../search/ProcedureDataGrid';
import { useCallback, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import useShow from '../../../aceapi/hooks/useShow';

function CaseAssessmentLink(props) {
    const { id } = useParams();
    const { app } = useAceApp();
    const show = useShow();

    const { handleNext, handleBack } = props;

    const { data: caseData } = useAssessmentsCasesRead({ pathParams: { id } });
    const { fetchCaseByProcedureId, linkCase } = useCaseMgr();

    const [selectedProcedure, setSelectedProcedure] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const { data: latestProcs } = useProceduresLatest({ queryParams: { app } }, { refetchInterval: 5000 });

    const { data: procData } = useProceduresRead(
        {
            pathParams: { procedureId: caseData.procedure_id },
            queryParams: { app },
        },
        { enabled: !!caseData.procedure_id },
    );

    const onRowClick = useCallback(
        async (params) => {
            const { id: procedureId } = params;
            const existingCase = await fetchCaseByProcedureId(procedureId);
            if (existingCase?.procedure_id && caseData?.id !== existingCase.id) {
                alert('This procedure is already linked to another case');
            } else if (procedureId !== caseData.procedure_id) {
                setSelectedProcedure(procedureId);
                setConfirmOpen(true);
            }
        },
        [caseData?.id, caseData.procedure_id, fetchCaseByProcedureId],
    );

    const handleLinkCase = useCallback(
        async (procedureId) => {
            await linkCase(id, procedureId);
        },
        [id, linkCase],
    );

    const procName = useMemo(() => {
        if (procData) {
            return `${procData.patient_id ?? procData.procedure_id} (${procData.username})`;
        }
        if (caseData?.procedure_id) {
            return caseData.procedure_id;
        }
        return 'None';
    }, [procData, caseData]);

    return (
        <Stack spacing={2}>
            <Typography variant='h5' color='primary' border={1} borderRadius={1} p={2} align='center'>
                <b>Currently selected: {procName}</b>
            </Typography>
            <ProcedureDataGrid
                rows={latestProcs?.procedures ?? []}
                pageSize={5}
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                sortable={false}
                sortingMode='server'
                onRowClick={onRowClick}
                streaming={show.stream}
                removeColumns={['procedure_id']}
            />
            <Dialog
                className='confirmDialog'
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
                open={confirmOpen}
            >
                <DialogTitle>Confirm assessment link</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to link with procedure {selectedProcedure}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className='cancelButton' autoFocus onClick={() => setConfirmOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        className='confirmButton'
                        onClick={async () => {
                            await handleLinkCase(selectedProcedure);
                            setConfirmOpen(false);
                            handleNext();
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Stack spacing={2} direction='row'>
                <Button onClick={handleBack}>Back</Button>
                <Button variant='contained' onClick={handleNext} disabled={!caseData.procedure_id}>
                    Next
                </Button>
            </Stack>
        </Stack>
    );
}

export default withAsyncBoundary(CaseAssessmentLink);
