import { withAsyncBoundary } from '../../aceapi/utils';
import useCaseMgr from './hooks/useCaseMgr';
import { Button, Stack } from '@mui/material';
import CaseAssessmentList from './CaseAssessmentList';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useNavigate } from 'react-router-dom';

function CaseAssessmentMain() {
    const { createCase } = useCaseMgr();

    const navigate = useNavigate();

    return (
        <Stack spacing={2}>
            <Box display='flex' justifyContent='center' p={2}>
                <Button
                    onClick={async () => {
                        const caseData = await createCase();
                        navigate(`/assessments/${caseData.id}`);
                    }}
                    variant='contained'
                    size='large'
                    color='primary'
                    startIcon={<PostAddIcon />}
                >
                    Create new Case
                </Button>
            </Box>
            <Typography variant='h6'>Previous Cases:</Typography>
            <CaseAssessmentList />
        </Stack>
    );
}

export default withAsyncBoundary(CaseAssessmentMain);
