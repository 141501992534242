import { Button, Stack } from '@mui/material';
import { withAsyncBoundary } from '../../../aceapi/utils';
import {
    useAssessmentsAssessmentFieldsList,
    useAssessmentsCasesGetAssessmentAnswers,
    useAssessmentsCasesRead,
} from '../../../aceapi/aceComponents';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import useCaseMgr from '../hooks/useCaseMgr';
import AssessmentCommonFields from '../common/AssessmentCommonFields';
import DoneAllIcon from '@mui/icons-material/DoneAll';

function PostAssessmentForm(props) {
    const { id } = useParams();
    const { handleBack } = props;

    const navigate = useNavigate();

    const { data: currentCase } = useAssessmentsCasesRead({ pathParams: { id } });

    const { updateAssessmentAnswers } = useCaseMgr();

    const { data: fields } = useAssessmentsAssessmentFieldsList({
        queryParams: { stage: 'POST' },
    });
    const { data: answers } = useAssessmentsCasesGetAssessmentAnswers({
        pathParams: { id },
        queryParams: { stage: 'POST' },
    });

    const commonFieldsDefaultValues = useMemo(
        () => Object.fromEntries(answers?.map(({ field_key, value }) => [field_key, value]) ?? []),
        [answers],
    );

    const formContext = useForm({
        defaultValues: {
            ...commonFieldsDefaultValues,
        },
    });
    const { handleSubmit } = formContext;
    const onSubmit = useCallback(
        async (data) => {
            await updateAssessmentAnswers(id, data);
        },
        [updateAssessmentAnswers, id],
    );

    const debouncedSubmit = useMemo(() => debounce(onSubmit, 500), [onSubmit]);

    return (
        <Stack spacing={2}>
            <FormContainer formContext={formContext}>
                <Stack spacing={2} p={1}>
                    <AssessmentCommonFields fields={fields} onChange={handleSubmit(debouncedSubmit)} xs={12} />
                </Stack>
            </FormContainer>
            <Stack spacing={2} direction='row' alignItems='center'>
                <Button onClick={handleBack}>Back</Button>
                <Button
                    variant='contained'
                    onClick={() => handleSubmit(onSubmit)().then(() => navigate('/assessments'))}
                    disabled={currentCase.type === 0}
                    startIcon={<DoneAllIcon />}
                >
                    Finish
                </Button>
            </Stack>
        </Stack>
    );
}

export default withAsyncBoundary(PostAssessmentForm);
