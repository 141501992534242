import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { SUMMARY_INPUTS } from '../StatOptionForm';
import useEncodedSearchParams from '../../report/useEncodedSearchParams';

export const WIDGET_TYPES = {
    SWITCH: 0,
    INT_SLIDER: 1,
    STRING_SELECTOR: 2,
    STRING_MULTISELECTOR: 3,
    DATE_SELECTOR: 4,
    SIMPLE_DATE_SELECTOR: 5,
    DISABLED: 6,
};

export default function useSummaryOptions() {
    const [searchParams, setSearchParams] = useEncodedSearchParams('summaryOptions');

    const baseDefaults = useMemo(() => {
        return Object.fromEntries(
            SUMMARY_INPUTS.map((field) => [field.name, field.type === 'date' ? null : field.defaultValue ?? '']),
        );
    }, []);

    const formDefaults = useMemo(() => {
        return { ...baseDefaults, ...searchParams };
    }, [baseDefaults, searchParams]);

    const formContext = useForm({
        defaultValues: formDefaults,
    });

    return {
        formContext,
        setSearchParams,
    };
}
