import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { useAceApp } from '../../../Menu/ReportAppSelector';
import Typography from '@mui/material/Typography';
import { Stack, Switch, TextField } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { chartColor } from '../../../Charts/ChartConfig';
import { CORRELATIONS, forAllPolyps, PerPolypToolkit } from './OpticalDiagnosisUtils';
import Box from '@mui/material/Box';

const greyColor = '#d3d3d3';
[
    { field: 'Metric', headerMetric: 'Metric', flex: 0.5 },
    { field: 'Overall', headerName: 'Overall', flex: 0.5 },
    { field: 'Neoplastics', headerName: 'Neoplastics', flex: 0.5 },
    { field: 'Non-Neoplastics', headerName: 'Non-Neoplastics', flex: 0.5 },
].map((col) => ({
    ...col,
    valueFormatter: (params) => {
        const value = params.value;
        if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
        return value;
    },
}));

function CorrelationBarChart({ chartData, category, withSeniority, all_focussed, focusPlot, metric }) {
    return (
        <ResponsiveContainer width='100%' height={800}>
            <BarChart data={chartData.filter((d) => d.metric === metric)}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='match' />
                <YAxis yAxisId='left' orientation='left' />
                <YAxis yAxisId='right' orientation='right' />
                <Tooltip
                    content={<PerPolypToolkit dataKey='match' category={category} withSeniority={withSeniority} />}
                />
                <Legend
                    payload={all_focussed.reduce((acc, focussed, i) => {
                        acc.push({ value: focussed, type: 'square', color: chartColor(i) });
                        return acc;
                    }, [])}
                />
                {all_focussed
                    .filter((focussed) => !focusPlot || focussed === focusPlot)
                    .map((focussed, i) => {
                        const focus = withSeniority ? 'seniority' : 'endoscopist';
                        if (category === 'All') {
                            return (
                                <Bar
                                    key={focussed}
                                    yAxisId='left'
                                    dataKey={(entry) =>
                                        entry['neo'].find((item) => item[focus] === focussed)?.count +
                                        entry['nonNeo'].find((item) => item[focus] === focussed)?.count
                                    }
                                    stackId='a'
                                    fill={chartColor(i)}
                                    name={focussed}
                                />
                            );
                        }
                        return (
                            <Fragment key={focussed}>
                                <Bar
                                    yAxisId='left'
                                    dataKey={(entry) => entry['neo'].find((item) => item[focus] === focussed)?.count}
                                    stackId='a'
                                    fill={category === 'Neoplastics' ? chartColor(i) : greyColor}
                                    name={focussed + '-neo'}
                                />
                                <Bar
                                    yAxisId='right'
                                    dataKey={(entry) => entry['nonNeo'].find((item) => item[focus] === focussed)?.count}
                                    stackId='b'
                                    fill={category === 'Non-Neoplastics' ? chartColor(i) : greyColor}
                                    name={focussed + '-nonNeo'}
                                />
                            </Fragment>
                        );
                    })}
            </BarChart>
        </ResponsiveContainer>
    );
}

/**
 * Compare Baseline diagnosis (ie initial OD) and Post-CADx diagnosis (ie second OD) to CADx
 */
export function CorrelationODvsCADx() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });

    const [focusPlot, setFocusPlot] = useState();
    const [category, setCategory] = useState('All');
    const [withSeniority, setWithSeniority] = useState(false);

    const all_endoscopists = useMemo(
        () => [...new Set(explore.ace_histo.map((p) => p.endoscopist))],
        [explore.ace_histo],
    );
    const all_seniorities = useMemo(() => [...new Set(explore.ace_histo.map((p) => p.seniority))], [explore.ace_histo]);
    const all_focussed = useMemo(() => {
        return withSeniority ? all_seniorities : all_endoscopists;
    }, [withSeniority, all_seniorities, all_endoscopists]);
    const polyps = useMemo(() => {
        const polyps = [];
        forAllPolyps(explore.ace_histo, (filteredPolyps, PolypClass, isNeo) => {
            filteredPolyps.map((p) => {
                const polyp = new PolypClass(p);

                CORRELATIONS.forEach(({ metric, condition }) => {
                    const match = condition(polyp);
                    if (match) {
                        polyps.push({ ...p, neoplastic: isNeo, metric, match });
                    }
                });
            });
        });
        return polyps;
    }, [explore.ace_histo]);

    const endoscopistData = useMemo(() => {
        const data = [];

        for (const metric of ['Baseline', 'Post-CADx']) {
            for (const match of ['Match', 'Mismatch']) {
                const row = { metric, match, neo: [], nonNeo: [] };
                for (const endo of all_endoscopists.filter((endo) => !focusPlot || endo === focusPlot)) {
                    row.neo.push({
                        endoscopist: endo,
                        count: polyps.filter(
                            (polyp) =>
                                polyp.metric === metric &&
                                polyp.endoscopist === endo &&
                                polyp.match === match &&
                                polyp.neoplastic === true,
                        ).length,
                    });
                    row.nonNeo.push({
                        endoscopist: endo,
                        count: polyps.filter(
                            (polyp) =>
                                polyp.metric === metric &&
                                polyp.endoscopist === endo &&
                                polyp.match === match &&
                                polyp.neoplastic === false,
                        ).length,
                    });
                }
                data.push(row);
            }
        }
        return data;
    }, [all_endoscopists, focusPlot, polyps]);

    const seniorityData = useMemo(() => {
        const data = [];

        for (const metric of ['Baseline', 'Post-CADx']) {
            for (const match of ['Match', 'Mismatch']) {
                const row = { metric, match, neo: [], nonNeo: [] };
                for (const senio of all_seniorities) {
                    row.neo.push({
                        seniority: senio,
                        count: polyps.filter(
                            (polyp) =>
                                polyp.metric === metric &&
                                polyp.seniority === senio &&
                                polyp.match === match &&
                                polyp.neoplastic === true,
                        ).length,
                    });
                    row.nonNeo.push({
                        seniority: senio,
                        count: polyps.filter(
                            (polyp) =>
                                polyp.metric === metric &&
                                polyp.seniority === senio &&
                                polyp.match === match &&
                                polyp.neoplastic === false,
                        ).length,
                    });
                }
                data.push(row);
            }
        }
        return data;
    }, [all_seniorities, polyps]);
    const chartData = useMemo(() => {
        return withSeniority ? seniorityData : endoscopistData;
    }, [withSeniority, seniorityData, endoscopistData]);
    return (
        <Stack spacing={2}>
            <Typography variant='h5'>Correlation of Human Optical Diagnosis and CADx</Typography>
            <Stack direction='row' spacing={2} alignItems='center'>
                <Typography>Category</Typography>
                <TextField
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    {['All', 'Neoplastics', 'Non-Neoplastics'].map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Focus: </Typography>
                <TextField
                    value={focusPlot}
                    onChange={(e) => setFocusPlot(e.target.value)}
                    select
                    SelectProps={{ native: true }}
                >
                    <option value=''>All</option>
                    {all_focussed.map((a) => (
                        <option key={a} value={a}>
                            {a}
                        </option>
                    ))}
                </TextField>
                <Typography>Seniority: </Typography>
                <Switch
                    checked={withSeniority}
                    onChange={(e) => {
                        setWithSeniority(e.target.checked);
                        setFocusPlot();
                    }}
                />
            </Stack>
            <Stack direction='row' spacing={2}>
                <Box width='50%'>
                    <Typography variant='h5' align='center'>
                        Baseline correlation
                    </Typography>
                    <CorrelationBarChart
                        chartData={chartData}
                        category={category}
                        withSeniority={withSeniority}
                        all_focussed={all_focussed}
                        focusPlot={focusPlot}
                        metric='Baseline'
                    />
                </Box>
                <Box width='50%'>
                    <Typography variant='h5' align='center'>
                        Post-CADx correlation
                    </Typography>
                    <CorrelationBarChart
                        chartData={chartData}
                        category={category}
                        withSeniority={withSeniority}
                        all_focussed={all_focussed}
                        focusPlot={focusPlot}
                        metric='Post-CADx'
                    />
                </Box>
            </Stack>
        </Stack>
    );
}
