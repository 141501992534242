import { useDashboardsRead } from '../../aceapi/aceComponents';
import { AsyncBoundary } from '../../aceapi/utils';
import Grid from '@mui/material/Grid';
import AceDashboardComponent from './AceDashboardComponent';
import { Skeleton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

function AsyncAceDashboard({ dashboard_id }) {
    const { data: dashboard } = useDashboardsRead({ pathParams: { id: dashboard_id } });

    return (
        <Stack spacing={1}>
            <Typography variant='h5' align='center'>
                {dashboard.description}
            </Typography>
            <Grid container spacing={3} justifyContent='center' alignItems='stretch' sx={{ width: '100%' }}>
                {dashboard.component_placements.map((placement) => (
                    <Grid key={placement.id} item xs={placement.grid_columns}>
                        <AceDashboardComponent component_id={placement.component.id} />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
}

export default function AceDashboard(props) {
    return (
        <AsyncBoundary fallback={<Skeleton variant='rectangular' height={1200} />}>
            <AsyncAceDashboard {...props} />
        </AsyncBoundary>
    );
}
