import React from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InsightsIcon from '@mui/icons-material/Insights';
import InfoIcon from '@mui/icons-material/Info';
import LoginIcon from '@mui/icons-material/Login';
import SearchIcon from '@mui/icons-material/Search';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ShortTextIcon from '@mui/icons-material/ShortText';
import BusinessIcon from '@mui/icons-material/Business';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import RadarIcon from '@mui/icons-material/Radar';

const getMenuItems = ({ token }) => {
    const user = token.parse().user;
    const isAuthorised = user.is_active;
    const hasAceDashboardsAccess = user.components?.includes('ace_dashboards') ?? false;
    const hasAssessmentAccess = user.components?.includes('case_assessments') ?? false;
    const hasSummaryAccess = user.components?.includes('summary') ?? false;

    return [
        {
            value: '/login',
            visible: !isAuthorised,
            primaryText: 'Login',
            leftIcon: <LoginIcon />,
        },
        {
            value: '/dashboard',
            visible: isAuthorised,
            primaryText: 'Dashboard',
            leftIcon: <DashboardIcon />,
        },
        {
            value: '/dashboards',
            visible: isAuthorised && hasAceDashboardsAccess,
            primaryText: 'Ace Metrics Dashboards',
            leftIcon: <InsightsIcon />,
        },
        {
            value: '/assessments',
            visible: isAuthorised && hasAssessmentAccess,
            primaryText: 'Live Assessments',
            leftIcon: <RadarIcon />,
        },
        {
            value: '/summary',
            visible: isAuthorised && hasSummaryAccess,
            primaryText: 'Procedure Summary',
            leftIcon: <SummarizeIcon />,
        },
        {
            value: '/search',
            visible: isAuthorised,
            primaryText: 'Search',
            leftIcon: <SearchIcon />,
        },
        {
            value: '/performance',
            visible: isAuthorised,
            primaryText: 'Co-creation Cases Search',
            leftIcon: <TroubleshootIcon />,
        },
        {
            value: '/demo/procedure',
            visible: isAuthorised,
            primaryText: 'Procedure Demo',
            leftIcon: <AssignmentIcon />,
        },
        {
            value: '/demo/p',
            visible: isAuthorised,
            primaryText: 'Procedure Compact Demo',
            leftIcon: <ShortTextIcon />,
        },
        {
            value: '/hospitals',
            visible: isAuthorised,
            primaryText: 'Hospitals',
            leftIcon: <BusinessIcon />,
        },
        {
            value: '/clinicalsummary',
            visible: isAuthorised,
            primaryText: 'Trial Summary',
            leftIcon: <AssessmentIcon />,
        },
        {
            value: '/cat',
            visible: isAuthorised,
            primaryText: 'CAT',
            leftIcon: <ShapeLineIcon />,
        },
        {
            value: '/about',
            visible: isAuthorised,
            primaryText: 'About',
            leftIcon: <InfoIcon />,
        },
        {
            value: '/settings',
            visible: isAuthorised,
            primaryText: 'Account Settings',
            leftIcon: <ManageAccountsIcon />,
        },
    ];
};
export default getMenuItems;
