import ReportBarChart from '../../Charts/stats/ReportBarChart';
import { useMemo } from 'react';
import useFilteredSummaryData from './useFilteredSummaryData';
import SummaryViewDump from '../SummaryViewDump';

export default function SummaryCounts({ type, params = {} }) {
    const { filteredProcs, filteredGroups } = useFilteredSummaryData({ type, params });
    const { showDownload } = params;

    const data = useMemo(() => {
        return filteredGroups
            .map((group) => {
                const groupProcs = filteredProcs.filter((p) => group.users.includes(p.username));
                return {
                    procs: groupProcs,
                    count: groupProcs.length,
                    ...group,
                };
            })
            .filter((x) => x.count > 0)
            .sort((x, y) => y.count - x.count);
    }, [filteredGroups, filteredProcs]);

    return (
        <>
            {showDownload && <SummaryViewDump data={data} type={type} />}
            <ReportBarChart
                data={data}
                nameKey='name'
                valueKey='count'
                yFormat={(name, i) => {
                    const short = data?.[i]?.short;
                    if (short) return short;
                    return name;
                }}
            />
        </>
    );
}
