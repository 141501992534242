import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Stack, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useState } from 'react';
import { useDashboardComponentsCreate } from '../../aceapi/aceComponents';
import Box from '@mui/material/Box';
import { Alert } from '@mui/lab';

export default function SummarySaveComponent() {
    const [saveDialogOpen, setSaveDialogOpen] = useState(false);
    const [currentOptions, setCurrentOptions] = useState();
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const { mutateAsync: createDashboardComponent } = useDashboardComponentsCreate();

    const save = useCallback(() => {
        const summaryOptions = localStorage.getItem('summaryOptions');
        setCurrentOptions(summaryOptions);
        setSaveDialogOpen(true);
    }, []);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            const formObject = Object.fromEntries(formData.entries());
            try {
                await createDashboardComponent({ body: formObject });
                setAlertSeverity('success');
                setAlertMessage('Dashboard component saved successfully');
            } catch (err) {
                setAlertSeverity('error');
                setAlertMessage(`Failed to save dashboard component: ${err.message}`);
            }
            setSaveDialogOpen(false);
            setAlertOpen(true);
        },
        [createDashboardComponent],
    );

    return (
        <>
            <Button onClick={save} variant='contained' color='success' startIcon={<SaveIcon />}>
                Save as Dashboard Component
            </Button>
            <Dialog
                open={saveDialogOpen}
                onClose={() => setSaveDialogOpen(false)}
                maxWidth='md'
                fullWidth
                component='form'
                onSubmit={handleSubmit}
            >
                <DialogTitle>Save Summary as Dashboard Component</DialogTitle>
                <DialogContent>
                    <Box p={2}>
                        <Stack spacing={2}>
                            <TextField id='name' name='name' label='Component Name' fullWidth required autoFocus />
                            <TextField id='description' name='description' label='Component Description' fullWidth />
                            <TextField
                                id='encoded_preset'
                                name='encoded_preset'
                                label='Encoded options'
                                fullWidth
                                value={currentOptions}
                                InputProps={{
                                    readOnly: true,
                                    onClick: (e) => e.target.select(),
                                }}
                                multiline
                            />
                        </Stack>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
                    <Button type='submit'>Save</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
                <Alert
                    onClose={() => setAlertOpen(false)}
                    severity={alertSeverity}
                    variant='filled'
                    sx={{ width: '100%' }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </>
    );
}
