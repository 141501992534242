import { AsyncBoundary, CustomErrorBoundary } from '../../aceapi/utils';
import TabPerTypes from './TabPerTypes';
import { Box, Divider, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ConfirmButton from '../dialogs/ConfirmButton';
import SummaryDownload from './SummaryDownload';
import FakeProgressBar from '../placeholders/FakeProgressBar';
import SummaryUpdate from './SummaryUpdate';
import ForceSelectApp from '../report/ForceSelectApp';
import { useNavigate, useParams } from 'react-router-dom';
import useSummaryOptions from './hooks/useSummaryOptions';
import { FormContainer } from 'react-hook-form-mui';
import SummaryCounts from './stats/SummaryCounts';
import SummaryTrends from './stats/SummaryTrends';
import SummaryTables from './stats/SummaryTables';
import SummaryInactivityTracer from './stats/SummaryInactivityTracer';
import SummarySaveComponent from './SummarySaveComponent';
import useShow from '../../aceapi/hooks/useShow';

export const SUMMARY_STATS = {
    counts: { name: 'Procedure counts', component: SummaryCounts },
    tables: { name: 'Procedure tables', component: SummaryTables },
    trends: { name: 'Procedure trends', component: SummaryTrends },
    inactivity: { name: 'Inactivity tracker', component: SummaryInactivityTracer },
};

function SafeSummaryMain() {
    const show = useShow();
    const { tab: currentTab } = useParams();
    const [stat, setStat] = useState(() => currentTab || localStorage.getItem('summaryView') || 'counts');

    const { formContext, setSearchParams } = useSummaryOptions();
    const { watch, reset } = formContext;
    const allParams = watch();
    useEffect(() => {
        setSearchParams({ ...allParams, statType: stat });
    }, [allParams, setSearchParams, stat]);

    const navigate = useNavigate();

    const handleSummaryViewChange = (e) => {
        const val = e.target.value;
        localStorage.setItem('summaryView', val);
        setStat(val);
        navigate(`/summary/${val}`);
    };

    const resetOptions = () => {
        localStorage.removeItem('summaryView');
        localStorage.removeItem('summaryOptions');
        setSearchParams({});
        reset();
        handleSummaryViewChange({ target: { value: 'counts' } });
    };

    return (
        <FormContainer formContext={formContext}>
            <Stack m={2} spacing={2}>
                <Stack spacing={1} direction='row' alignItems='center' justifyContent='center'>
                    <Typography variant='h6'>Type: </Typography>
                    <TextField select value={stat} onChange={handleSummaryViewChange}>
                        {Object.entries(SUMMARY_STATS).map(([key, { name }]) => (
                            <MenuItem key={key} value={key}>
                                {name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <SummaryDownload />
                    {show.ace_dashboards && <SummarySaveComponent />}
                    <ConfirmButton
                        onConfirm={resetOptions}
                        variant='outlined'
                        action='reset all options and views to their defaults'
                    >
                        Reset All To Defaults
                    </ConfirmButton>
                </Stack>
                <SummaryUpdate />
                <Divider />
                <AsyncBoundary
                    fallback={
                        <Box p={1}>
                            <Typography variant='h6'>Loading summary... This should take a few seconds.</Typography>
                            <FakeProgressBar step={0.05} />
                        </Box>
                    }
                >
                    <TabPerTypes name={stat} />
                </AsyncBoundary>
            </Stack>
        </FormContainer>
    );
}

export default function SummaryMain(props) {
    const navigate = useNavigate();

    return (
        <CustomErrorBoundary
            errorCallback={() => {
                localStorage.removeItem('summaryView');
                localStorage.removeItem('summaryOptions');
                navigate('/summary/counts');
            }}
        >
            <ForceSelectApp>
                <SafeSummaryMain {...props} />
            </ForceSelectApp>
        </CustomErrorBoundary>
    );
}
