import { useParams } from 'react-router-dom';
import Forbidden from '../components/Login/Forbidden';
import PaperCard from '../components/report/PaperCard';
import { Button, Grid, IconButton, Link, Stack, TextField, Typography } from '@mui/material';
import useFrames from '../components/report/useFrames';
import FrameGrid from '../components/Charts/timeline/FrameGrid';
import format from 'format-duration';
import { useCallback, useEffect, useMemo } from 'react';
import useDebugOptions from '../components/report/useDebugOptions';
import { WIDGET_TYPES } from '../components/summary/hooks/useSummaryOptions';
import ExtraDataLegend from '../components/report/ExtraDataLegend';
import ConfirmButton from '../components/dialogs/ConfirmButton';
import { DataGrid } from '@mui/x-data-grid';
import ClearIcon from '@mui/icons-material/Clear';
import useExtraData from '../components/report/useExtraData';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import useProcTime from '../aceapi/hooks/useProcTime';
import useShow from '../aceapi/hooks/useShow';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import ProcedureStream from '../components/stream/ProcedureStream';
import useKeyBoardEvent from '../components/events/useKeyBoardEvent';
import ReportTimeline from '../components/Charts/ReportTimeline';
import { useTimelineBrush } from '../components/Charts/timeline/TimelineBrush';
import useVideoKeyNav from '../components/shortcuts/useVideoKeyNav';
import ShortcutCard from '../components/shortcuts/ShortcutCard';
import VideoNavButtons from '../components/stream/VideoNavButtons';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import { useEditsTimelineCreate, useEditsTimelineDelete, useProceduresTimelineEdits } from '../aceapi/aceComponents';
import { useQueryClient } from '@tanstack/react-query';

export default function ProcedureTimelineEdit(props) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const show = useShow();
    const procTime = useProcTime();

    const { extraRow, extraModels, edits, annotations, extraBars } = useExtraData({
        show: show.extra_models,
        authorized,
    });

    const queryClient = useQueryClient();
    const { data: timelineEdits } = useProceduresTimelineEdits(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized && show.timeline, suspense: false },
    );
    const { mutateAsync: createEdit } = useEditsTimelineCreate();
    const { mutateAsync: deleteEdit } = useEditsTimelineDelete();

    useEffect(() => {
        edits.setState(timelineEdits ?? []);
    }, [timelineEdits, edits]);

    const noop = useCallback(() => null, []);
    const seeker = useVideoSeeker();
    useVideoKeyNav(seeker);

    const frameProps = useFrames({
        uuid,
        setUnavailable: noop,
        authorized,
    });

    const brush = useTimelineBrush(seeker);

    const options = useDebugOptions(extraModels !== null, [
        {
            key: 'models',
            type: WIDGET_TYPES.STRING_SELECTOR,
            name: '',
            defaultValue: 1,
            extraProps: {
                options:
                    extraModels?.map((x) => ({
                        key: x.key,
                        name: x.name,
                        value: x.id,
                    })) ?? [],
                extraInputProps: { label: 'Model to alter', helperText: 'For annotating use models ending with *' },
            },
        },
    ]);

    const appendEdit = (type) => {
        if (options.state.models === null) {
            alert('Please select a model to alter');
            return;
        }
        if (brush.end - brush.start < 100) {
            alert('Please select a valid interval (at least 100ms)');
            return;
        }
        createEdit({
            body: {
                procedure_id: uuid,
                type,
                model: options.state.models,
                start: Math.round(brush.start - procTime.start),
                end: Math.round(brush.end - procTime.start),
                comment: {},
            },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const removeEdit = (id) => {
        deleteEdit({ pathParams: { id } }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const singleFrameAnnotation = () => {
        if (options.state.models === null) {
            alert('Please select a model to alter');
            return;
        }
        createEdit({
            body: {
                procedure_id: uuid,
                type: 'single',
                model: options.state.models,
                start: seeker.timestamp.state - procTime.start,
                end: null,
                comment: {},
            },
        }).then(async () => {
            await queryClient.invalidateQueries({ queryKey: ['procedures', uuid, 'timeline_edits'] });
        });
    };

    const annotating = extraModels?.find((x) => x.id === options.state.models)?.manual_annotation ?? false;

    const downloadJson = (data, name = 'data') => {
        const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}_${uuid}.json`;
        a.click();
    };

    const downloadAnnotations = () => {
        downloadJson(
            annotations.map((x) => ({
                ...x,
                start: x.start + procTime.start,
                end: x.end ? x.end + procTime.start : undefined,
                start_frame: seeker.ts2frame(x.start + procTime.start),
                end_frame: x.end ? seeker.ts2frame(x.end + procTime.start) : undefined,
            })),
            'annotations',
        );
    };

    const freeze_frame_timestamps = useMemo(() => {
        return annotations
            .filter((x) => x.model?.key === 'freeze_frame_annotation')
            .map((x) => x.start + procTime.start - seeker.video_start);
    }, [annotations, procTime.start, seeker.video_start]);

    const downloadFreezeFrames = () => {
        downloadJson(
            {
                procedure_id: uuid,
                timestamps: freeze_frame_timestamps,
            },
            'freeze_frames',
        );
    };

    useKeyBoardEvent({
        watchKeys: ['s'],
        onKeyUp: () => singleFrameAnnotation(),
    });

    useKeyBoardEvent({
        watchKeys: ['a'],
        onKeyUp: () => appendEdit('add'),
    });

    useKeyBoardEvent({
        watchKeys: ['d'],
        onKeyUp: () => appendEdit('remove'),
    });

    useKeyBoardEvent({
        watchKeys: ['p'],
        onKeyUp: () => downloadAnnotations(),
    });

    const frameCell = (x) => {
        const ts = x + procTime.start;
        const frame = seeker.ts2frame(ts);
        return (
            <Link onClick={() => seeker.seekTo(ts)} sx={{ cursor: 'pointer' }}>
                {frame} ({format(ts - seeker.video_start)} | {format(x)})
            </Link>
        );
    };

    const columns = [
        {
            field: 'type',
            headerName: 'Type',
            flex: 0.2,
        },
        {
            field: 'model',
            headerName: 'Model',
            flex: 0.2,
            valueFormatter: (x) => extraModels.find((y) => y.id === x.value)?.name ?? 'Unknown',
        },
        {
            field: 'start',
            headerName: 'Start',
            flex: 0.2,
            renderCell: (x) => frameCell(x.value),
        },
        {
            field: 'end',
            headerName: 'End',
            flex: 0.2,
            renderCell: (x) => x.value && frameCell(x.value),
        },
        {
            field: 'user',
            headerName: 'Annotator',
            flex: 0.1,
            valueFormatter: (x) => x.value?.username ?? 'Unknown',
        },
        {
            field: 'id',
            headerName: 'Delete',
            flex: 0.1,
            renderCell: (x) => (
                <ConfirmButton ButtonType={IconButton} onConfirm={() => removeEdit(x.value)} action='delete this edit'>
                    <ClearIcon />
                </ConfirmButton>
            ),
        },
    ];

    return !authorized || !show.timeline ? (
        <Forbidden />
    ) : (
        <Grid container spacing={3}>
            <PaperCard xs={12} title='Procedure Timeline Edit' variant='h2' align='center'>
                <ProcedureStream uuid={uuid} seeker={seeker} setUnavailable={noop} noPaper />
                <VideoNavButtons seeker={seeker} brush={brush} />
                <ReportTimeline
                    brush={brush}
                    setUnavailable={noop}
                    loaded={noop}
                    toggleBars
                    seeker={seeker}
                    extraRow={extraRow}
                    extraBars={extraBars}
                    compact
                    {...frameProps}
                    {...props}
                />
                <Stack spacing={2}>
                    <Typography variant='h6' color='primary' fontFamily='Roboto Mono, Monospace'>
                        Cursor: {seeker.frameInput()} (video: {format(seeker.timestamp.state - seeker.video_start)} |
                        timeline: {format(seeker.timestamp.state - procTime.start)})
                    </Typography>
                    {brush.selected && (
                        <>
                            <Typography variant='h6' color='primary' fontFamily='Roboto Mono, Monospace'>
                                Selection:{' '}
                                <TextField
                                    sx={{ width: '8ch' }}
                                    variant='standard'
                                    value={brush.frameInterval.start}
                                    onChange={(e) => {
                                        const frame = Math.min(
                                            seeker.parseFrame(e.target.value),
                                            brush.frameInterval.end,
                                        );
                                        brush.setInterval((prev) => ({ ...prev, start: seeker.frame2ts(frame) }));
                                    }}
                                />{' '}
                                ({format(brush.videoInterval.start)} | {format(brush.start - procTime.start)}) -{' '}
                                <TextField
                                    sx={{ width: '8ch' }}
                                    variant='standard'
                                    value={brush.frameInterval.end}
                                    onChange={(e) => {
                                        const frame = Math.max(
                                            seeker.parseFrame(e.target.value),
                                            brush.frameInterval.start,
                                        );
                                        brush.setInterval((prev) => ({ ...prev, end: seeker.frame2ts(frame) }));
                                    }}
                                />{' '}
                                ({format(brush.videoInterval.end)} | {format(brush.end - procTime.start)})
                            </Typography>
                        </>
                    )}
                    <ExtraDataLegend extraModels={extraModels} />
                    <FrameGrid {...frameProps} />
                    <Stack direction='row' spacing={2} alignItems='center'>
                        {options.render()}
                        <Button variant='contained' color='success' onClick={() => appendEdit('add')}>
                            Add
                        </Button>
                        <Button
                            variant='contained'
                            color='error'
                            onClick={() => appendEdit('remove')}
                            disabled={annotating}
                        >
                            Remove
                        </Button>
                        <Button variant='contained' color='warning' onClick={singleFrameAnnotation}>
                            Single Frame Annotation
                        </Button>
                        <ConfirmButton
                            variant='contained'
                            color='secondary'
                            action='delete all your edits'
                            onConfirm={() => edits.state.map((x) => removeEdit(x.id))}
                        >
                            Reset
                        </ConfirmButton>
                        <ConfirmButton
                            variant='contained'
                            color='primary'
                            action='download annotation data (from models ending with *)'
                            onConfirm={downloadAnnotations}
                        >
                            Export Annotations
                        </ConfirmButton>
                        {freeze_frame_timestamps.length > 0 && (
                            <ConfirmButton
                                variant='contained'
                                color='info'
                                action='download freeze frame data'
                                onConfirm={downloadFreezeFrames}
                            >
                                Export Freeze Frames
                            </ConfirmButton>
                        )}
                    </Stack>
                    <DataGrid columns={columns} rows={edits.state} getRowId={(row) => row.id} autoHeight />
                </Stack>
            </PaperCard>
            <ShortcutCard xs={12} />
        </Grid>
    );
}
