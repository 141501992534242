import { useMemo } from 'react';
import { useGroupMetagroupsList, useGroupUsersList } from '../../../aceapi/aceComponents';
import { MetaUserGroup, ReportUser } from '../../../aceapi/aceSchemas';

export function user_to_group(user: ReportUser, groups?: MetaUserGroup[]): MetaUserGroup {
    const test = groups?.filter((x) => x.test).some((x) => x.users?.includes(user.username)) ?? false;

    return {
        id: user.id,
        name: user.username,
        short: null,
        type: 'user',
        users: [user.username],
        test,
    };
}

export default function useGroupList({ type }: { type: string }) {
    const { data: users } = useGroupUsersList({}, { enabled: type === 'user' });
    const { data: metagroups = [] } = useGroupMetagroupsList({});

    const groups: MetaUserGroup[] = useMemo(() => {
        if (type === 'user' && Array.isArray(users)) {
            return users.map((user) => user_to_group(user, metagroups));
        }
        return metagroups.filter((g) => g.type === type);
    }, [metagroups, type, users]);

    return groups;
}
