import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';

const serializeDates = (params) => {
    const newParams = {};
    for (const key in params) {
        if (params[key] instanceof Date) {
            newParams[key] = { $date: params[key].getTime() };
        } else {
            newParams[key] = params[key];
        }
    }
    return newParams;
};

const deserializeDates = (params) => {
    const newParams = {};
    for (const key in params) {
        if (params[key]?.$date) {
            newParams[key] = new Date(params[key].$date);
        } else {
            newParams[key] = params[key];
        }
    }
    return newParams;
};

export function encodeSearchParams(params) {
    return btoa(JSON.stringify(serializeDates(params)));
}

export function decodeSearchParams(encoded) {
    return deserializeDates(JSON.parse(atob(encoded)));
}

/*
 * useEncodedSearchParams is a custom wrapper around useSearchParams that allows for encoding and decoding of search params
 * more complex than a simple key-value pair dictionary.
 * It uses JSON and base64 encoding to store the search params in a single string.
 */
export default function useEncodedSearchParams(localStorageKey = null) {
    const [searchParams, setSearchParams] = useSearchParams(
        localStorageKey ? { params: localStorage.getItem(localStorageKey) ?? '' } : null,
    );

    const setEncodedSearchParams = useCallback(
        (params) => {
            if (!params) return;
            const encoded = encodeSearchParams(params);
            if (encoded === searchParams.get('params')) return;
            if (localStorageKey) localStorage.setItem(localStorageKey, encoded);
            setSearchParams({ params: encoded });
        },
        [localStorageKey, searchParams, setSearchParams],
    );

    useEffect(() => {
        if (localStorageKey) {
            setSearchParams({ params: localStorage.getItem(localStorageKey) ?? '' });
        }
    }, [localStorageKey, setSearchParams]);

    const decodedParams = useMemo(() => {
        try {
            return decodeSearchParams(searchParams.get('params') ?? '{}');
        } catch {
            return {};
        }
    }, [searchParams]);

    return [decodedParams, setEncodedSearchParams];
}
