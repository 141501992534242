import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Widget from '../search/widgets/Widget';
import format from 'format-duration';
import { timedelta } from './utils';

const MAX_DURATION = timedelta.HOUR * 3;

export const PROCEDURE_STATUS = ['COMPLETED', 'INTERRUPTED', 'STARTED', 'UPLOADED'];

export const WORKFLOW_MODES = [
    'AUDIT',
    'COMPUTER_AIDED',
    'COMPUTER_AIDED_TRIAL',
    'NORMAL_ENDOSCOPY',
    'NORMAL_ENDOSCOPY_TRIAL',
    'DEMO',
    'CASE_SERIES',
];

export const SUMMARY_INPUTS = [
    { name: 'groupType', label: 'Group type', type: 'select', defaultValue: 'user', show: [] },
    { name: 'test', label: 'Hide test users and groups', type: 'switch', defaultValue: true },
    {
        name: 'recording',
        label: 'Only show procedures with recordings',
        type: 'switch',
        defaultValue: false,
    },
    {
        name: 'duration',
        label: 'Procedure duration range',
        type: 'slider',
        defaultValue: [0, MAX_DURATION],
        min: 0,
        max: MAX_DURATION,
        valueLabelFormat: (v) => format(v, { leading: true }),
    },
    {
        name: 'status',
        label: 'Procedure status',
        type: 'multiselect',
        options: ['COMPLETED', 'INTERRUPTED'],
        defaultValue: ['COMPLETED', 'INTERRUPTED'],
        showChips: true,
        showCheckbox: true,
    },
    {
        name: 'modes',
        label: 'Workflow modes',
        type: 'multiselect',
        options: WORKFLOW_MODES,
        defaultValue: WORKFLOW_MODES,
        showChips: true,
        showCheckbox: true,
    },
    {
        name: 'recentOption',
        label: 'Recent procedures filter',
        type: 'select',
        options: [
            { id: 0, label: 'All time' },
            { id: 1, label: 'Last 24 hours' },
            { id: 7, label: 'Last 7 days' },
            { id: 14, label: 'Last 14 days' },
            { id: 30, label: 'Last 30 days' },
            { id: 90, label: 'Last 90 days' },
            { id: 180, label: 'Last 180 days' },
            { id: 365, label: 'Last year' },
        ],
        defaultValue: 0,
        sx: { minWidth: 200 },
    },
    {
        name: 'dateRange',
        type: 'group',
        direction: 'row',
        children: [
            {
                name: 'dateFrom',
                label: 'From date',
                type: 'date',
            },
            {
                name: 'dateTo',
                label: 'To date',
                type: 'date',
            },
        ],
    },
    {
        name: 'period',
        label: 'Aggregation period',
        type: 'select',
        options: [
            { id: 1, label: '1 day' },
            { id: 7, label: '1 week' },
            { id: 14, label: '2 weeks' },
            { id: 30, label: '1 month' },
            { id: 60, label: '2 months' },
            { id: 90, label: '3 months' },
            { id: 180, label: '6 months' },
            { id: 365, label: '1 year' },
        ],
        defaultValue: 7,
        show: ['trends'],
    },
    {
        name: 'extraColumn',
        label: 'Additional data to show on the table',
        type: 'select',
        options: [
            { id: 0, label: 'Procedure duration' },
            { id: 1, label: 'Cumulated network Disruption Time' },
            { id: 2, label: 'Cumulated Detection Off Time' },
            { id: 3, label: 'Cumulated Bad VM Time (< 50%)' },
            { id: 4, label: 'Procedure Time of Day' },
        ],
        defaultValue: 0,
        show: ['tables'],
    },
];

function StatOptionForm({ statName }) {
    return (
        <Accordion sx={{ boxShadow: 4 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3}>
                    {SUMMARY_INPUTS.filter((field) => !Array.isArray(field.show) || field.show.includes(statName)).map(
                        (field) => (
                            <Box key={field.name}>
                                <Widget {...field} />
                            </Box>
                        ),
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export { StatOptionForm };
