import { SwitchElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function FieldComponent({ field, onChange }) {
    const type = field.type;

    switch (type) {
        case 'TEXT':
            return (
                <TextFieldElement id={field.key} name={field.key} label={field.name} fullWidth onChange={onChange} />
            );
        case 'NUMBER':
            return (
                <TextFieldElement
                    id={field.key}
                    name={field.key}
                    label={field.name}
                    fullWidth
                    type='number'
                    onChange={onChange}
                />
            );
        case 'BOOLEAN':
            return <SwitchElement id={field.key} name={field.key} label={field.name} />;
        default:
            return <Typography color='error'>Unknown field type: {type}</Typography>;
    }
}

export default function AssessmentCommonFields({ fields, onChange, xs = 4 }) {
    return (
        <Grid container spacing={3} sx={{ width: '100%' }}>
            {fields?.map((field) => (
                <Grid item key={field.id} xs={xs}>
                    <FieldComponent field={field} onChange={onChange} />
                </Grid>
            ))}
        </Grid>
    );
}
