import { useMemo } from 'react';
import ReportLineChart from '../../Charts/stats/ReportLineChart';
import { timedelta } from '../utils';
import { getProcedureTrends } from '../filters/transforms';
import useFilteredSummaryData from './useFilteredSummaryData';

export default function SummaryTrends({ type, params }) {
    const { filteredProcs, filteredGroups } = useFilteredSummaryData({ type, params, sort: true });

    const { period = timedelta.WEEK } = params;

    const formattedData = useMemo(() => {
        return getProcedureTrends(params, filteredProcs, filteredGroups);
    }, [params, filteredProcs, filteredGroups]);

    const displayedGroups = useMemo(() => {
        return filteredGroups.filter((group) => formattedData.some((d) => d[group.name] > 0));
    }, [formattedData, filteredGroups]);

    return <ReportLineChart formatted={formattedData} groups={displayedGroups} period={period} />;
}
