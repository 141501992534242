import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import {
    isDiminutive,
    isDiminutiveRectosigmoidACE,
    isNotRectosigmoidACE,
    isRectosigmoid,
    isRectosigmoidACE,
    MetricsGrid,
    quantitativeMetricsAgainstHistology,
} from './OpticalDiagnosisUtils';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { Switch } from '@mui/material';
import useShow from '../../../../aceapi/hooks/useShow';

const COLUMN_GROUPS = [
    {
        groupId: 'All',
        children: [{ field: 'All' }, { field: 'Metric' }, { field: 'Non-Rectosigmoid' }],
    },
    {
        groupId: 'Rectosigmoid',
        children: [{ field: 'Rectosigmoid' }, { field: 'Diminutive Rectosigmoid' }],
    },
];
const METRICS_COLS = [
    { field: 'Metric', headerName: 'Metric', flex: 0.5 },
    { field: 'All', headerName: 'All', flex: 0.5 },
    { field: 'Non-Rectosigmoid', headerName: 'Non-Rectosigmoid', flex: 0.5 },
    { field: 'Rectosigmoid', headerName: 'Rectosigmoid', flex: 0.5 },
    { field: 'Diminutive Rectosigmoid', headerName: 'Diminutive Rectosigmoid', flex: 0.5 },
].map((col) => ({
    ...col,
    valueFormatter: (params) => {
        const value = params.value;
        if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
        return value;
    },
    valueGetter: (params) => {
        return typeof params.value == 'number' && isNaN(params.value) ? 'No data' : params.value;
    },
}));
const ROWS_COMPUTE = [
    {
        name: 'True positives',
        compute: ({ tp }) => tp,
    },
    {
        name: 'False positives',
        compute: ({ fp }) => fp,
    },
    {
        name: 'True negatives',
        compute: ({ tn }) => tn,
    },
    {
        name: 'False negatives',
        compute: ({ fn }) => fn,
    },
    {
        name: 'Total',
        compute: ({ total }) => total,
    },
    {
        name: 'Sensitivity',
        compute: ({ tp, fn }) => tp / (tp + fn),
    },
    {
        name: 'Specificity',
        compute: ({ tn, fp }) => tn / (tn + fp),
    },
    {
        name: 'Accuracy',
        compute: ({ tp, tn, total }) => (tp + tn) / total,
    },
];

/**
 * Compare Second decision to 'resect or leave in' (ie get_diagnosis_data's .second_decision) to histology
 */
export function QuantitativeMetrics() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });

    const show = useShow();
    const [withHistoDiag, setWithHistoDiag] = useState(true);
    const POLYP_FILTERS = useMemo(
        () =>
            withHistoDiag
                ? [
                      { name: 'All', filter: undefined },
                      { name: 'Non-Rectosigmoid', filter: (polyp) => !isRectosigmoid(polyp) },
                      { name: 'Rectosigmoid', filter: (polyp) => isRectosigmoid(polyp) },
                      {
                          name: 'Diminutive Rectosigmoid',
                          filter: (polyp) => isRectosigmoid(polyp) && isDiminutive(polyp),
                      },
                  ]
                : [
                      { name: 'All', filter: undefined },
                      { name: 'Non-Rectosigmoid', filter: (polyp) => isNotRectosigmoidACE(polyp) },
                      { name: 'Rectosigmoid', filter: (polyp) => isRectosigmoidACE(polyp) },
                      { name: 'Diminutive Rectosigmoid', filter: (polyp) => isDiminutiveRectosigmoidACE(polyp) },
                  ],
        [withHistoDiag],
    );
    const polyps = useMemo(
        () =>
            (withHistoDiag ? explore.diag_histo : explore.ace_histo).filter(
                (polyp) => polyp.arm === 'AI Arm' && ['Resect', 'Leave in'].includes(polyp.second_decision),
            ),
        [explore.ace_histo, explore.diag_histo, withHistoDiag],
    );

    const rows = [];
    ROWS_COMPUTE.map(({ name, compute }, id) => {
        const row = { id, Metric: name };
        POLYP_FILTERS.forEach(({ name, filter }) => {
            const d = quantitativeMetricsAgainstHistology(
                polyps,
                (polyp) => polyp.second_decision,
                'resect',
                'leave in',
                filter,
            );
            row[name] = compute(d);
        });
        rows.push(row);
    });
    return (
        <>
            <Typography variant='h4'>Quantitative metrics</Typography>
            <Typography variant='body1'>{withHistoDiag ? 'With histo diag' : 'Without histo diag'}</Typography>
            {show.debug && (
                <Switch
                    checked={withHistoDiag}
                    onChange={(e) => {
                        setWithHistoDiag(e.target.checked);
                    }}
                />
            )}
            <MetricsGrid name='Overall' rows={rows} columns={METRICS_COLS} columnGroupingModel={COLUMN_GROUPS} />
        </>
    );
}
