import useGroupList from '../hooks/useGroupList';
import { useMemo } from 'react';
import { GROUP_FILTERS, PROCEDURE_FILTERS } from '../filters/filters';
import { useAceApp } from '../../Menu/ReportAppSelector';
import { useSummaryProceduresMaxDuration } from '../../../aceapi/aceComponents';

export default function useFilteredSummaryData({ type, params, sort = false }) {
    const groups = useGroupList({ type });
    const { app } = useAceApp();
    const { data: unsortedProcs } = useSummaryProceduresMaxDuration({ queryParams: { app } });

    const procs = useMemo(
        () => (sort ? (unsortedProcs || []).sort((x, y) => x.start - y.start) : unsortedProcs),
        [unsortedProcs, sort],
    );

    const filteredProcs = useMemo(() => {
        const allProcedureFilters = [
            PROCEDURE_FILTERS.recording,
            PROCEDURE_FILTERS.duration,
            PROCEDURE_FILTERS.status,
            PROCEDURE_FILTERS.modes,
            PROCEDURE_FILTERS.recent,
            PROCEDURE_FILTERS.fromDate,
            PROCEDURE_FILTERS.toDate,
        ];

        return allProcedureFilters.reduce((procs, filter) => filter(params)(procs), procs);
    }, [params, procs]);

    const filteredGroups = useMemo(() => {
        const allGroupFilters = [GROUP_FILTERS.test];

        return allGroupFilters.reduce((groups, filter) => filter(params)(groups), groups);
    }, [params, groups]);

    return {
        filteredProcs,
        filteredGroups,
    };
}
