import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import {
    isDiminutive,
    isRectosigmoid,
    neoplasticsFilter,
    nonNeoplasticsFilter,
    quantitativeMetricsAgainstHistology,
} from './OpticalDiagnosisUtils';
import { useMemo } from 'react';
import { DataGrid } from '@mui/x-data-grid';

export function ExploreSummary() {
    const { app } = useAceApp();

    const { data: explore } = useGgExploreList({ queryParams: { app } });

    // TODO Ask if adenomas are only Adenoma or also include other types of neoplastic
    const adenomas = explore.diag_histo.filter((polyp) => polyp.histo_diagnosis === 'Adenoma').length;
    const nonAdenomas = explore.diag_histo.filter((polyp) => nonNeoplasticsFilter(polyp)).length;
    const DRSPs = explore.diag_histo.filter((polyp) => isDiminutive(polyp) && isRectosigmoid(polyp)).length;

    const neoplasticDRSPs = explore.diag_histo.filter(
        (polyp) => isDiminutive(polyp) && isRectosigmoid(polyp) && neoplasticsFilter(polyp),
    ).length;
    const nonNeoplasticDRSPs = explore.diag_histo.filter(
        (polyp) => isDiminutive(polyp) && isRectosigmoid(polyp) && nonNeoplasticsFilter(polyp),
    ).length;
    const {
        tp: neoplasticsDRSPsCorrect,
        fp: nonNeoplasticsDRSPsIncorrect,
        tn: nonNeoplasticsDRSPsCorrect,
        fn: neoplasticsDRSPsIncorrect,
    } = quantitativeMetricsAgainstHistology(
        explore.diag_histo,
        (polyp) => polyp.second_decision,
        'resect',
        'leave in',
        (polyp) => isDiminutive(polyp) && isRectosigmoid(polyp),
    );
    // const neoplasticDRSPs = neoplasticsDRSPsCorrect + neoplasticsDRSPsIncorrect;
    // const nonNeoplasticDRSPs = nonNeoplasticsDRSPsCorrect + nonNeoplasticsDRSPsIncorrect;

    const allResectedNeos = explore.diag_histo
        .filter(neoplasticsFilter)
        .filter((polyp) => polyp.resected === true).length;
    const studyEndPoint = allResectedNeos === 0 ? 'NaN' : neoplasticsDRSPsCorrect / allResectedNeos;

    const changedHighToLow = useMemo(
        () =>
            explore.diag_histo.filter(
                (polyp) => polyp.initial_confidence === 'High' && polyp.second_confidence === 'Low',
            ).length,
        [explore.diag_histo],
    );
    const changedLowToHigh = useMemo(
        () =>
            explore.diag_histo.filter(
                (polyp) => polyp.initial_confidence === 'Low' && polyp.second_confidence === 'High',
            ).length,
        [explore.diag_histo],
    );

    const map = {
        'Adenomas in Histology': adenomas,
        'Non-Adenomas in Histology': nonAdenomas,
        'Endoscopist with Confidence Level High->Low': changedHighToLow,
        'Endoscopist with Confidence Level Low->High': changedLowToHigh,
        'Diminutive Rectosigmoid Polyps - DRSPs': DRSPs,
        'Neoplastic DRSPs': neoplasticDRSPs,
        'Non-neoplastic DRSPs': nonNeoplasticDRSPs,
        'Neoplastic DRSPs Correctly Chosen for Resection': neoplasticsDRSPsCorrect,
        'Non-Neoplastic DRSPs Incorrectly Chosen for Resection': nonNeoplasticsDRSPsIncorrect,
        'Non-Neoplastic DRSPs Correctly Chosen to be left in': nonNeoplasticsDRSPsCorrect,
        'Neoplastic DRSPs Incorrectly Chosen to be left in': neoplasticsDRSPsIncorrect,
        'All Resected Neoplastic Polyps': allResectedNeos,
        'Study End Point': studyEndPoint,
    };
    const rows = Object.keys(map).map((key) => ({ id: key, Metric: key, All: map[key] }));
    return (
        <DataGrid
            name='Explore Summary'
            rows={rows}
            autoHeight
            columns={[
                {
                    field: 'Metric',
                    headerName: 'Name',
                    flex: 0.5,
                },
                {
                    field: 'All',
                    headerName: 'Value',
                    flex: 0.5,
                },
            ]}
        />
    );
}
