import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { timedelta } from '../../summary/utils';
import { chartColor } from '../ChartConfig';

export default function ReportLineChart({
    formatted,
    groups,
    period,
    showStartOnly = false,
    lineType = 'monotone',
    focus = null,
}) {
    const getDateRange = (v) => {
        const start = new Date(v);
        let end = new Date(start);
        if (period < timedelta.MONTH) end = new Date(v + period);
        else end.setMonth(end.getMonth() + period / timedelta.MONTH);
        const startStr = start.toLocaleDateString('en-UK');
        const endStr = end.toLocaleDateString('en-UK');
        return showStartOnly ? startStr : `${startStr} - ${endStr}`;
    };

    return (
        <ResponsiveContainer width='100%' aspect={16 / 9}>
            <LineChart data={formatted} margin={{ top: 20, bottom: 120, right: 50 }}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                    type='number'
                    dataKey='time'
                    domain={['auto', 'auto']}
                    scale='time'
                    tick={{ fontSize: 12 }}
                    tickFormatter={getDateRange}
                    angle={70}
                    textAnchor='start'
                    interval={formatted.length > 50 ? Math.trunc(formatted.length / 50) : 0}
                />
                <YAxis type='number' />
                <Tooltip labelFormatter={getDateRange} itemSorter={(e) => -e.value} />
                <Legend verticalAlign='top' />
                {groups.map((group, i) => (
                    <Line
                        key={group.id}
                        type={lineType}
                        dataKey={group.name}
                        stroke={chartColor(i)}
                        strokeWidth={2}
                        hide={focus && group.name !== focus}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}
