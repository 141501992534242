import PreAssessmentForm from './pre/PreAssessmentForm';
import { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, Step, StepLabel, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import CaseAssessmentLink from './link/CaseAssessmentLink';
import LiveAssessmentMain from './live/LiveAssessmentMain';
import { useSearchParams } from 'react-router-dom';
import PostAssessmentForm from './post/PostAssessmentForm';

const ASSESSMENT_STEPS = [
    {
        key: 'pre',
        name: 'Pre-Case Assessment',
        Component: PreAssessmentForm,
    },
    {
        key: 'link',
        name: 'Link Assessment to Procedure',
        Component: CaseAssessmentLink,
    },
    {
        key: 'live',
        name: 'Live Case Assessment',
        Component: LiveAssessmentMain,
    },
    {
        key: 'post',
        name: 'Post-Case Report and Actions',
        Component: PostAssessmentForm,
    },
];

export default function CaseAssessmentSteps() {
    const [searchParams, setSearchParams] = useSearchParams();

    const [activeStep, setActiveStep] = useState(() => {
        const step = searchParams.get('step');
        if (!step) return 0;
        return ASSESSMENT_STEPS.findIndex((s) => s.key === step);
    });

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSearchParams({ step: ASSESSMENT_STEPS[activeStep + 1].key });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSearchParams({ step: ASSESSMENT_STEPS[activeStep - 1].key });
    };

    const ActiveComponent = useMemo(() => {
        return ASSESSMENT_STEPS[activeStep]?.Component;
    }, [activeStep]);

    return (
        <Box sx={{ width: '100%' }} mt={2}>
            <Stack spacing={2}>
                <Stepper activeStep={activeStep}>
                    {ASSESSMENT_STEPS.map((step) => {
                        return (
                            <Step key={step.name}>
                                <StepLabel>{step.name}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {ActiveComponent ? (
                    <ActiveComponent
                        handleNext={handleNext}
                        handleBack={handleBack}
                        step={activeStep}
                        totalSteps={ASSESSMENT_STEPS.length}
                    />
                ) : (
                    <>
                        <Typography>Step {activeStep + 1}</Typography>
                        <Stack spacing={2} direction='row'>
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            <Button variant='contained' onClick={handleNext}>
                                {activeStep === ASSESSMENT_STEPS.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Stack>
                    </>
                )}
            </Stack>
        </Box>
    );
}
