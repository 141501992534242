import { Component, Suspense, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Skeleton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import PaperCard from '../components/report/PaperCard';
import ReplayIcon from '@mui/icons-material/Replay';
import UndoIcon from '@mui/icons-material/Undo';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import FlexBox from '../components/report/FlexBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import { QueryErrorResetBoundary, useQueryClient } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

const MAX_RETRIES = 3;

export function AsyncBoundary({ children, fallback = <CircularProgress /> }) {
    const [key, setKey] = useState(0);

    function NetworkErrorFallback({ error, resetErrorBoundary }) {
        return (
            <FlexBox width='100%' justifyContent='flex-start' alignItems='flex-start' p={1}>
                <Tooltip
                    title={`Status: ${error.stack?.detail ?? 'Unknown details'}, click to retry (${MAX_RETRIES - key} remaining)`}
                    arrow
                >
                    <Button
                        variant='contained'
                        size='small'
                        color='error'
                        onClick={() => {
                            setKey((prevState) => prevState + 1);
                            resetErrorBoundary();
                        }}
                        endIcon={<RefreshIcon />}
                        disabled={key >= MAX_RETRIES}
                        sx={{ zIndex: 100 }}
                    >
                        {error.message ?? 'Unknown error'}: {error.stack?.detail ?? 'Unknown error'}
                    </Button>
                </Tooltip>
                {error.status === 401 && <Typography variant='h5'>Please try logging out and back in.</Typography>}
            </FlexBox>
        );
    }

    const queryClient = useQueryClient();
    const resetErrors = (callback) => {
        queryClient.clear();
        callback();
    };

    return (
        <Suspense fallback={fallback} key={key}>
            <QueryErrorResetBoundary>
                {({ reset }) => (
                    <ErrorBoundary onReset={() => resetErrors(reset)} fallbackRender={NetworkErrorFallback}>
                        {children}
                    </ErrorBoundary>
                )}
            </QueryErrorResetBoundary>
        </Suspense>
    );
}

class BlockingErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
        this.props.errorCallback();
        this.setState({ error, errorInfo: errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Grid container spacing={3}>
                    <PaperCard xs={12} title='ERROR' variant='h3' align='center'>
                        <Divider sx={{ mb: 2 }} />
                        <Typography variant='h5'>Something went wrong:</Typography>
                        <Accordion sx={{ boxShadow: 4 }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography color='error.main'>
                                    {this.state.error ? this.state.error.message : 'Unknown error'}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography component='pre'>
                                    {this.state.errorInfo
                                        ? this.state.errorInfo.componentStack
                                        : 'No details available'}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Typography>
                            If the issue persist, please contact an admin and send the details above.
                        </Typography>
                        <Stack direction='row' spacing={2} mt={2}>
                            <Button
                                variant='contained'
                                color='error'
                                startIcon={<AttachEmailIcon />}
                                href={`mailto:baptisteparsy@odin-vision.com?subject=Report%20Error&body=${encodeURIComponent(
                                    `Error title: ${this.state.error ? this.state.error.message : 'Unknown error'}`,
                                )}${encodeURIComponent('\n\n')}${encodeURIComponent(
                                    `Error details: ${
                                        this.state.errorInfo
                                            ? this.state.errorInfo.componentStack
                                            : 'No details available'
                                    }`,
                                )}`}
                                target='_blank'
                            >
                                File a bug report
                            </Button>
                            <Button
                                variant='contained'
                                startIcon={<ReplayIcon />}
                                onClick={() => window.location.reload()}
                            >
                                Reload Page
                            </Button>
                            <Button variant='contained' startIcon={<UndoIcon />} onClick={() => window.history.back()}>
                                Go Back
                            </Button>
                            <Button
                                variant='contained'
                                startIcon={<DashboardIcon />}
                                onClick={() => window.location.assign('/dashboard')}
                            >
                                Go to Dashboard
                            </Button>
                        </Stack>
                    </PaperCard>
                </Grid>
            );
        }

        return this.props.children;
    }
}

BlockingErrorBoundary.defaultProps = {
    errorCallback: () => {},
};

export function CustomErrorBoundary(props) {
    const location = useLocation();
    return <BlockingErrorBoundary key={location.pathname} {...props} />;
}

export function withAsyncBoundary(WrappedComponent, { customFallback = null, skeletonHeight = 200 } = {}) {
    return function AsyncBoundaryWrapper(props) {
        return (
            <AsyncBoundary fallback={customFallback || <Skeleton variant='rectangular' height={skeletonHeight} />}>
                <WrappedComponent {...props} />
            </AsyncBoundary>
        );
    };
}
