import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { Switch } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { videoColumn } from './OpticalDiagnosisUtils';

export function RawExploreData() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const navigate = useNavigate();

    const [withHistoDiag, setWithHistoDiag] = useState(false);

    const polyps = useMemo(
        () => (withHistoDiag ? explore.diag_histo : explore.ace_histo),
        [explore.ace_histo, explore.diag_histo, withHistoDiag],
    );
    const columnNames = Object.keys(polyps[0] ?? []).sort();
    const columns = useMemo(() => {
        return columnNames.reduce((acc, key) => {
            const r =
                key === 'procedure_id'
                    ? { ...videoColumn(navigate), field: key, headerName: key, flex: 1 }
                    : { field: key, headerName: key, flex: 1 };
            acc.push(r);
            return acc;
        }, []);
    }, [columnNames, navigate]);
    const rows = useMemo(() => {
        const rows = [];
        polyps.forEach((polyp, id) => {
            rows[id] = { id };
            columnNames.forEach((name) => {
                rows[id][name] = polyp[name];
            });
        });
        return rows;
    }, [columnNames, polyps]);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector slotProps={{ tooltip: { title: 'Change density' } }} />
                <Box sx={{ flexGrow: 1 }} />
                <GridToolbarExport
                    slotProps={{
                        tooltip: { title: 'Export data' },
                        button: { variant: 'outlined' },
                    }}
                />
            </GridToolbarContainer>
        );
    }

    return (
        <>
            <Typography variant='h4'>Raw {withHistoDiag ? 'Histology & Diagnosis' : 'ACE'} combined data</Typography>
            <Typography>Toggle source</Typography>
            <Switch
                checked={withHistoDiag}
                onChange={(e) => {
                    setWithHistoDiag(e.target.checked);
                }}
            />
            <DataGrid
                autoHeight
                name='Raw Explore Data'
                rows={rows}
                columns={columns}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            endo_id: false,
                            mode: false,
                            endo_tool: false,
                            image_quality: false,
                            // procedure_id: false,
                            in_rectosigmoid: false,
                            diminutive: false,
                            seniority: false,
                            subject: false,
                        },
                    },
                    pagination: { pageSize: 10 },
                }}
                pageSizeOptions={[5, 10, 25, 50, 100]}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </>
    );
}
