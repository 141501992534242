import PaperCard from '../components/report/PaperCard';
import CaseAssessmentMain from '../components/assessment/CaseAssessmentMain';
import { useParams } from 'react-router-dom';
import CaseAssessmentSteps from '../components/assessment/CaseAssessmentSteps';
import useShow from '../aceapi/hooks/useShow';
import Forbidden from '../components/Login/Forbidden';

export default function CaseAssessment(props) {
    const show = useShow();

    const { id } = useParams();

    return !show.case_assessments ? (
        <Forbidden />
    ) : (
        <PaperCard title='Live Assessment' xs={12} variant='h3' align='center'>
            {id ? <CaseAssessmentSteps {...props} /> : <CaseAssessmentMain {...props} />}
        </PaperCard>
    );
}
