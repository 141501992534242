import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Main from './Main';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto-mono/300.css';
import '@fontsource/roboto-mono/400.css';
import '@fontsource/roboto-mono/500.css';
import '@fontsource/roboto-mono/700.css';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import useToken from './aceapi/hooks/useToken';
import { TokenProvider } from './config/contexts';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

function App() {
    const token = useToken();

    const queryClient = useMemo(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        suspense: true,
                        retry: (failureCount, error) => {
                            if (error?.stack?.code === 'token_not_valid') return false;
                            return failureCount < 2;
                        },
                    },
                },
                queryCache: new QueryCache({
                    onError: (error) => {
                        if (error?.stack?.code === 'token_not_valid') {
                            token.refresh.now();
                        }
                    },
                }),
            }),
        [token.refresh],
    );

    return (
        <TokenProvider value={token}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Main />
                    </LocalizationProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </TokenProvider>
    );
}

export default App;
