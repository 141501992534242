import { timedelta } from '../utils';

const MAX_DURATION = timedelta.HOUR * 3;

const filterRecording = (params) => (procs) => {
    const { recording = false } = params;
    if (!recording) return procs;
    return procs.filter((p) => p.patient_id);
};

const filterDuration = (params) => (procs) => {
    const { durationRange = null } = params;
    if (!Array.isArray(durationRange) || durationRange.length !== 2) return procs;

    return procs.filter(
        (p) => p.duration >= durationRange[0] && (p.duration <= durationRange[1] || durationRange[1] === MAX_DURATION),
    );
};

const filterProperty = (property, paramKey) => (params) => (procs) => {
    const values = params[paramKey];
    if (!Array.isArray(values)) return procs;

    return procs.filter((p) => values.includes(p[property]));
};

const filterStatus = filterProperty('status', 'statuses');
const filterModes = filterProperty('mode', 'modes');

const filterFromDate = (params) => (procs) => {
    const { fromDate = null } = params;
    if (!fromDate) return procs;
    return procs.filter((p) => p.start >= fromDate);
};

const filterRecent = (params) => (procs) => {
    const { recentOption = null } = params;
    if (!recentOption || recentOption <= 0) return procs;

    const now = new Date();
    const from = now - timedelta.DAY * recentOption;
    return procs.filter((p) => p.start >= from);
};

const filterToDate = (params) => (procs) => {
    const { toDate = null } = params;
    if (!toDate) return procs;
    return procs.filter((p) => p.start <= toDate);
};

const filterTest = (params) => (groups) => {
    const { showTest = false } = params;
    if (showTest) return groups;
    return groups.filter((g) => !g.test);
};

export const PROCEDURE_FILTERS = {
    recording: filterRecording,
    duration: filterDuration,
    status: filterStatus,
    modes: filterModes,
    recent: filterRecent,
    fromDate: filterFromDate,
    toDate: filterToDate,
};

export const GROUP_FILTERS = {
    test: filterTest,
};
