import useFilteredSummaryData from './useFilteredSummaryData';
import humanizeDuration from 'humanize-duration';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';

export default function SummaryInactivityTracer({ type, params = {} }) {
    const { filteredProcs, filteredGroups } = useFilteredSummaryData({ type, params });

    const columns = [
        { field: 'name', headerName: 'Entity (user of group)', flex: 0.5 },
        {
            field: 'last_start_ts',
            headerName: 'Time since last procedure (matching option criteria)',
            flex: 0.5,
            type: 'number',
            valueFormatter: (x) =>
                x.value
                    ? humanizeDuration(Date.now() - x.value, { units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true })
                    : Infinity,
        },
    ];

    const rows = filteredGroups
        .map((group) => ({
            ...group,
            last_start_ts:
                filteredProcs
                    .filter((proc) => group.users.includes(proc.username))
                    .map((proc) => proc.start)
                    .sort((a, b) => b - a)?.[0] ?? 0,
        }))
        .filter((x) => x.last_start_ts > 0);

    const [sortModel, setSortModel] = useState([{ field: 'last_start_ts', sort: 'asc' }]);

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            pageSize={15}
            rowsPerPageOptions={[15]}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            disableSelectionOnClick
        />
    );
}
