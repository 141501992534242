import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';

const neoplastics = ['Adenoma', 'Sessile serrated lesion (SSL)', 'Cancer', 'Other neoplastic'];
const nonNeoplastics = ['Hyperplastic', 'Other non-neoplastic'];
export const TRUSTS = ['Appropriate trust', 'Appropriate mistrust', 'Inappropriate trust', 'Inappropriate mistrust'];
export const SENIORITIES = ['consultant', 'research fellow pre cct', 'registrar', 'other'];
export const greyColor = '#d3d3d3';

export const neoplasticsFilter = (polyp) => neoplastics.includes(polyp.histo_diagnosis);
export const nonNeoplasticsFilter = (polyp) => nonNeoplastics.includes(polyp.histo_diagnosis);

export const isDiminutiveRectosigmoidACE = (polyp) => isDiminutive(polyp) && isRectosigmoidACE(polyp);
export const isRectosigmoidACE = (polyp) => polyp.in_rectosigmoid === 'yes';
export const isNotRectosigmoidACE = (polyp) => polyp.in_rectosigmoid === 'no';

export const isRectosigmoid = (polyp) =>
    ['rectum', 'sigmoid', 'rectosigmoid'].includes(polyp.histo_site?.toLowerCase());
export const isDiminutive = (polyp) => polyp.histo_size > 0 && polyp.histo_size <= 5;

export function quantitativeMetricsAgainstHistology(explore, polypFilter, neo, nonNeo, generalFilter) {
    const all = generalFilter ? explore.filter(generalFilter) : explore;
    const neos = all.filter(neoplasticsFilter);
    const tp = neos.filter((polyp) => polypFilter(polyp)?.toLowerCase() === neo);
    const fn = neos.filter((polyp) => polypFilter(polyp)?.toLowerCase() === nonNeo);
    const nonNeos = all.filter(nonNeoplasticsFilter);
    const fp = nonNeos.filter((polyp) => polypFilter(polyp)?.toLowerCase() === neo);
    const tn = nonNeos.filter((polyp) => polypFilter(polyp)?.toLowerCase() === nonNeo);

    return {
        tps: tp,
        fps: fp,
        tns: tn,
        fns: fn,
        tp: tp.length,
        fp: fp.length,
        tn: tn.length,
        fn: fn.length,
        total: tp.length + fp.length + tn.length + fn.length,
    };
}

class ExplorePolyp {
    constructor(polyp, correctDecision, correctDiagnosis, incorrectDecision, incorrectDiagnosis) {
        this.polyp = polyp;
        this.correctDecision = correctDecision;
        this.correctDiag = correctDiagnosis;
        this.incorrectDecision = incorrectDecision;
        this.incorrectDiag = incorrectDiagnosis;
    }

    firstCorrect() {
        return this.polyp.initial_decision === this.correctDecision;
    }

    firstIncorrect() {
        return this.polyp.initial_decision === this.incorrectDecision;
    }

    secondCorrect() {
        return this.polyp.second_decision === this.correctDecision;
    }

    secondIncorrect() {
        return this.polyp.second_decision === this.incorrectDecision;
    }

    // this.polyp.cadx_diagnosis comes from ACE data
    correctDiagnosis() {
        return this.polyp.cadx_diagnosis === this.correctDiag;
    }

    incorrectDiagnosis() {
        return this.polyp.cadx_diagnosis === this.incorrectDiag;
    }

    initialODCorrect() {
        return this.polyp.visual_diagnosis === this.correctDiag.toLowerCase();
    }

    initialODIncorrect() {
        return this.polyp.visual_diagnosis === this.incorrectDiag.toLowerCase();
    }

    secondODCorrect() {
        return this.polyp.expert_diagnosis === this.correctDiag.toLowerCase();
    }

    secondODIncorrect() {
        return this.polyp.expert_diagnosis === this.incorrectDiag.toLowerCase();
    }
}

export class Neoplastic extends ExplorePolyp {
    constructor(polyp) {
        super(polyp, 'Resect', 'Adenoma', 'Leave in', 'Non-Adenoma');
    }
}

export class NonNeoplastic extends ExplorePolyp {
    constructor(polyp) {
        super(polyp, 'Leave in', 'Non-Adenoma', 'Resect', 'Adenoma');
    }
}

export const DISCORDANCES = [
    {
        trust: 'Appropriate trust',
        condition: (polyp) =>
            (polyp.firstIncorrect() && polyp.secondCorrect() && polyp.correctDiagnosis()) ||
            (polyp.firstCorrect() && polyp.secondCorrect() && polyp.correctDiagnosis()),
    },
    {
        trust: 'Appropriate mistrust',
        condition: (polyp) => polyp.firstCorrect() && polyp.secondCorrect() && polyp.incorrectDiagnosis(),
    },
    {
        trust: 'Inappropriate trust',
        condition: (polyp) => polyp.firstCorrect() && polyp.secondIncorrect() && polyp.incorrectDiagnosis(),
    },
    {
        trust: 'Inappropriate mistrust',
        condition: (polyp) => polyp.firstIncorrect() && polyp.secondIncorrect() && polyp.correctDiagnosis(),
    },
];
export const CORRELATIONS = [
    {
        metric: 'Baseline',
        condition: (p) => {
            if ((p.initialODCorrect() && p.correctDiagnosis()) || (p.initialODIncorrect() && p.incorrectDiagnosis()))
                return 'Match';
            if ((p.initialODCorrect() && p.incorrectDiagnosis()) || (p.initialODIncorrect() && p.correctDiagnosis()))
                return 'Mismatch';
        },
    },
    {
        metric: 'Post-CADx',
        condition: (p) => {
            if ((p.secondODCorrect() && p.correctDiagnosis()) || (p.secondODIncorrect() && p.incorrectDiagnosis()))
                return 'Match';
            if ((p.secondODCorrect() && p.incorrectDiagnosis()) || (p.secondODIncorrect() && p.correctDiagnosis()))
                return 'Mismatch';
        },
    },
];

export function PerPolypToolkit({ active, payload, label }) {
    if (active && payload?.length) {
        const actives = payload.filter((entry) => entry.fill !== greyColor);
        return (
            <Box sx={{ backgroundColor: 'background.paper', padding: 1, borderRadius: 1, boxShadow: 1 }}>
                <Typography variant='h6'>{`${label}`}</Typography>
                {actives.map((entry, index) => (
                    <Typography key={`item-${index}`} style={{ color: entry.color }}>
                        {`${entry.name.split('-')[0]}: ${entry.value}`}
                    </Typography>
                ))}
                <Typography>{`Total: ${actives.reduce((acc, p) => acc + p.value, 0)}`}</Typography>
            </Box>
        );
    }
    return null;
}

export const generateFakePolyps = (max_fakes) => {
    const endoscopists = [
        { name: 'endoscopist 1', seniority: 'consultant' },
        { name: 'endoscopist 2', seniority: 'research fellow pre cct' },
        { name: 'endoscopist 3', seniority: 'research fellow pre cct' },
        { name: 'endoscopist 4', seniority: 'registrar' },
        { name: 'endoscopist 5', seniority: 'other' },
        { name: 'endoscopist 6', seniority: 'registrar' },
        { name: 'endoscopist 7', seniority: 'other' },
        { name: 'endoscopist 8', seniority: 'registrar' },
    ];
    const fakePolyps = [];

    for (let i = 0; i < max_fakes; i++) {
        const endoscopist = endoscopists[i % endoscopists.length];
        for (const trust of TRUSTS) {
            for (let j = 0; j < Math.floor(Math.random() * 5); j++) {
                fakePolyps.push({
                    procedure_id: `procedure_${j}_TEST`,
                    polyp_id: `polyp_${j}_TEST`,
                    diagnosis: j % 2 === 0 ? 'Adenoma' : 'Hyperplastic',
                    endo_tool: 'snare',
                    image_quality: 'high',
                    in_rectosigmoid: 'no',
                    expert_diagnosis: j % 2 === 0 ? 'adenoma' : 'hyperplastic',
                    visual_diagnosis: j % 2 === 0 ? 'adenoma' : 'hyperplastic',
                    endoscopist: endoscopist.name,
                    seniority: endoscopist.seniority,
                    initial_decision: j % 2 === 0 ? 'Resect' : 'Leave in',
                    second_decision: j % 2 === 0 ? 'Resect' : 'Leave in',
                    diminutive: false,
                    histology: j % 2 === 0 ? 'Adenoma' : 'Hyperplastic',
                    neoplastic: j % 2 === 0,
                    trust: trust,
                });
            }
        }
    }

    return fakePolyps;
};

export function MetricsGrid({ name, rows, columns }) {
    return (
        <DataGrid
            columns={columns}
            rows={rows}
            autoHeight
            pageSizeOptions={[5, 10, 25]}
            initialState={{
                pagination: { pageSize: 10 },
            }}
            rowsPerPageOptions={[5, 10, 25]}
            components={{
                Toolbar: () => (
                    <GridToolbarContainer>
                        <GridToolbarExport
                            csvOptions={{
                                fileName: `GG_${name}_Export_${new Date().toISOString().split('T')[0]}`,
                            }}
                        />
                    </GridToolbarContainer>
                ),
            }}
        />
    );
}

/** Iterate through neoplastics, and then non-neoplastics.
 *  callbackFn is thus called twice
 */
export const forAllPolyps = (explore, callbackFn) => {
    const iterateThrough = (neoNonNeoFilter, PolypClass, isNeo) => {
        const polyps = explore.filter(neoNonNeoFilter);
        callbackFn(polyps, PolypClass, isNeo);
    };
    iterateThrough(neoplasticsFilter, Neoplastic, true);
    iterateThrough(nonNeoplasticsFilter, NonNeoplastic, false);
};

export function videoColumn(navigate) {
    return {
        field: 'Video',
        headerName: 'Video',
        flex: 0.5,
        renderCell: (params) => {
            return (
                <strong>
                    <Button
                        variant='contained'
                        size='small'
                        style={{ backgroundColor: params.value ? '#376092' : 'grey' }}
                        tabIndex={params.hasFocus ? 0 : -1}
                        onClick={() => (params.value ? navigate(`/procedures/${params.value}`) : null)}
                    >
                        {params.value ? 'Open video' : 'Not available'}
                    </Button>
                </strong>
            );
        },
        // (v1, v2) are procedure_ids
        sortComparator: (v1, v2) => {
            if (v1 === null) return -1;
            if (v2 === null) return 1;
            return v1 - v2;
        },
    };
}
