import Grid from '@mui/material/Grid';
import { SwitchElement } from 'react-hook-form-mui';

export default function PreAssessmentAnnotationsSelect({ categories }) {
    return (
        <Grid container spacing={3} sx={{ width: '100%' }}>
            {categories?.map((category) => (
                <Grid item key={category.id} xs={2}>
                    <SwitchElement id={category.id} name={`ann_${category.id}`} label={category.name} />
                </Grid>
            ))}
        </Grid>
    );
}
