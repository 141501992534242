import Typography from '@mui/material/Typography';
import {
    DatePickerElement,
    MultiSelectElement,
    SelectElement,
    SliderElement,
    SwitchElement,
    TextFieldElement,
    useFormContext,
} from 'react-hook-form-mui';
import { IconButton, Stack, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

export function DateElement(props) {
    const { setValue, watch } = useFormContext();
    const value = watch(props.name);

    return (
        <DatePickerElement
            {...props}
            renderInput={(params) => <TextField {...params} />}
            onChange={(date) => setValue(props.name, date, { shouldValidate: true, shouldDirty: true })}
            inputFormat='dd/MM/yyyy'
            slotProps={{
                actionBar: {
                    actions: ['clear'],
                },
            }}
            InputProps={{
                startAdornment: value && (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setValue(props.name, null, { shouldValidate: true, shouldDirty: true });
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                ),
            }}
        />
    );
}

export const FORM_WIDGETS = {
    text: TextFieldElement,
    select: SelectElement,
    multiselect: MultiSelectElement,
    date: DateElement,
    switch: SwitchElement,
    slider: SliderElement,
};

export default function Widget(props) {
    const type = props.type;

    if (type === 'group') {
        return (
            <Stack spacing={2} {...props}>
                {props.children.map((child) => (
                    <Widget key={child.name} {...child} />
                ))}
            </Stack>
        );
    }

    const WidgetComponent = FORM_WIDGETS[type];
    if (!WidgetComponent) {
        return <Typography>Unknown widget type: {type}</Typography>;
    }
    return <WidgetComponent {...props} />;
}
