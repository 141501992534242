import { withAsyncBoundary } from '../../../aceapi/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useAssessmentsCasesRead } from '../../../aceapi/aceComponents';
import { useEffect } from 'react';
import { Skeleton } from '@mui/material';
import LiveAssessment from './LiveAssessment';

function LiveAssessmentMain(props) {
    const { uuid: procedure_id, id: case_id } = useParams();

    const navigate = useNavigate();
    const { data: caseFromId } = useAssessmentsCasesRead({ pathParams: { id: case_id } }, { enabled: !procedure_id });

    useEffect(() => {
        if (!procedure_id) {
            const uuid = caseFromId.procedure_id;
            navigate(`/assessments/${case_id}/${uuid}?step=live`);
        }
    }, [caseFromId?.procedure_id, case_id, navigate, procedure_id]);

    return procedure_id ? <LiveAssessment {...props} /> : <Skeleton variant='rectangular' height={400} />;
}

export default withAsyncBoundary(LiveAssessmentMain);
