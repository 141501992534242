import useShow from '../aceapi/hooks/useShow';
import Forbidden from '../components/Login/Forbidden';
import AceDashboardsMain from '../components/dashboards/AceDashboardsMain';
import PaperCard from '../components/report/PaperCard';

export default function AceDashboards(props) {
    const show = useShow();

    return !show.ace_dashboards ? (
        <Forbidden />
    ) : (
        <PaperCard title='Ace Metrics Dashboards' xs={12} variant='h3' align='center'>
            <AceDashboardsMain {...props} />
        </PaperCard>
    );
}
