import { AsyncBoundary } from '../../../../aceapi/utils';
import PaperCard from '../../../report/PaperCard';
import FakeProgressBar from '../../../placeholders/FakeProgressBar';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { OpticalDiagnosisPerPolyp } from './OpticalDiagnosisPerPolyp';
import { CorrelationODvsCADx } from './CorrelationODvsCADx';
import { ConfidenceInAIArm } from './ConfidenceInAIArm';
import { AgreementInOpticalDiagnosis } from './AgreementInOpticalDiagnosis';
import { RawExploreData } from './RawExploreData';
import { Rawen } from './Rawen';
import { ExploreSummary } from './ExploreSummary';
import { QuantitativeMetrics } from './QuantitativeMetrics';
import { OpticalDiagnosisPerPolypTable } from './OpticalDiagnosisPerPolypTable';
import { SizingComparison } from './SizingComparison';
import { ComparePolyps } from './ComparePolyps';

function AsyncOpticalDiagnosis() {
    return (
        <PaperCard xs={12}>
            <Stack direction='column' spacing={2}>
                <Typography variant='h4'>Optical Diagnosis Overall Performance</Typography>
                <ExploreSummary />
                <QuantitativeMetrics />
                <Rawen />
                <OpticalDiagnosisPerPolypTable />
                <OpticalDiagnosisPerPolyp />
                <CorrelationODvsCADx />
                <AgreementInOpticalDiagnosis />
                <ConfidenceInAIArm />
                <SizingComparison />
                <RawExploreData />
                <ComparePolyps />
            </Stack>
        </PaperCard>
    );
}

export default function OpticalDiagnosis() {
    return (
        <AsyncBoundary
            fallback={
                <PaperCard xs={12} title='Optical Diagnosis'>
                    <FakeProgressBar />
                </PaperCard>
            }
        >
            <AsyncOpticalDiagnosis />
        </AsyncBoundary>
    );
}
