import { timedelta } from '../utils';

/*
 * computeTimeSpans: Generate a list of time spans based on the given parameters
 * @param {Object} params - The parameters object containing the time range and period
 * @returns {Array} - An array of time spans containing the start time of each span
 * @example
 * computeTimeSpans({ from: 0, to: 100, period: 20 });
 * // => [0, 20, 40, 60, 80, 100]
 */
const computeTimeSpans = (params) => {
    const {
        fromDate = Date.now() - timedelta.YEAR,
        toDate = Date.now(),
        period = timedelta.WEEK,
        recentOption,
    } = params;
    const start = recentOption ? Date.now() - recentOption * timedelta.DAY : fromDate;

    const span = toDate - start;
    const N = Math.max(1, Math.ceil(span / period));

    return Array(N)
        .fill()
        .map((_, i) => {
            if (period < timedelta.MONTH) return start + period * i;
            const dateStart = new Date(start);
            dateStart.setMonth(dateStart.getMonth() + i * (period / timedelta.MONTH), 1);
            return dateStart.getTime();
        });
};

/*
 * computeGroupTimeSeries: Compute the number of procedures for each group in each time span
 * @param {Object} params - The parameters object containing the time range and period
 * @param {Array} spans - An array of time spans containing the start time of each span
 * @param {Array} procedures - An array of all the procedures
 * @param {Array} groups - An array of all the groups
 * @returns {Array} - An array of group objects with the number of procedures for each time span
 * @example
 * const params = { from: 0, to: 100, period: 20 };
 * const spans = [0, 20, 40, 60, 80, 100];
 * const procedures = [{ start: 10, username: 'user1' }, { start: 30, username: 'user2' }];
 * const groups = [{ name: 'group1', users: ['user1'] }, { name: 'group2', users: ['user2'] }];
 * computeGroupTimeSeries(params, spans, procedures, groups);
 * // => [
 *  { name: 'group1', users: ['user1'], values: [1, 0, 0, 0, 0, 0] },
 *  { name: 'group2', users: ['user2'], values: [0, 1, 0, 0, 0, 0] }
 * ]
 */
const computeGroupTimeSeries = (params, spans, procedures, groups) => {
    const { toDate = Date.now() } = params;

    const intervals = spans.map((start, i) => {
        const end = i < spans.length - 1 ? spans[i + 1] : toDate;
        return [start, end];
    });

    const getValues = (group) => {
        const groupProcs = procedures.filter((p) => group.users.includes(p.username));
        return intervals.map(([start, end]) => {
            return groupProcs.reduce((count, p) => {
                if (p.start >= start && p.start < end) return count + 1;
                return count;
            }, 0);
        });
    };

    return groups.map((group) => ({
        ...group,
        values: getValues(group),
    }));
};

/*
 * buildFormattedData: Build the formatted data object for the line chart
 * @param {Array} spans - An array of time spans containing the start time of each span
 * @param {Array} groupTimeSeries - An array of group objects with the number of procedures for each time span
 * @returns {Array} - An array of formatted data objects for the line chart
 * @example
 * const spans = [0, 20, 40, 60, 80, 100];
 * const groupTimeSeries = [
 * { name: 'group1', users: ['user1'], values: [1, 0, 0, 0, 0, 0] },
 * { name: 'group2', users: ['user2'], values: [0, 1, 0, 0, 0, 0] }
 * ];
 * buildFormattedData(spans, groupTimeSeries);
 * // => [
 *  { time: 0, group1: 1, group2: 0 },
 *  { time: 20, group1: 0, group2: 1 },
 *  { time: 40, group1: 0, group2: 0 },
 *  { time: 60, group1: 0, group2: 0 },
 *  { time: 80, group1: 0, group2: 0 },
 *  { time: 100, group1: 0, group2: 0 },
 * ]
 */
const buildFormattedData = (spans, groupTimeSeries) => {
    return spans.map((time, i) => {
        const groupsObject = groupTimeSeries
            .map((group) => ({
                [group.name]: group.values[i],
            }))
            .reduce((acc, val) => ({ ...acc, ...val }), {});

        return {
            time,
            ...groupsObject,
        };
    });
};

export const getProcedureTrends = (params, procedures, groups) => {
    const spans = computeTimeSpans(params);
    const groupTimeSeries = computeGroupTimeSeries(params, spans, procedures, groups);
    return buildFormattedData(spans, groupTimeSeries);
};
