import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import Typography from '@mui/material/Typography';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { BugReport } from '@mui/icons-material';
import useStickyState from '../../../report/useStickyState';
import ClearIcon from '@mui/icons-material/Clear';

function CustomFooter({ grid: { name } }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '99%' }}>
            <Typography variant='h6' fontWeight='bold'>
                {name.split('_').join(' & ')}
            </Typography>
        </Box>
    );
}
function handleDebug(row) {
    window.debugRow = row;
    console.log('Debugging row:', window.debugRow);
}
export function ComparePolyps() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const [selectedPolyps, setSelectedPolyps, resetSelectedPolyps] = useStickyState('selectedPolyps', '');

    const grids = [];
    Object.keys(explore).forEach((key) => {
        const columnNames = Object.keys(explore[key][0] ?? []).sort();
        const columns = columnNames.reduce((acc, key) => {
            const r = { field: key, headerName: key, flex: 0.5 };
            acc.push(r);
            return acc.sort();
        }, []);
        if (columns.length > 0)
            columns.push({
                field: 'debug',
                headerName: 'debug',
                flex: 0.5,
                renderCell: (params) => (
                    <IconButton onClick={() => handleDebug(params.row)}>
                        <BugReport />
                    </IconButton>
                ),
            });
        const rows = [];
        const ps = selectedPolyps.split(' ');
        const polyps = explore[key].filter((polyp) => ps.includes(polyp.id) || ps.includes(polyp.polyp_id));
        polyps?.forEach((polyp, id) => {
            rows[id] = { id };
            columnNames.forEach((name) => {
                rows[id][name] = polyp[name];
            });
        });
        grids.push({ name: key, rows, columns });
    });

    return (
        <>
            <Typography variant='h4'>Compare polyps</Typography>
            <FormControl fullWidth>
                <InputLabel htmlFor='compare-polyps-selection'>Search polyps by id</InputLabel>
                <OutlinedInput
                    id='compare-polyps-selection'
                    type='text'
                    value={selectedPolyps}
                    onChange={(e) => setSelectedPolyps(e.target.value)}
                    endAdornment={
                        <InputAdornment position='end'>
                            <IconButton
                                aria-label='clear selection'
                                onClick={resetSelectedPolyps}
                                onMouseDown={(e) => e.preventDefault()}
                                edge='end'
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                    label='Search polyps by id'
                />
            </FormControl>
            <>
                {grids.map((grid, i) => (
                    <DataGrid
                        autoHeight
                        key={i}
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    endo_id: false,
                                    mode: false,
                                    endo_tool: false,
                                    image_quality: false,
                                    initial_confidence: false,
                                    second_confidence: false,
                                    procedure_id: false,
                                    // in_rectosigmoid: false,
                                    // diminutive: false,
                                    seniority: false,
                                    subject: false,
                                },
                            },
                            pagination: { pageSize: 10 },
                        }}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        name='Polyps'
                        rows={grid.rows}
                        columns={grid.columns}
                        components={{
                            Footer: CustomFooter,
                        }}
                        componentsProps={{
                            footer: { grid },
                        }}
                    />
                ))}
            </>
        </>
    );
}
