import { AsyncBoundary } from '../../aceapi/utils';
import { useDashboardComponentsRead } from '../../aceapi/aceComponents';
import { decodeSearchParams } from '../report/useEncodedSearchParams';
import { useMemo } from 'react';
import SummaryStat from '../summary/stats/SummaryStat';
import { Skeleton, Stack, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';

function AsyncAceDashboardComponent({ component_id }) {
    const { data: component } = useDashboardComponentsRead({ pathParams: { id: component_id } });

    const decodedParams = useMemo(() => decodeSearchParams(component.encoded_preset), [component.encoded_preset]);

    return (
        <Stack>
            <Typography variant='h6'>{component.name}</Typography>
            <Tooltip title={component.description}>
                <Typography
                    variant='body2'
                    sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                >
                    ({component.description})
                </Typography>
            </Tooltip>
            <SummaryStat params={decodedParams} hideDownload />
        </Stack>
    );
}

export default function AceDashboardComponent(props) {
    return (
        <AsyncBoundary fallback={<Skeleton variant='rectangular' height={400} />}>
            <AsyncAceDashboardComponent {...props} />
        </AsyncBoundary>
    );
}
