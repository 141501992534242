import { useAceApp } from '../../../Menu/ReportAppSelector';
import { useGgExploreList } from '../../../../aceapi/aceComponents';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { MetricsGrid, videoColumn } from './OpticalDiagnosisUtils';
import Typography from '@mui/material/Typography';

const POLYP_FILTERS = [
    { name: 'Patient Id', get: (polyp) => polyp.id },
    { name: 'Endoscopist', get: (polyp) => polyp.endoscopist },
    { name: 'Change in Confidence', get: (polyp) => polyp.initial_confidence + ' -> ' + polyp.second_confidence },
    {
        name: 'Video',
        get: (polyp) => polyp.procedure_id,
    },
];

export function ConfidenceInAIArm() {
    const { app } = useAceApp();
    const { data: explore } = useGgExploreList({ queryParams: { app } });
    const navigate = useNavigate();

    const METRICS_COLS = [
        { field: 'Patient Id', headerMetric: 'Patient Id', flex: 0.5 },
        { field: 'Endoscopist', headerName: 'Endoscopist', flex: 0.5 },
        { field: 'Change in Confidence', headerName: 'Change in Confidence', flex: 0.5 },
        videoColumn(navigate),
    ].map((col) => ({
        ...col,
        valueFormatter: (params) => {
            const value = params.value;
            if (typeof value === 'number' && value % 1 !== 0) return (Math.round(value * 100) / 100).toFixed(2);
            return value;
        },
    }));
    const polyps = useMemo(() => {
        return explore.diag_histo.filter((polyp) => polyp.arm === 'AI Arm');
    }, [explore.diag_histo]);
    const rows = useMemo(() => {
        const rows = [];
        polyps.forEach((polyp, id) => {
            rows[id] = { id };
            POLYP_FILTERS.forEach(({ name, get }) => {
                rows[id][name] = get(polyp);
            });
        });
        return rows;
    }, [polyps]);

    return (
        <Stack direction='column' spacing={2}>
            <Typography variant='h4'>Confidence in AI Arm</Typography>
            <MetricsGrid name='AgreementInOD' rows={rows} columns={METRICS_COLS} />
        </Stack>
    );
}
