/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from '@tanstack/react-query';
import { useAceContext, AceContext } from './aceContext';
import type * as Fetcher from './aceFetcher';
import { aceFetch } from './aceFetcher';
import type * as Schemas from './aceSchemas';
import type * as RequestBodies from './aceRequestBodies';

export type AssessmentsAnnotationCategoriesListError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsAnnotationCategoriesListResponse = Schemas.AnnotationCategory[];

export type AssessmentsAnnotationCategoriesListVariables = AceContext['fetcherOptions'];

export const fetchAssessmentsAnnotationCategoriesList = (
    variables: AssessmentsAnnotationCategoriesListVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        AssessmentsAnnotationCategoriesListResponse,
        AssessmentsAnnotationCategoriesListError,
        undefined,
        {},
        {},
        {}
    >({ url: '/assessments/annotation_categories/', method: 'get', ...variables, signal });

export const useAssessmentsAnnotationCategoriesList = <TData = AssessmentsAnnotationCategoriesListResponse>(
    variables: AssessmentsAnnotationCategoriesListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            AssessmentsAnnotationCategoriesListResponse,
            AssessmentsAnnotationCategoriesListError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        AssessmentsAnnotationCategoriesListResponse,
        AssessmentsAnnotationCategoriesListError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/assessments/annotation_categories/',
            operationId: 'assessmentsAnnotationCategoriesList',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsAnnotationCategoriesList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsAnnotationCategoriesReadPathParams = {
    /**
     * A unique integer value identifying this annotation category.
     */
    id: number;
};

export type AssessmentsAnnotationCategoriesReadError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsAnnotationCategoriesReadVariables = {
    pathParams: AssessmentsAnnotationCategoriesReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsAnnotationCategoriesRead = (
    variables: AssessmentsAnnotationCategoriesReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.AnnotationCategory,
        AssessmentsAnnotationCategoriesReadError,
        undefined,
        {},
        {},
        AssessmentsAnnotationCategoriesReadPathParams
    >({ url: '/assessments/annotation_categories/{id}/', method: 'get', ...variables, signal });

export const useAssessmentsAnnotationCategoriesRead = <TData = Schemas.AnnotationCategory>(
    variables: AssessmentsAnnotationCategoriesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AnnotationCategory, AssessmentsAnnotationCategoriesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AnnotationCategory, AssessmentsAnnotationCategoriesReadError, TData>({
        queryKey: queryKeyFn({
            path: '/assessments/annotation_categories/{id}/',
            operationId: 'assessmentsAnnotationCategoriesRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsAnnotationCategoriesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsAssessmentFieldsListQueryParams = {
    /**
     * Filter by stage
     */
    stage?: 'PRE' | 'POST';
};

export type AssessmentsAssessmentFieldsListError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsAssessmentFieldsListResponse = Schemas.AssessmentField[];

export type AssessmentsAssessmentFieldsListVariables = {
    queryParams?: AssessmentsAssessmentFieldsListQueryParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsAssessmentFieldsList = (
    variables: AssessmentsAssessmentFieldsListVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        AssessmentsAssessmentFieldsListResponse,
        AssessmentsAssessmentFieldsListError,
        undefined,
        {},
        AssessmentsAssessmentFieldsListQueryParams,
        {}
    >({ url: '/assessments/assessment_fields/', method: 'get', ...variables, signal });

export const useAssessmentsAssessmentFieldsList = <TData = AssessmentsAssessmentFieldsListResponse>(
    variables: AssessmentsAssessmentFieldsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            AssessmentsAssessmentFieldsListResponse,
            AssessmentsAssessmentFieldsListError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<AssessmentsAssessmentFieldsListResponse, AssessmentsAssessmentFieldsListError, TData>({
        queryKey: queryKeyFn({
            path: '/assessments/assessment_fields/',
            operationId: 'assessmentsAssessmentFieldsList',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsAssessmentFieldsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsAssessmentFieldsReadPathParams = {
    /**
     * A unique integer value identifying this assessment field.
     */
    id: number;
};

export type AssessmentsAssessmentFieldsReadError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsAssessmentFieldsReadVariables = {
    pathParams: AssessmentsAssessmentFieldsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsAssessmentFieldsRead = (
    variables: AssessmentsAssessmentFieldsReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.AssessmentField,
        AssessmentsAssessmentFieldsReadError,
        undefined,
        {},
        {},
        AssessmentsAssessmentFieldsReadPathParams
    >({ url: '/assessments/assessment_fields/{id}/', method: 'get', ...variables, signal });

export const useAssessmentsAssessmentFieldsRead = <TData = Schemas.AssessmentField>(
    variables: AssessmentsAssessmentFieldsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AssessmentField, AssessmentsAssessmentFieldsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AssessmentField, AssessmentsAssessmentFieldsReadError, TData>({
        queryKey: queryKeyFn({
            path: '/assessments/assessment_fields/{id}/',
            operationId: 'assessmentsAssessmentFieldsRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsAssessmentFieldsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsCasesListError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesListResponse = Schemas.CaseAssessment[];

export type AssessmentsCasesListVariables = AceContext['fetcherOptions'];

export const fetchAssessmentsCasesList = (variables: AssessmentsCasesListVariables, signal?: AbortSignal) =>
    aceFetch<AssessmentsCasesListResponse, AssessmentsCasesListError, undefined, {}, {}, {}>({
        url: '/assessments/cases/',
        method: 'get',
        ...variables,
        signal,
    });

export const useAssessmentsCasesList = <TData = AssessmentsCasesListResponse>(
    variables: AssessmentsCasesListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<AssessmentsCasesListResponse, AssessmentsCasesListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<AssessmentsCasesListResponse, AssessmentsCasesListError, TData>({
        queryKey: queryKeyFn({ path: '/assessments/cases/', operationId: 'assessmentsCasesList', variables }),
        queryFn: ({ signal }) => fetchAssessmentsCasesList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsCasesCreateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesCreateVariables = {
    body?: RequestBodies.CaseAssessment;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesCreate = (variables: AssessmentsCasesCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.CaseAssessment, AssessmentsCasesCreateError, RequestBodies.CaseAssessment, {}, {}, {}>({
        url: '/assessments/cases/',
        method: 'post',
        ...variables,
        signal,
    });

export const useAssessmentsCasesCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.CaseAssessment,
            AssessmentsCasesCreateError,
            AssessmentsCasesCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.CaseAssessment, AssessmentsCasesCreateError, AssessmentsCasesCreateVariables>(
        {
            mutationFn: (variables: AssessmentsCasesCreateVariables) =>
                fetchAssessmentsCasesCreate({ ...fetcherOptions, ...variables }),
            ...options,
        },
    );
};

export type AssessmentsCasesByProcedureIdQueryParams = {
    /**
     * Procedure ID
     */
    procedureId: string;
};

export type AssessmentsCasesByProcedureIdError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesByProcedureIdVariables = {
    queryParams: AssessmentsCasesByProcedureIdQueryParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesByProcedureId = (
    variables: AssessmentsCasesByProcedureIdVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.CaseAssessment,
        AssessmentsCasesByProcedureIdError,
        undefined,
        {},
        AssessmentsCasesByProcedureIdQueryParams,
        {}
    >({ url: '/assessments/cases/by_procedure_id/', method: 'get', ...variables, signal });

export const useAssessmentsCasesByProcedureId = <TData = Schemas.CaseAssessment>(
    variables: AssessmentsCasesByProcedureIdVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.CaseAssessment, AssessmentsCasesByProcedureIdError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.CaseAssessment, AssessmentsCasesByProcedureIdError, TData>({
        queryKey: queryKeyFn({
            path: '/assessments/cases/by_procedure_id/',
            operationId: 'assessmentsCasesByProcedureId',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsCasesByProcedureId({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsCasesGetAssessmentTypesError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesGetAssessmentTypesResponse = Schemas.CaseAssessmentType[];

export type AssessmentsCasesGetAssessmentTypesVariables = AceContext['fetcherOptions'];

export const fetchAssessmentsCasesGetAssessmentTypes = (
    variables: AssessmentsCasesGetAssessmentTypesVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        AssessmentsCasesGetAssessmentTypesResponse,
        AssessmentsCasesGetAssessmentTypesError,
        undefined,
        {},
        {},
        {}
    >({ url: '/assessments/cases/get_assessment_types/', method: 'get', ...variables, signal });

export const useAssessmentsCasesGetAssessmentTypes = <TData = AssessmentsCasesGetAssessmentTypesResponse>(
    variables: AssessmentsCasesGetAssessmentTypesVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            AssessmentsCasesGetAssessmentTypesResponse,
            AssessmentsCasesGetAssessmentTypesError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        AssessmentsCasesGetAssessmentTypesResponse,
        AssessmentsCasesGetAssessmentTypesError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/assessments/cases/get_assessment_types/',
            operationId: 'assessmentsCasesGetAssessmentTypes',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsCasesGetAssessmentTypes({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsCasesReadPathParams = {
    /**
     * A unique integer value identifying this case assessment.
     */
    id: number;
};

export type AssessmentsCasesReadError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesReadVariables = {
    pathParams: AssessmentsCasesReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesRead = (variables: AssessmentsCasesReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.CaseAssessment, AssessmentsCasesReadError, undefined, {}, {}, AssessmentsCasesReadPathParams>({
        url: '/assessments/cases/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

export const useAssessmentsCasesRead = <TData = Schemas.CaseAssessment>(
    variables: AssessmentsCasesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.CaseAssessment, AssessmentsCasesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.CaseAssessment, AssessmentsCasesReadError, TData>({
        queryKey: queryKeyFn({ path: '/assessments/cases/{id}/', operationId: 'assessmentsCasesRead', variables }),
        queryFn: ({ signal }) => fetchAssessmentsCasesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsCasesUpdatePathParams = {
    /**
     * A unique integer value identifying this case assessment.
     */
    id: number;
};

export type AssessmentsCasesUpdateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesUpdateVariables = {
    body?: RequestBodies.CaseAssessment;
    pathParams: AssessmentsCasesUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesUpdate = (variables: AssessmentsCasesUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.CaseAssessment,
        AssessmentsCasesUpdateError,
        RequestBodies.CaseAssessment,
        {},
        {},
        AssessmentsCasesUpdatePathParams
    >({ url: '/assessments/cases/{id}/', method: 'put', ...variables, signal });

export const useAssessmentsCasesUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.CaseAssessment,
            AssessmentsCasesUpdateError,
            AssessmentsCasesUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.CaseAssessment, AssessmentsCasesUpdateError, AssessmentsCasesUpdateVariables>(
        {
            mutationFn: (variables: AssessmentsCasesUpdateVariables) =>
                fetchAssessmentsCasesUpdate({ ...fetcherOptions, ...variables }),
            ...options,
        },
    );
};

export type AssessmentsCasesPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this case assessment.
     */
    id: number;
};

export type AssessmentsCasesPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesPartialUpdateVariables = {
    body?: RequestBodies.CaseAssessment;
    pathParams: AssessmentsCasesPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesPartialUpdate = (
    variables: AssessmentsCasesPartialUpdateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.CaseAssessment,
        AssessmentsCasesPartialUpdateError,
        RequestBodies.CaseAssessment,
        {},
        {},
        AssessmentsCasesPartialUpdatePathParams
    >({ url: '/assessments/cases/{id}/', method: 'patch', ...variables, signal });

export const useAssessmentsCasesPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.CaseAssessment,
            AssessmentsCasesPartialUpdateError,
            AssessmentsCasesPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.CaseAssessment,
        AssessmentsCasesPartialUpdateError,
        AssessmentsCasesPartialUpdateVariables
    >({
        mutationFn: (variables: AssessmentsCasesPartialUpdateVariables) =>
            fetchAssessmentsCasesPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type AssessmentsCasesDeletePathParams = {
    /**
     * A unique integer value identifying this case assessment.
     */
    id: number;
};

export type AssessmentsCasesDeleteError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesDeleteVariables = {
    pathParams: AssessmentsCasesDeletePathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesDelete = (variables: AssessmentsCasesDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, AssessmentsCasesDeleteError, undefined, {}, {}, AssessmentsCasesDeletePathParams>({
        url: '/assessments/cases/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

export const useAssessmentsCasesDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, AssessmentsCasesDeleteError, AssessmentsCasesDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, AssessmentsCasesDeleteError, AssessmentsCasesDeleteVariables>({
        mutationFn: (variables: AssessmentsCasesDeleteVariables) =>
            fetchAssessmentsCasesDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type AssessmentsCasesGetAssessmentAnswersPathParams = {
    /**
     * A unique integer value identifying this case assessment.
     */
    id: number;
};

export type AssessmentsCasesGetAssessmentAnswersQueryParams = {
    /**
     * Filter by stage
     */
    stage?: 'PRE' | 'POST';
};

export type AssessmentsCasesGetAssessmentAnswersError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesGetAssessmentAnswersResponse = Schemas.AssessmentAnswer[];

export type AssessmentsCasesGetAssessmentAnswersVariables = {
    pathParams: AssessmentsCasesGetAssessmentAnswersPathParams;
    queryParams?: AssessmentsCasesGetAssessmentAnswersQueryParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesGetAssessmentAnswers = (
    variables: AssessmentsCasesGetAssessmentAnswersVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        AssessmentsCasesGetAssessmentAnswersResponse,
        AssessmentsCasesGetAssessmentAnswersError,
        undefined,
        {},
        AssessmentsCasesGetAssessmentAnswersQueryParams,
        AssessmentsCasesGetAssessmentAnswersPathParams
    >({ url: '/assessments/cases/{id}/get_assessment_answers/', method: 'get', ...variables, signal });

export const useAssessmentsCasesGetAssessmentAnswers = <TData = AssessmentsCasesGetAssessmentAnswersResponse>(
    variables: AssessmentsCasesGetAssessmentAnswersVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            AssessmentsCasesGetAssessmentAnswersResponse,
            AssessmentsCasesGetAssessmentAnswersError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        AssessmentsCasesGetAssessmentAnswersResponse,
        AssessmentsCasesGetAssessmentAnswersError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/assessments/cases/{id}/get_assessment_answers/',
            operationId: 'assessmentsCasesGetAssessmentAnswers',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsCasesGetAssessmentAnswers({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsCasesUpdateAssessmentAnswersPathParams = {
    /**
     * A unique integer value identifying this case assessment.
     */
    id: number;
};

export type AssessmentsCasesUpdateAssessmentAnswersError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsCasesUpdateAssessmentAnswersVariables = {
    body: Schemas.CaseAssessmentAnswers;
    pathParams: AssessmentsCasesUpdateAssessmentAnswersPathParams;
} & AceContext['fetcherOptions'];

export const fetchAssessmentsCasesUpdateAssessmentAnswers = (
    variables: AssessmentsCasesUpdateAssessmentAnswersVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        undefined,
        AssessmentsCasesUpdateAssessmentAnswersError,
        Schemas.CaseAssessmentAnswers,
        {},
        {},
        AssessmentsCasesUpdateAssessmentAnswersPathParams
    >({ url: '/assessments/cases/{id}/update_assessment_answers/', method: 'put', ...variables, signal });

export const useAssessmentsCasesUpdateAssessmentAnswers = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            undefined,
            AssessmentsCasesUpdateAssessmentAnswersError,
            AssessmentsCasesUpdateAssessmentAnswersVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        undefined,
        AssessmentsCasesUpdateAssessmentAnswersError,
        AssessmentsCasesUpdateAssessmentAnswersVariables
    >({
        mutationFn: (variables: AssessmentsCasesUpdateAssessmentAnswersVariables) =>
            fetchAssessmentsCasesUpdateAssessmentAnswers({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type AssessmentsLiveFormsListError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsLiveFormsListResponse = Schemas.LiveAssessmentForm[];

export type AssessmentsLiveFormsListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing live report forms.
 */
export const fetchAssessmentsLiveFormsList = (variables: AssessmentsLiveFormsListVariables, signal?: AbortSignal) =>
    aceFetch<AssessmentsLiveFormsListResponse, AssessmentsLiveFormsListError, undefined, {}, {}, {}>({
        url: '/assessments/live_forms/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing live report forms.
 */
export const useAssessmentsLiveFormsList = <TData = AssessmentsLiveFormsListResponse>(
    variables: AssessmentsLiveFormsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<AssessmentsLiveFormsListResponse, AssessmentsLiveFormsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<AssessmentsLiveFormsListResponse, AssessmentsLiveFormsListError, TData>({
        queryKey: queryKeyFn({ path: '/assessments/live_forms/', operationId: 'assessmentsLiveFormsList', variables }),
        queryFn: ({ signal }) => fetchAssessmentsLiveFormsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AssessmentsLiveFormsReadPathParams = {
    /**
     * A unique integer value identifying this live assessment form.
     */
    id: number;
};

export type AssessmentsLiveFormsReadError = Fetcher.ErrorWrapper<undefined>;

export type AssessmentsLiveFormsReadVariables = {
    pathParams: AssessmentsLiveFormsReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing live report forms.
 */
export const fetchAssessmentsLiveFormsRead = (variables: AssessmentsLiveFormsReadVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.LiveAssessmentForm,
        AssessmentsLiveFormsReadError,
        undefined,
        {},
        {},
        AssessmentsLiveFormsReadPathParams
    >({ url: '/assessments/live_forms/{id}/', method: 'get', ...variables, signal });

/**
 * Endpoint for managing live report forms.
 */
export const useAssessmentsLiveFormsRead = <TData = Schemas.LiveAssessmentForm>(
    variables: AssessmentsLiveFormsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.LiveAssessmentForm, AssessmentsLiveFormsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.LiveAssessmentForm, AssessmentsLiveFormsReadError, TData>({
        queryKey: queryKeyFn({
            path: '/assessments/live_forms/{id}/',
            operationId: 'assessmentsLiveFormsRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchAssessmentsLiveFormsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AuthorizedReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type AuthorizedReadError = Fetcher.ErrorWrapper<undefined>;

export type AuthorizedReadVariables = {
    pathParams: AuthorizedReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Dummy endpoint used to check if one has access to a specific procedure using the procedure_id.
 */
export const fetchAuthorizedRead = (variables: AuthorizedReadVariables, signal?: AbortSignal) =>
    aceFetch<undefined, AuthorizedReadError, undefined, {}, {}, AuthorizedReadPathParams>({
        url: '/authorized/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Dummy endpoint used to check if one has access to a specific procedure using the procedure_id.
 */
export const useAuthorizedRead = <TData = undefined>(
    variables: AuthorizedReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, AuthorizedReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, AuthorizedReadError, TData>({
        queryKey: queryKeyFn({ path: '/authorized/{procedureId}/', operationId: 'authorizedRead', variables }),
        queryFn: ({ signal }) => fetchAuthorizedRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type AvgReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type AvgReadQueryParams = {
    /**
     * Name of attribute key of the desired plotting data
     */
    plot?: string;
};

export type AvgReadError = Fetcher.ErrorWrapper<undefined>;

export type AvgReadVariables = {
    pathParams: AvgReadPathParams;
    queryParams?: AvgReadQueryParams;
} & AceContext['fetcherOptions'];

/**
 * This viewset is used to retrieve---for a specific procedure---the average value of datasets (time series) stored
 * in caddie attributes such as network_level or visible_mucosa.
 */
export const fetchAvgRead = (variables: AvgReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AverageAttribute, AvgReadError, undefined, {}, AvgReadQueryParams, AvgReadPathParams>({
        url: '/avg/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * This viewset is used to retrieve---for a specific procedure---the average value of datasets (time series) stored
 * in caddie attributes such as network_level or visible_mucosa.
 */
export const useAvgRead = <TData = Schemas.AverageAttribute>(
    variables: AvgReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AverageAttribute, AvgReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AverageAttribute, AvgReadError, TData>({
        queryKey: queryKeyFn({ path: '/avg/{procedureId}/', operationId: 'avgRead', variables }),
        queryFn: ({ signal }) => fetchAvgRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type BillingListQueryParams = {
    /**
     * Date from which to start counting procedures (for the procedure_count), format must be 'yyyymmdd', defaults to the first day of the current month
     *
     * @default 20250201
     */
    from?: number;
    /**
     * Date from which to stop counting procedures (for the procedure_count), format must be 'yyyymmdd', defaults to the current date
     *
     * @default 20250212
     */
    to?: number;
};

export type BillingListError = Fetcher.ErrorWrapper<undefined>;

export type BillingListResponse = Schemas.BillingData[];

export type BillingListVariables = {
    queryParams?: BillingListQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for retrieving usage data from CADDIE Users (ReportUser model, not caddie report users).
 */
export const fetchBillingList = (variables: BillingListVariables, signal?: AbortSignal) =>
    aceFetch<BillingListResponse, BillingListError, undefined, {}, BillingListQueryParams, {}>({
        url: '/billing/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for retrieving usage data from CADDIE Users (ReportUser model, not caddie report users).
 */
export const useBillingList = <TData = BillingListResponse>(
    variables: BillingListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<BillingListResponse, BillingListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<BillingListResponse, BillingListError, TData>({
        queryKey: queryKeyFn({ path: '/billing/', operationId: 'billingList', variables }),
        queryFn: ({ signal }) => fetchBillingList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type BillingReadPathParams = {
    /**
     * CADDIE username (different from caddie-report user), e.g. CADDIE-UCLH-001
     */
    username: string;
};

export type BillingReadQueryParams = {
    /**
     * Date from which to start counting procedures (for the procedure_count), format must be 'yyyymmdd', defaults to the first day of the current month
     *
     * @default 20250201
     */
    from?: number;
    /**
     * Date from which to stop counting procedures (for the procedure_count), format must be 'yyyymmdd', defaults to the current date
     *
     * @default 20250212
     */
    to?: number;
};

export type BillingReadError = Fetcher.ErrorWrapper<undefined>;

export type BillingReadVariables = {
    pathParams: BillingReadPathParams;
    queryParams?: BillingReadQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for retrieving usage data from CADDIE Users (ReportUser model, not caddie report users).
 */
export const fetchBillingRead = (variables: BillingReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.BillingData, BillingReadError, undefined, {}, BillingReadQueryParams, BillingReadPathParams>({
        url: '/billing/{username}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for retrieving usage data from CADDIE Users (ReportUser model, not caddie report users).
 */
export const useBillingRead = <TData = Schemas.BillingData>(
    variables: BillingReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.BillingData, BillingReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.BillingData, BillingReadError, TData>({
        queryKey: queryKeyFn({ path: '/billing/{username}/', operationId: 'billingRead', variables }),
        queryFn: ({ signal }) => fetchBillingRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ChangelogListError = Fetcher.ErrorWrapper<undefined>;

export type ChangelogListVariables = AceContext['fetcherOptions'];

export const fetchChangelogList = (variables: ChangelogListVariables, signal?: AbortSignal) =>
    aceFetch<undefined, ChangelogListError, undefined, {}, {}, {}>({
        url: '/changelog/',
        method: 'get',
        ...variables,
        signal,
    });

export const useChangelogList = <TData = undefined>(
    variables: ChangelogListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, ChangelogListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, ChangelogListError, TData>({
        queryKey: queryKeyFn({ path: '/changelog/', operationId: 'changelogList', variables }),
        queryFn: ({ signal }) => fetchChangelogList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleListError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleListResponse = Schemas.EagleReport[];

export type ClinicalreportEagleListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleList = (variables: ClinicalreportEagleListVariables, signal?: AbortSignal) =>
    aceFetch<ClinicalreportEagleListResponse, ClinicalreportEagleListError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleList = <TData = ClinicalreportEagleListResponse>(
    variables: ClinicalreportEagleListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ClinicalreportEagleListResponse, ClinicalreportEagleListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ClinicalreportEagleListResponse, ClinicalreportEagleListError, TData>({
        queryKey: queryKeyFn({ path: '/clinicalreport/eagle/', operationId: 'clinicalreportEagleList', variables }),
        queryFn: ({ signal }) => fetchClinicalreportEagleList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleDownloadPatientExcelError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleDownloadPatientExcelResponse = Schemas.EagleReport[];

export type ClinicalreportEagleDownloadPatientExcelVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleDownloadPatientExcel = (
    variables: ClinicalreportEagleDownloadPatientExcelVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ClinicalreportEagleDownloadPatientExcelResponse,
        ClinicalreportEagleDownloadPatientExcelError,
        undefined,
        {},
        {},
        {}
    >({ url: '/clinicalreport/eagle/download_patient_excel/', method: 'get', ...variables, signal });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleDownloadPatientExcel = <TData = ClinicalreportEagleDownloadPatientExcelResponse>(
    variables: ClinicalreportEagleDownloadPatientExcelVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ClinicalreportEagleDownloadPatientExcelResponse,
            ClinicalreportEagleDownloadPatientExcelError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ClinicalreportEagleDownloadPatientExcelResponse,
        ClinicalreportEagleDownloadPatientExcelError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/download_patient_excel/',
            operationId: 'clinicalreportEagleDownloadPatientExcel',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportEagleDownloadPatientExcel({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleDownloadReportExcelError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleDownloadReportExcelResponse = Schemas.EagleReport[];

export type ClinicalreportEagleDownloadReportExcelVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleDownloadReportExcel = (
    variables: ClinicalreportEagleDownloadReportExcelVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ClinicalreportEagleDownloadReportExcelResponse,
        ClinicalreportEagleDownloadReportExcelError,
        undefined,
        {},
        {},
        {}
    >({ url: '/clinicalreport/eagle/download_report_excel/', method: 'get', ...variables, signal });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleDownloadReportExcel = <TData = ClinicalreportEagleDownloadReportExcelResponse>(
    variables: ClinicalreportEagleDownloadReportExcelVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ClinicalreportEagleDownloadReportExcelResponse,
            ClinicalreportEagleDownloadReportExcelError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ClinicalreportEagleDownloadReportExcelResponse,
        ClinicalreportEagleDownloadReportExcelError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/download_report_excel/',
            operationId: 'clinicalreportEagleDownloadReportExcel',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportEagleDownloadReportExcel({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleEndoscopistSummaryError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleEndoscopistSummaryVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleEndoscopistSummary = (
    variables: ClinicalreportEagleEndoscopistSummaryVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.EndoscopistSummary, ClinicalreportEagleEndoscopistSummaryError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/endoscopist_summary/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleEndoscopistSummary = <TData = Schemas.EndoscopistSummary>(
    variables: ClinicalreportEagleEndoscopistSummaryVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.EndoscopistSummary, ClinicalreportEagleEndoscopistSummaryError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.EndoscopistSummary, ClinicalreportEagleEndoscopistSummaryError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/endoscopist_summary/',
            operationId: 'clinicalreportEagleEndoscopistSummary',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportEagleEndoscopistSummary({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleExtendedError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleExtendedVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleExtended = (
    variables: ClinicalreportEagleExtendedVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.EagleReportExtended, ClinicalreportEagleExtendedError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/extended/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleExtended = <TData = Schemas.EagleReportExtended>(
    variables: ClinicalreportEagleExtendedVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.EagleReportExtended, ClinicalreportEagleExtendedError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.EagleReportExtended, ClinicalreportEagleExtendedError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/extended/',
            operationId: 'clinicalreportEagleExtended',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleExtended({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleGeneratePatientExcelError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleGeneratePatientExcelResponse = Schemas.EagleReport[];

export type ClinicalreportEagleGeneratePatientExcelVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleGeneratePatientExcel = (
    variables: ClinicalreportEagleGeneratePatientExcelVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ClinicalreportEagleGeneratePatientExcelResponse,
        ClinicalreportEagleGeneratePatientExcelError,
        undefined,
        {},
        {},
        {}
    >({ url: '/clinicalreport/eagle/generate_patient_excel/', method: 'get', ...variables, signal });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleGeneratePatientExcel = <TData = ClinicalreportEagleGeneratePatientExcelResponse>(
    variables: ClinicalreportEagleGeneratePatientExcelVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ClinicalreportEagleGeneratePatientExcelResponse,
            ClinicalreportEagleGeneratePatientExcelError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ClinicalreportEagleGeneratePatientExcelResponse,
        ClinicalreportEagleGeneratePatientExcelError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/generate_patient_excel/',
            operationId: 'clinicalreportEagleGeneratePatientExcel',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportEagleGeneratePatientExcel({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleGetTotalRecruitmentError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleGetTotalRecruitmentVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleGetTotalRecruitment = (
    variables: ClinicalreportEagleGetTotalRecruitmentVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.TrialStats, ClinicalreportEagleGetTotalRecruitmentError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/get_total_recruitment/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleGetTotalRecruitment = <TData = Schemas.TrialStats>(
    variables: ClinicalreportEagleGetTotalRecruitmentVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.TrialStats, ClinicalreportEagleGetTotalRecruitmentError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.TrialStats, ClinicalreportEagleGetTotalRecruitmentError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/get_total_recruitment/',
            operationId: 'clinicalreportEagleGetTotalRecruitment',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportEagleGetTotalRecruitment({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleHistologyStatsError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleHistologyStatsVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleHistologyStats = (
    variables: ClinicalreportEagleHistologyStatsVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.Histology, ClinicalreportEagleHistologyStatsError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/histology_stats/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleHistologyStats = <TData = Schemas.Histology>(
    variables: ClinicalreportEagleHistologyStatsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Histology, ClinicalreportEagleHistologyStatsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Histology, ClinicalreportEagleHistologyStatsError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/histology_stats/',
            operationId: 'clinicalreportEagleHistologyStats',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleHistologyStats({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleHospitalSummaryError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleHospitalSummaryVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleHospitalSummary = (
    variables: ClinicalreportEagleHospitalSummaryVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.HospitalSummary, ClinicalreportEagleHospitalSummaryError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/hospital_summary/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleHospitalSummary = <TData = Schemas.HospitalSummary>(
    variables: ClinicalreportEagleHospitalSummaryVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.HospitalSummary, ClinicalreportEagleHospitalSummaryError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.HospitalSummary, ClinicalreportEagleHospitalSummaryError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/hospital_summary/',
            operationId: 'clinicalreportEagleHospitalSummary',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleHospitalSummary({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleIgnoredIssuesError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleIgnoredIssuesVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleIgnoredIssues = (
    variables: ClinicalreportEagleIgnoredIssuesVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.IgnoreIssue, ClinicalreportEagleIgnoredIssuesError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/ignored_issues/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleIgnoredIssues = <TData = Schemas.IgnoreIssue>(
    variables: ClinicalreportEagleIgnoredIssuesVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.IgnoreIssue, ClinicalreportEagleIgnoredIssuesError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.IgnoreIssue, ClinicalreportEagleIgnoredIssuesError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/ignored_issues/',
            operationId: 'clinicalreportEagleIgnoredIssues',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleIgnoredIssues({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleProcedureDatesBySiteError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleProcedureDatesBySiteVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleProcedureDatesBySite = (
    variables: ClinicalreportEagleProcedureDatesBySiteVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.ProcedureDate, ClinicalreportEagleProcedureDatesBySiteError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/procedure_dates_by_site/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleProcedureDatesBySite = <TData = Schemas.ProcedureDate>(
    variables: ClinicalreportEagleProcedureDatesBySiteVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ProcedureDate, ClinicalreportEagleProcedureDatesBySiteError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ProcedureDate, ClinicalreportEagleProcedureDatesBySiteError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/procedure_dates_by_site/',
            operationId: 'clinicalreportEagleProcedureDatesBySite',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportEagleProcedureDatesBySite({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleTrialSummaryStatsError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleTrialSummaryStatsVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleTrialSummaryStats = (
    variables: ClinicalreportEagleTrialSummaryStatsVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.PolypStats, ClinicalreportEagleTrialSummaryStatsError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/trial_summary_stats/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleTrialSummaryStats = <TData = Schemas.PolypStats>(
    variables: ClinicalreportEagleTrialSummaryStatsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.PolypStats, ClinicalreportEagleTrialSummaryStatsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.PolypStats, ClinicalreportEagleTrialSummaryStatsError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/trial_summary_stats/',
            operationId: 'clinicalreportEagleTrialSummaryStats',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleTrialSummaryStats({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleWithTaskStatusError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleWithTaskStatusVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleWithTaskStatus = (
    variables: ClinicalreportEagleWithTaskStatusVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.EagleReportExtended, ClinicalreportEagleWithTaskStatusError, undefined, {}, {}, {}>({
        url: '/clinicalreport/eagle/with_task_status/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleWithTaskStatus = <TData = Schemas.EagleReportExtended>(
    variables: ClinicalreportEagleWithTaskStatusVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.EagleReportExtended, ClinicalreportEagleWithTaskStatusError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.EagleReportExtended, ClinicalreportEagleWithTaskStatusError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/with_task_status/',
            operationId: 'clinicalreportEagleWithTaskStatus',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleWithTaskStatus({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleReadPathParams = {
    /**
     * A unique integer value identifying this eagle report.
     */
    id: number;
};

export type ClinicalreportEagleReadError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleReadVariables = {
    pathParams: ClinicalreportEagleReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleRead = (variables: ClinicalreportEagleReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.EagleReport, ClinicalreportEagleReadError, undefined, {}, {}, ClinicalreportEagleReadPathParams>({
        url: '/clinicalreport/eagle/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleRead = <TData = Schemas.EagleReport>(
    variables: ClinicalreportEagleReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.EagleReport, ClinicalreportEagleReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.EagleReport, ClinicalreportEagleReadError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/eagle/{id}/',
            operationId: 'clinicalreportEagleRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportEagleRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportEagleUpdatePathParams = {
    /**
     * A unique integer value identifying this eagle report.
     */
    id: number;
};

export type ClinicalreportEagleUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEagleUpdateVariables = {
    body?: RequestBodies.EagleReport;
    pathParams: ClinicalreportEagleUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEagleUpdate = (variables: ClinicalreportEagleUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.EagleReport,
        ClinicalreportEagleUpdateError,
        RequestBodies.EagleReport,
        {},
        {},
        ClinicalreportEagleUpdatePathParams
    >({ url: '/clinicalreport/eagle/{id}/', method: 'put', ...variables, signal });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEagleUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.EagleReport,
            ClinicalreportEagleUpdateError,
            ClinicalreportEagleUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.EagleReport,
        ClinicalreportEagleUpdateError,
        ClinicalreportEagleUpdateVariables
    >({
        mutationFn: (variables: ClinicalreportEagleUpdateVariables) =>
            fetchClinicalreportEagleUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ClinicalreportEaglePartialUpdatePathParams = {
    /**
     * A unique integer value identifying this eagle report.
     */
    id: number;
};

export type ClinicalreportEaglePartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportEaglePartialUpdateVariables = {
    body?: RequestBodies.EagleReport;
    pathParams: ClinicalreportEaglePartialUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for Eagle Clinical Reports
 */
export const fetchClinicalreportEaglePartialUpdate = (
    variables: ClinicalreportEaglePartialUpdateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.EagleReport,
        ClinicalreportEaglePartialUpdateError,
        RequestBodies.EagleReport,
        {},
        {},
        ClinicalreportEaglePartialUpdatePathParams
    >({ url: '/clinicalreport/eagle/{id}/', method: 'patch', ...variables, signal });

/**
 * Endpoint for Eagle Clinical Reports
 */
export const useClinicalreportEaglePartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.EagleReport,
            ClinicalreportEaglePartialUpdateError,
            ClinicalreportEaglePartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.EagleReport,
        ClinicalreportEaglePartialUpdateError,
        ClinicalreportEaglePartialUpdateVariables
    >({
        mutationFn: (variables: ClinicalreportEaglePartialUpdateVariables) =>
            fetchClinicalreportEaglePartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ClinicalreportGgpatientListError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientListResponse = Schemas.GGPatient[];

export type ClinicalreportGgpatientListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientList = (
    variables: ClinicalreportGgpatientListVariables,
    signal?: AbortSignal,
) =>
    aceFetch<ClinicalreportGgpatientListResponse, ClinicalreportGgpatientListError, undefined, {}, {}, {}>({
        url: '/clinicalreport/ggpatient/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientList = <TData = ClinicalreportGgpatientListResponse>(
    variables: ClinicalreportGgpatientListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ClinicalreportGgpatientListResponse, ClinicalreportGgpatientListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ClinicalreportGgpatientListResponse, ClinicalreportGgpatientListError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/',
            operationId: 'clinicalreportGgpatientList',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportGgpatientList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportGgpatientFromProcedureIdQueryParams = {
    /**
     * Procedure ID
     */
    procedure_id?: string;
};

export type ClinicalreportGgpatientFromProcedureIdError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientFromProcedureIdVariables = {
    queryParams?: ClinicalreportGgpatientFromProcedureIdQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientFromProcedureId = (
    variables: ClinicalreportGgpatientFromProcedureIdVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.GGPatient,
        ClinicalreportGgpatientFromProcedureIdError,
        undefined,
        {},
        ClinicalreportGgpatientFromProcedureIdQueryParams,
        {}
    >({ url: '/clinicalreport/ggpatient/from_procedure_id/', method: 'get', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientFromProcedureId = <TData = Schemas.GGPatient>(
    variables: ClinicalreportGgpatientFromProcedureIdVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GGPatient, ClinicalreportGgpatientFromProcedureIdError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GGPatient, ClinicalreportGgpatientFromProcedureIdError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/from_procedure_id/',
            operationId: 'clinicalreportGgpatientFromProcedureId',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportGgpatientFromProcedureId({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportGgpatientIgnoredIssuesError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientIgnoredIssuesVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientIgnoredIssues = (
    variables: ClinicalreportGgpatientIgnoredIssuesVariables,
    signal?: AbortSignal,
) =>
    aceFetch<Schemas.IgnoreIssue, ClinicalreportGgpatientIgnoredIssuesError, undefined, {}, {}, {}>({
        url: '/clinicalreport/ggpatient/ignored_issues/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientIgnoredIssues = <TData = Schemas.IgnoreIssue>(
    variables: ClinicalreportGgpatientIgnoredIssuesVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.IgnoreIssue, ClinicalreportGgpatientIgnoredIssuesError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.IgnoreIssue, ClinicalreportGgpatientIgnoredIssuesError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/ignored_issues/',
            operationId: 'clinicalreportGgpatientIgnoredIssues',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportGgpatientIgnoredIssues({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportGgpatientRelatedCommentsError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientRelatedCommentsResponse = Schemas.ReportComment[];

export type ClinicalreportGgpatientRelatedCommentsVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientRelatedComments = (
    variables: ClinicalreportGgpatientRelatedCommentsVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ClinicalreportGgpatientRelatedCommentsResponse,
        ClinicalreportGgpatientRelatedCommentsError,
        undefined,
        {},
        {},
        {}
    >({ url: '/clinicalreport/ggpatient/related_comments/', method: 'get', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientRelatedComments = <TData = ClinicalreportGgpatientRelatedCommentsResponse>(
    variables: ClinicalreportGgpatientRelatedCommentsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ClinicalreportGgpatientRelatedCommentsResponse,
            ClinicalreportGgpatientRelatedCommentsError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ClinicalreportGgpatientRelatedCommentsResponse,
        ClinicalreportGgpatientRelatedCommentsError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/related_comments/',
            operationId: 'clinicalreportGgpatientRelatedComments',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportGgpatientRelatedComments({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportGgpatientRelatedProceduresError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientRelatedProceduresResponse = Schemas.ProcedureDate[];

export type ClinicalreportGgpatientRelatedProceduresVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientRelatedProcedures = (
    variables: ClinicalreportGgpatientRelatedProceduresVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ClinicalreportGgpatientRelatedProceduresResponse,
        ClinicalreportGgpatientRelatedProceduresError,
        undefined,
        {},
        {},
        {}
    >({ url: '/clinicalreport/ggpatient/related_procedures/', method: 'get', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientRelatedProcedures = <TData = ClinicalreportGgpatientRelatedProceduresResponse>(
    variables: ClinicalreportGgpatientRelatedProceduresVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ClinicalreportGgpatientRelatedProceduresResponse,
            ClinicalreportGgpatientRelatedProceduresError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ClinicalreportGgpatientRelatedProceduresResponse,
        ClinicalreportGgpatientRelatedProceduresError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/related_procedures/',
            operationId: 'clinicalreportGgpatientRelatedProcedures',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportGgpatientRelatedProcedures({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportGgpatientReadPathParams = {
    /**
     * A unique integer value identifying this Greenlight Guru Patients.
     */
    id: number;
};

export type ClinicalreportGgpatientReadError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientReadVariables = {
    pathParams: ClinicalreportGgpatientReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientRead = (
    variables: ClinicalreportGgpatientReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.GGPatient,
        ClinicalreportGgpatientReadError,
        undefined,
        {},
        {},
        ClinicalreportGgpatientReadPathParams
    >({ url: '/clinicalreport/ggpatient/{id}/', method: 'get', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientRead = <TData = Schemas.GGPatient>(
    variables: ClinicalreportGgpatientReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GGPatient, ClinicalreportGgpatientReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GGPatient, ClinicalreportGgpatientReadError, TData>({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/{id}/',
            operationId: 'clinicalreportGgpatientRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchClinicalreportGgpatientRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ClinicalreportGgpatientUpdatePathParams = {
    /**
     * A unique integer value identifying this Greenlight Guru Patients.
     */
    id: number;
};

export type ClinicalreportGgpatientUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientUpdateVariables = {
    body?: RequestBodies.GGPatient;
    pathParams: ClinicalreportGgpatientUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientUpdate = (
    variables: ClinicalreportGgpatientUpdateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.GGPatient,
        ClinicalreportGgpatientUpdateError,
        RequestBodies.GGPatient,
        {},
        {},
        ClinicalreportGgpatientUpdatePathParams
    >({ url: '/clinicalreport/ggpatient/{id}/', method: 'put', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.GGPatient,
            ClinicalreportGgpatientUpdateError,
            ClinicalreportGgpatientUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.GGPatient,
        ClinicalreportGgpatientUpdateError,
        ClinicalreportGgpatientUpdateVariables
    >({
        mutationFn: (variables: ClinicalreportGgpatientUpdateVariables) =>
            fetchClinicalreportGgpatientUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ClinicalreportGgpatientPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this Greenlight Guru Patients.
     */
    id: number;
};

export type ClinicalreportGgpatientPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientPartialUpdateVariables = {
    body?: RequestBodies.GGPatient;
    pathParams: ClinicalreportGgpatientPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientPartialUpdate = (
    variables: ClinicalreportGgpatientPartialUpdateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.GGPatient,
        ClinicalreportGgpatientPartialUpdateError,
        RequestBodies.GGPatient,
        {},
        {},
        ClinicalreportGgpatientPartialUpdatePathParams
    >({ url: '/clinicalreport/ggpatient/{id}/', method: 'patch', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.GGPatient,
            ClinicalreportGgpatientPartialUpdateError,
            ClinicalreportGgpatientPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.GGPatient,
        ClinicalreportGgpatientPartialUpdateError,
        ClinicalreportGgpatientPartialUpdateVariables
    >({
        mutationFn: (variables: ClinicalreportGgpatientPartialUpdateVariables) =>
            fetchClinicalreportGgpatientPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ClinicalreportGgpatientPolypDiagnosisPathParams = {
    /**
     * A unique integer value identifying this Greenlight Guru Patients.
     */
    id: number;
};

export type ClinicalreportGgpatientPolypDiagnosisError = Fetcher.ErrorWrapper<undefined>;

export type ClinicalreportGgpatientPolypDiagnosisResponse = {
    cols?: void;
    rows?: void;
};

export type ClinicalreportGgpatientPolypDiagnosisVariables = {
    pathParams: ClinicalreportGgpatientPolypDiagnosisPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for GreenLight Guru Patients
 */
export const fetchClinicalreportGgpatientPolypDiagnosis = (
    variables: ClinicalreportGgpatientPolypDiagnosisVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ClinicalreportGgpatientPolypDiagnosisResponse,
        ClinicalreportGgpatientPolypDiagnosisError,
        undefined,
        {},
        {},
        ClinicalreportGgpatientPolypDiagnosisPathParams
    >({ url: '/clinicalreport/ggpatient/{id}/polyp_diagnosis/', method: 'get', ...variables, signal });

/**
 * Endpoint for GreenLight Guru Patients
 */
export const useClinicalreportGgpatientPolypDiagnosis = <TData = ClinicalreportGgpatientPolypDiagnosisResponse>(
    variables: ClinicalreportGgpatientPolypDiagnosisVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ClinicalreportGgpatientPolypDiagnosisResponse,
            ClinicalreportGgpatientPolypDiagnosisError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ClinicalreportGgpatientPolypDiagnosisResponse,
        ClinicalreportGgpatientPolypDiagnosisError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/clinicalreport/ggpatient/{id}/polyp_diagnosis/',
            operationId: 'clinicalreportGgpatientPolypDiagnosis',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchClinicalreportGgpatientPolypDiagnosis({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type CommentsListError = Fetcher.ErrorWrapper<undefined>;

export type CommentsListResponse = Schemas.ReportComment[];

export type CommentsListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const fetchCommentsList = (variables: CommentsListVariables, signal?: AbortSignal) =>
    aceFetch<CommentsListResponse, CommentsListError, undefined, {}, {}, {}>({
        url: '/comments/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const useCommentsList = <TData = CommentsListResponse>(
    variables: CommentsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<CommentsListResponse, CommentsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<CommentsListResponse, CommentsListError, TData>({
        queryKey: queryKeyFn({ path: '/comments/', operationId: 'commentsList', variables }),
        queryFn: ({ signal }) => fetchCommentsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type CommentsCreateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsCreateVariables = {
    body: RequestBodies.ReportComment;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const fetchCommentsCreate = (variables: CommentsCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ReportComment, CommentsCreateError, RequestBodies.ReportComment, {}, {}, {}>({
        url: '/comments/',
        method: 'post',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const useCommentsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.ReportComment, CommentsCreateError, CommentsCreateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ReportComment, CommentsCreateError, CommentsCreateVariables>({
        mutationFn: (variables: CommentsCreateVariables) => fetchCommentsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type CommentsReadPathParams = {
    /**
     * A unique integer value identifying this report comment.
     */
    id: number;
};

export type CommentsReadError = Fetcher.ErrorWrapper<undefined>;

export type CommentsReadVariables = {
    pathParams: CommentsReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const fetchCommentsRead = (variables: CommentsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ReportComment, CommentsReadError, undefined, {}, {}, CommentsReadPathParams>({
        url: '/comments/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const useCommentsRead = <TData = Schemas.ReportComment>(
    variables: CommentsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ReportComment, CommentsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ReportComment, CommentsReadError, TData>({
        queryKey: queryKeyFn({ path: '/comments/{id}/', operationId: 'commentsRead', variables }),
        queryFn: ({ signal }) => fetchCommentsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type CommentsUpdatePathParams = {
    /**
     * A unique integer value identifying this report comment.
     */
    id: number;
};

export type CommentsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsUpdateVariables = {
    body: RequestBodies.ReportComment;
    pathParams: CommentsUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const fetchCommentsUpdate = (variables: CommentsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ReportComment, CommentsUpdateError, RequestBodies.ReportComment, {}, {}, CommentsUpdatePathParams>(
        { url: '/comments/{id}/', method: 'put', ...variables, signal },
    );

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const useCommentsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.ReportComment, CommentsUpdateError, CommentsUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ReportComment, CommentsUpdateError, CommentsUpdateVariables>({
        mutationFn: (variables: CommentsUpdateVariables) => fetchCommentsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type CommentsPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this report comment.
     */
    id: number;
};

export type CommentsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type CommentsPartialUpdateVariables = {
    body: RequestBodies.ReportComment;
    pathParams: CommentsPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const fetchCommentsPartialUpdate = (variables: CommentsPartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.ReportComment,
        CommentsPartialUpdateError,
        RequestBodies.ReportComment,
        {},
        {},
        CommentsPartialUpdatePathParams
    >({ url: '/comments/{id}/', method: 'patch', ...variables, signal });

/**
 * Endpoint for managing comments displayed in the comment section under every procedure,
 * they support nesting.
 */
export const useCommentsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ReportComment,
            CommentsPartialUpdateError,
            CommentsPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ReportComment, CommentsPartialUpdateError, CommentsPartialUpdateVariables>({
        mutationFn: (variables: CommentsPartialUpdateVariables) =>
            fetchCommentsPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type CommentsDeletePathParams = {
    /**
     * A unique integer value identifying this report comment.
     */
    id: number;
};

export type CommentsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type CommentsDeleteVariables = {
    pathParams: CommentsDeletePathParams;
} & AceContext['fetcherOptions'];

/**
 * Only admins can irreversibly delete comments.
 */
export const fetchCommentsDelete = (variables: CommentsDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, CommentsDeleteError, undefined, {}, {}, CommentsDeletePathParams>({
        url: '/comments/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

/**
 * Only admins can irreversibly delete comments.
 */
export const useCommentsDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, CommentsDeleteError, CommentsDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, CommentsDeleteError, CommentsDeleteVariables>({
        mutationFn: (variables: CommentsDeleteVariables) => fetchCommentsDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardComponentsListError = Fetcher.ErrorWrapper<undefined>;

export type DashboardComponentsListResponse = Schemas.AceDashBoardComponent[];

export type DashboardComponentsListVariables = AceContext['fetcherOptions'];

export const fetchDashboardComponentsList = (variables: DashboardComponentsListVariables, signal?: AbortSignal) =>
    aceFetch<DashboardComponentsListResponse, DashboardComponentsListError, undefined, {}, {}, {}>({
        url: '/dashboard_components/',
        method: 'get',
        ...variables,
        signal,
    });

export const useDashboardComponentsList = <TData = DashboardComponentsListResponse>(
    variables: DashboardComponentsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<DashboardComponentsListResponse, DashboardComponentsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<DashboardComponentsListResponse, DashboardComponentsListError, TData>({
        queryKey: queryKeyFn({ path: '/dashboard_components/', operationId: 'dashboardComponentsList', variables }),
        queryFn: ({ signal }) => fetchDashboardComponentsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DashboardComponentsCreateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardComponentsCreateVariables = {
    body: RequestBodies.AceDashBoardComponent;
} & AceContext['fetcherOptions'];

export const fetchDashboardComponentsCreate = (variables: DashboardComponentsCreateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.AceDashBoardComponent,
        DashboardComponentsCreateError,
        RequestBodies.AceDashBoardComponent,
        {},
        {},
        {}
    >({ url: '/dashboard_components/', method: 'post', ...variables, signal });

export const useDashboardComponentsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.AceDashBoardComponent,
            DashboardComponentsCreateError,
            DashboardComponentsCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.AceDashBoardComponent,
        DashboardComponentsCreateError,
        DashboardComponentsCreateVariables
    >({
        mutationFn: (variables: DashboardComponentsCreateVariables) =>
            fetchDashboardComponentsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardComponentsReadPathParams = {
    /**
     * A unique integer value identifying this ace dashboard component.
     */
    id: number;
};

export type DashboardComponentsReadError = Fetcher.ErrorWrapper<undefined>;

export type DashboardComponentsReadVariables = {
    pathParams: DashboardComponentsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardComponentsRead = (variables: DashboardComponentsReadVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.AceDashBoardComponent,
        DashboardComponentsReadError,
        undefined,
        {},
        {},
        DashboardComponentsReadPathParams
    >({ url: '/dashboard_components/{id}/', method: 'get', ...variables, signal });

export const useDashboardComponentsRead = <TData = Schemas.AceDashBoardComponent>(
    variables: DashboardComponentsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AceDashBoardComponent, DashboardComponentsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AceDashBoardComponent, DashboardComponentsReadError, TData>({
        queryKey: queryKeyFn({
            path: '/dashboard_components/{id}/',
            operationId: 'dashboardComponentsRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchDashboardComponentsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DashboardComponentsUpdatePathParams = {
    /**
     * A unique integer value identifying this ace dashboard component.
     */
    id: number;
};

export type DashboardComponentsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardComponentsUpdateVariables = {
    body: RequestBodies.AceDashBoardComponent;
    pathParams: DashboardComponentsUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardComponentsUpdate = (variables: DashboardComponentsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.AceDashBoardComponent,
        DashboardComponentsUpdateError,
        RequestBodies.AceDashBoardComponent,
        {},
        {},
        DashboardComponentsUpdatePathParams
    >({ url: '/dashboard_components/{id}/', method: 'put', ...variables, signal });

export const useDashboardComponentsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.AceDashBoardComponent,
            DashboardComponentsUpdateError,
            DashboardComponentsUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.AceDashBoardComponent,
        DashboardComponentsUpdateError,
        DashboardComponentsUpdateVariables
    >({
        mutationFn: (variables: DashboardComponentsUpdateVariables) =>
            fetchDashboardComponentsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardComponentsPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this ace dashboard component.
     */
    id: number;
};

export type DashboardComponentsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardComponentsPartialUpdateVariables = {
    body: RequestBodies.AceDashBoardComponent;
    pathParams: DashboardComponentsPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardComponentsPartialUpdate = (
    variables: DashboardComponentsPartialUpdateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.AceDashBoardComponent,
        DashboardComponentsPartialUpdateError,
        RequestBodies.AceDashBoardComponent,
        {},
        {},
        DashboardComponentsPartialUpdatePathParams
    >({ url: '/dashboard_components/{id}/', method: 'patch', ...variables, signal });

export const useDashboardComponentsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.AceDashBoardComponent,
            DashboardComponentsPartialUpdateError,
            DashboardComponentsPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.AceDashBoardComponent,
        DashboardComponentsPartialUpdateError,
        DashboardComponentsPartialUpdateVariables
    >({
        mutationFn: (variables: DashboardComponentsPartialUpdateVariables) =>
            fetchDashboardComponentsPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardComponentsDeletePathParams = {
    /**
     * A unique integer value identifying this ace dashboard component.
     */
    id: number;
};

export type DashboardComponentsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type DashboardComponentsDeleteVariables = {
    pathParams: DashboardComponentsDeletePathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardComponentsDelete = (variables: DashboardComponentsDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, DashboardComponentsDeleteError, undefined, {}, {}, DashboardComponentsDeletePathParams>({
        url: '/dashboard_components/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

export const useDashboardComponentsDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, DashboardComponentsDeleteError, DashboardComponentsDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, DashboardComponentsDeleteError, DashboardComponentsDeleteVariables>({
        mutationFn: (variables: DashboardComponentsDeleteVariables) =>
            fetchDashboardComponentsDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardsListError = Fetcher.ErrorWrapper<undefined>;

export type DashboardsListResponse = Schemas.AceDashBoard[];

export type DashboardsListVariables = AceContext['fetcherOptions'];

export const fetchDashboardsList = (variables: DashboardsListVariables, signal?: AbortSignal) =>
    aceFetch<DashboardsListResponse, DashboardsListError, undefined, {}, {}, {}>({
        url: '/dashboards/',
        method: 'get',
        ...variables,
        signal,
    });

export const useDashboardsList = <TData = DashboardsListResponse>(
    variables: DashboardsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<DashboardsListResponse, DashboardsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<DashboardsListResponse, DashboardsListError, TData>({
        queryKey: queryKeyFn({ path: '/dashboards/', operationId: 'dashboardsList', variables }),
        queryFn: ({ signal }) => fetchDashboardsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DashboardsCreateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardsCreateVariables = {
    body: RequestBodies.AceDashBoard;
} & AceContext['fetcherOptions'];

export const fetchDashboardsCreate = (variables: DashboardsCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AceDashBoard, DashboardsCreateError, RequestBodies.AceDashBoard, {}, {}, {}>({
        url: '/dashboards/',
        method: 'post',
        ...variables,
        signal,
    });

export const useDashboardsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.AceDashBoard, DashboardsCreateError, DashboardsCreateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.AceDashBoard, DashboardsCreateError, DashboardsCreateVariables>({
        mutationFn: (variables: DashboardsCreateVariables) =>
            fetchDashboardsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardsReadPathParams = {
    /**
     * A unique integer value identifying this ace dashboard.
     */
    id: number;
};

export type DashboardsReadError = Fetcher.ErrorWrapper<undefined>;

export type DashboardsReadVariables = {
    pathParams: DashboardsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardsRead = (variables: DashboardsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AceDashBoard, DashboardsReadError, undefined, {}, {}, DashboardsReadPathParams>({
        url: '/dashboards/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

export const useDashboardsRead = <TData = Schemas.AceDashBoard>(
    variables: DashboardsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AceDashBoard, DashboardsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AceDashBoard, DashboardsReadError, TData>({
        queryKey: queryKeyFn({ path: '/dashboards/{id}/', operationId: 'dashboardsRead', variables }),
        queryFn: ({ signal }) => fetchDashboardsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DashboardsUpdatePathParams = {
    /**
     * A unique integer value identifying this ace dashboard.
     */
    id: number;
};

export type DashboardsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardsUpdateVariables = {
    body: RequestBodies.AceDashBoard;
    pathParams: DashboardsUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardsUpdate = (variables: DashboardsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.AceDashBoard,
        DashboardsUpdateError,
        RequestBodies.AceDashBoard,
        {},
        {},
        DashboardsUpdatePathParams
    >({ url: '/dashboards/{id}/', method: 'put', ...variables, signal });

export const useDashboardsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.AceDashBoard, DashboardsUpdateError, DashboardsUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.AceDashBoard, DashboardsUpdateError, DashboardsUpdateVariables>({
        mutationFn: (variables: DashboardsUpdateVariables) =>
            fetchDashboardsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DashboardsPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this ace dashboard.
     */
    id: number;
};

export type DashboardsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DashboardsPartialUpdateVariables = {
    body: RequestBodies.AceDashBoard;
    pathParams: DashboardsPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardsPartialUpdate = (variables: DashboardsPartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.AceDashBoard,
        DashboardsPartialUpdateError,
        RequestBodies.AceDashBoard,
        {},
        {},
        DashboardsPartialUpdatePathParams
    >({ url: '/dashboards/{id}/', method: 'patch', ...variables, signal });

export const useDashboardsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.AceDashBoard,
            DashboardsPartialUpdateError,
            DashboardsPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.AceDashBoard, DashboardsPartialUpdateError, DashboardsPartialUpdateVariables>(
        {
            mutationFn: (variables: DashboardsPartialUpdateVariables) =>
                fetchDashboardsPartialUpdate({ ...fetcherOptions, ...variables }),
            ...options,
        },
    );
};

export type DashboardsDeletePathParams = {
    /**
     * A unique integer value identifying this ace dashboard.
     */
    id: number;
};

export type DashboardsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type DashboardsDeleteVariables = {
    pathParams: DashboardsDeletePathParams;
} & AceContext['fetcherOptions'];

export const fetchDashboardsDelete = (variables: DashboardsDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, DashboardsDeleteError, undefined, {}, {}, DashboardsDeletePathParams>({
        url: '/dashboards/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

export const useDashboardsDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, DashboardsDeleteError, DashboardsDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, DashboardsDeleteError, DashboardsDeleteVariables>({
        mutationFn: (variables: DashboardsDeleteVariables) =>
            fetchDashboardsDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DebugGraphReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type DebugGraphReadQueryParams = {
    /**
     * Name of attribute key of the desired plotting data
     */
    plot?: string;
};

export type DebugGraphReadError = Fetcher.ErrorWrapper<undefined>;

export type DebugGraphReadVariables = {
    pathParams: DebugGraphReadPathParams;
    queryParams?: DebugGraphReadQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Debug endpoint only available for admins, it is used to retrieve all data points of a caddie attribute
 * plot (e.g. network_level) without any subsampling. Warning: there are no limits to the number of data points,
 * proceed with caution.
 */
export const fetchDebugGraphRead = (variables: DebugGraphReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.GraphRaw, DebugGraphReadError, undefined, {}, DebugGraphReadQueryParams, DebugGraphReadPathParams>(
        { url: '/debug/graph/{procedureId}/', method: 'get', ...variables, signal },
    );

/**
 * Debug endpoint only available for admins, it is used to retrieve all data points of a caddie attribute
 * plot (e.g. network_level) without any subsampling. Warning: there are no limits to the number of data points,
 * proceed with caution.
 */
export const useDebugGraphRead = <TData = Schemas.GraphRaw>(
    variables: DebugGraphReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GraphRaw, DebugGraphReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GraphRaw, DebugGraphReadError, TData>({
        queryKey: queryKeyFn({ path: '/debug/graph/{procedureId}/', operationId: 'debugGraphRead', variables }),
        queryFn: ({ signal }) => fetchDebugGraphRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DoctorsListError = Fetcher.ErrorWrapper<undefined>;

export type DoctorsListResponse = Schemas.Doctor[];

export type DoctorsListVariables = AceContext['fetcherOptions'];

export const fetchDoctorsList = (variables: DoctorsListVariables, signal?: AbortSignal) =>
    aceFetch<DoctorsListResponse, DoctorsListError, undefined, {}, {}, {}>({
        url: '/doctors/',
        method: 'get',
        ...variables,
        signal,
    });

export const useDoctorsList = <TData = DoctorsListResponse>(
    variables: DoctorsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<DoctorsListResponse, DoctorsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<DoctorsListResponse, DoctorsListError, TData>({
        queryKey: queryKeyFn({ path: '/doctors/', operationId: 'doctorsList', variables }),
        queryFn: ({ signal }) => fetchDoctorsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DoctorsCreateError = Fetcher.ErrorWrapper<undefined>;

export type DoctorsCreateVariables = {
    body: RequestBodies.Doctor;
} & AceContext['fetcherOptions'];

export const fetchDoctorsCreate = (variables: DoctorsCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Doctor, DoctorsCreateError, RequestBodies.Doctor, {}, {}, {}>({
        url: '/doctors/',
        method: 'post',
        ...variables,
        signal,
    });

export const useDoctorsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.Doctor, DoctorsCreateError, DoctorsCreateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.Doctor, DoctorsCreateError, DoctorsCreateVariables>({
        mutationFn: (variables: DoctorsCreateVariables) => fetchDoctorsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DoctorsReadPathParams = {
    /**
     * A unique integer value identifying this doctor.
     */
    id: number;
};

export type DoctorsReadError = Fetcher.ErrorWrapper<undefined>;

export type DoctorsReadVariables = {
    pathParams: DoctorsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchDoctorsRead = (variables: DoctorsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Doctor, DoctorsReadError, undefined, {}, {}, DoctorsReadPathParams>({
        url: '/doctors/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

export const useDoctorsRead = <TData = Schemas.Doctor>(
    variables: DoctorsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Doctor, DoctorsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Doctor, DoctorsReadError, TData>({
        queryKey: queryKeyFn({ path: '/doctors/{id}/', operationId: 'doctorsRead', variables }),
        queryFn: ({ signal }) => fetchDoctorsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DoctorsUpdatePathParams = {
    /**
     * A unique integer value identifying this doctor.
     */
    id: number;
};

export type DoctorsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DoctorsUpdateVariables = {
    body: RequestBodies.Doctor;
    pathParams: DoctorsUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchDoctorsUpdate = (variables: DoctorsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Doctor, DoctorsUpdateError, RequestBodies.Doctor, {}, {}, DoctorsUpdatePathParams>({
        url: '/doctors/{id}/',
        method: 'put',
        ...variables,
        signal,
    });

export const useDoctorsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.Doctor, DoctorsUpdateError, DoctorsUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.Doctor, DoctorsUpdateError, DoctorsUpdateVariables>({
        mutationFn: (variables: DoctorsUpdateVariables) => fetchDoctorsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DoctorsPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this doctor.
     */
    id: number;
};

export type DoctorsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type DoctorsPartialUpdateVariables = {
    body: RequestBodies.Doctor;
    pathParams: DoctorsPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchDoctorsPartialUpdate = (variables: DoctorsPartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Doctor, DoctorsPartialUpdateError, RequestBodies.Doctor, {}, {}, DoctorsPartialUpdatePathParams>({
        url: '/doctors/{id}/',
        method: 'patch',
        ...variables,
        signal,
    });

export const useDoctorsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.Doctor, DoctorsPartialUpdateError, DoctorsPartialUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.Doctor, DoctorsPartialUpdateError, DoctorsPartialUpdateVariables>({
        mutationFn: (variables: DoctorsPartialUpdateVariables) =>
            fetchDoctorsPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type DownloadAnnotationReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type DownloadAnnotationReadError = Fetcher.ErrorWrapper<undefined>;

export type DownloadAnnotationReadVariables = {
    pathParams: DownloadAnnotationReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint to download the XML annotations of a specific procedure from the "recordings" bucket
 */
export const fetchDownloadAnnotationRead = (variables: DownloadAnnotationReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.FileDownload, DownloadAnnotationReadError, undefined, {}, {}, DownloadAnnotationReadPathParams>({
        url: '/download/annotation/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint to download the XML annotations of a specific procedure from the "recordings" bucket
 */
export const useDownloadAnnotationRead = <TData = Schemas.FileDownload>(
    variables: DownloadAnnotationReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.FileDownload, DownloadAnnotationReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.FileDownload, DownloadAnnotationReadError, TData>({
        queryKey: queryKeyFn({
            path: '/download/annotation/{procedureId}/',
            operationId: 'downloadAnnotationRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchDownloadAnnotationRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DownloadMetadataReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type DownloadMetadataReadError = Fetcher.ErrorWrapper<undefined>;

export type DownloadMetadataReadVariables = {
    pathParams: DownloadMetadataReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint to download the video metadata of a specific procedure from the "recordings" bucket,
 * this file should contain information such as FPS, aspect ratios, detection area, etc...
 */
export const fetchDownloadMetadataRead = (variables: DownloadMetadataReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.FileDownload, DownloadMetadataReadError, undefined, {}, {}, DownloadMetadataReadPathParams>({
        url: '/download/metadata/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint to download the video metadata of a specific procedure from the "recordings" bucket,
 * this file should contain information such as FPS, aspect ratios, detection area, etc...
 */
export const useDownloadMetadataRead = <TData = Schemas.FileDownload>(
    variables: DownloadMetadataReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.FileDownload, DownloadMetadataReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.FileDownload, DownloadMetadataReadError, TData>({
        queryKey: queryKeyFn({
            path: '/download/metadata/{procedureId}/',
            operationId: 'downloadMetadataRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchDownloadMetadataRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type DownloadReadPathParams = {
    procedureId: string;
};

export type DownloadReadError = Fetcher.ErrorWrapper<undefined>;

export type DownloadReadVariables = {
    pathParams: DownloadReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint to download a zip archive containing full frames and a csv file containing corresponding diagnosis.
 */
export const fetchDownloadRead = (variables: DownloadReadVariables, signal?: AbortSignal) =>
    aceFetch<undefined, DownloadReadError, undefined, {}, {}, DownloadReadPathParams>({
        url: '/download/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint to download a zip archive containing full frames and a csv file containing corresponding diagnosis.
 */
export const useDownloadRead = <TData = undefined>(
    variables: DownloadReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, DownloadReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, DownloadReadError, TData>({
        queryKey: queryKeyFn({ path: '/download/{procedureId}/', operationId: 'downloadRead', variables }),
        queryFn: ({ signal }) => fetchDownloadRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type EditsTimelineListError = Fetcher.ErrorWrapper<undefined>;

export type EditsTimelineListResponse = Schemas.TimelineEdit[];

export type EditsTimelineListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const fetchEditsTimelineList = (variables: EditsTimelineListVariables, signal?: AbortSignal) =>
    aceFetch<EditsTimelineListResponse, EditsTimelineListError, undefined, {}, {}, {}>({
        url: '/edits/timeline/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const useEditsTimelineList = <TData = EditsTimelineListResponse>(
    variables: EditsTimelineListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<EditsTimelineListResponse, EditsTimelineListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<EditsTimelineListResponse, EditsTimelineListError, TData>({
        queryKey: queryKeyFn({ path: '/edits/timeline/', operationId: 'editsTimelineList', variables }),
        queryFn: ({ signal }) => fetchEditsTimelineList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type EditsTimelineCreateError = Fetcher.ErrorWrapper<undefined>;

export type EditsTimelineCreateVariables = {
    body: RequestBodies.TimelineEdit;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const fetchEditsTimelineCreate = (variables: EditsTimelineCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.TimelineEdit, EditsTimelineCreateError, RequestBodies.TimelineEdit, {}, {}, {}>({
        url: '/edits/timeline/',
        method: 'post',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const useEditsTimelineCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.TimelineEdit, EditsTimelineCreateError, EditsTimelineCreateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.TimelineEdit, EditsTimelineCreateError, EditsTimelineCreateVariables>({
        mutationFn: (variables: EditsTimelineCreateVariables) =>
            fetchEditsTimelineCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type EditsTimelineReadPathParams = {
    /**
     * A unique integer value identifying this timeline edit.
     */
    id: number;
};

export type EditsTimelineReadError = Fetcher.ErrorWrapper<undefined>;

export type EditsTimelineReadVariables = {
    pathParams: EditsTimelineReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const fetchEditsTimelineRead = (variables: EditsTimelineReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.TimelineEdit, EditsTimelineReadError, undefined, {}, {}, EditsTimelineReadPathParams>({
        url: '/edits/timeline/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const useEditsTimelineRead = <TData = Schemas.TimelineEdit>(
    variables: EditsTimelineReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.TimelineEdit, EditsTimelineReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.TimelineEdit, EditsTimelineReadError, TData>({
        queryKey: queryKeyFn({ path: '/edits/timeline/{id}/', operationId: 'editsTimelineRead', variables }),
        queryFn: ({ signal }) => fetchEditsTimelineRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type EditsTimelineUpdatePathParams = {
    /**
     * A unique integer value identifying this timeline edit.
     */
    id: number;
};

export type EditsTimelineUpdateError = Fetcher.ErrorWrapper<undefined>;

export type EditsTimelineUpdateVariables = {
    body: RequestBodies.TimelineEdit;
    pathParams: EditsTimelineUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const fetchEditsTimelineUpdate = (variables: EditsTimelineUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.TimelineEdit,
        EditsTimelineUpdateError,
        RequestBodies.TimelineEdit,
        {},
        {},
        EditsTimelineUpdatePathParams
    >({ url: '/edits/timeline/{id}/', method: 'put', ...variables, signal });

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const useEditsTimelineUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.TimelineEdit, EditsTimelineUpdateError, EditsTimelineUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.TimelineEdit, EditsTimelineUpdateError, EditsTimelineUpdateVariables>({
        mutationFn: (variables: EditsTimelineUpdateVariables) =>
            fetchEditsTimelineUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type EditsTimelinePartialUpdatePathParams = {
    /**
     * A unique integer value identifying this timeline edit.
     */
    id: number;
};

export type EditsTimelinePartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type EditsTimelinePartialUpdateVariables = {
    body: RequestBodies.TimelineEdit;
    pathParams: EditsTimelinePartialUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const fetchEditsTimelinePartialUpdate = (variables: EditsTimelinePartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.TimelineEdit,
        EditsTimelinePartialUpdateError,
        RequestBodies.TimelineEdit,
        {},
        {},
        EditsTimelinePartialUpdatePathParams
    >({ url: '/edits/timeline/{id}/', method: 'patch', ...variables, signal });

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const useEditsTimelinePartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.TimelineEdit,
            EditsTimelinePartialUpdateError,
            EditsTimelinePartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.TimelineEdit,
        EditsTimelinePartialUpdateError,
        EditsTimelinePartialUpdateVariables
    >({
        mutationFn: (variables: EditsTimelinePartialUpdateVariables) =>
            fetchEditsTimelinePartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type EditsTimelineDeletePathParams = {
    /**
     * A unique integer value identifying this timeline edit.
     */
    id: number;
};

export type EditsTimelineDeleteError = Fetcher.ErrorWrapper<undefined>;

export type EditsTimelineDeleteVariables = {
    pathParams: EditsTimelineDeletePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const fetchEditsTimelineDelete = (variables: EditsTimelineDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, EditsTimelineDeleteError, undefined, {}, {}, EditsTimelineDeletePathParams>({
        url: '/edits/timeline/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing edits of timeline data from extra models.
 */
export const useEditsTimelineDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, EditsTimelineDeleteError, EditsTimelineDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, EditsTimelineDeleteError, EditsTimelineDeleteVariables>({
        mutationFn: (variables: EditsTimelineDeleteVariables) =>
            fetchEditsTimelineDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type EmptyReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type EmptyReadError = Fetcher.ErrorWrapper<undefined>;

export type EmptyReadVariables = {
    pathParams: EmptyReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Returns true if the procedure does not contain any prediction object otherwise false.
 */
export const fetchEmptyRead = (variables: EmptyReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.EmptyCheck, EmptyReadError, undefined, {}, {}, EmptyReadPathParams>({
        url: '/empty/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns true if the procedure does not contain any prediction object otherwise false.
 */
export const useEmptyRead = <TData = Schemas.EmptyCheck>(
    variables: EmptyReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.EmptyCheck, EmptyReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.EmptyCheck, EmptyReadError, TData>({
        queryKey: queryKeyFn({ path: '/empty/{procedureId}/', operationId: 'emptyRead', variables }),
        queryFn: ({ signal }) => fetchEmptyRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ExtraListError = Fetcher.ErrorWrapper<undefined>;

export type ExtraListResponse = Schemas.ExtraProcedureAnnotation[];

export type ExtraListVariables = AceContext['fetcherOptions'];

/**
 * Procedure-wise extra annotations, quite expensive and so computed once for every completed procedures,
 * and stored in a table, retrievable via this endpoint. It contains data such as cumulated time of
 * network_level=0 or VM<50%, etc...
 * Will not compute missing annotations.
 */
export const fetchExtraList = (variables: ExtraListVariables, signal?: AbortSignal) =>
    aceFetch<ExtraListResponse, ExtraListError, undefined, {}, {}, {}>({
        url: '/extra/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Procedure-wise extra annotations, quite expensive and so computed once for every completed procedures,
 * and stored in a table, retrievable via this endpoint. It contains data such as cumulated time of
 * network_level=0 or VM<50%, etc...
 * Will not compute missing annotations.
 */
export const useExtraList = <TData = ExtraListResponse>(
    variables: ExtraListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ExtraListResponse, ExtraListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ExtraListResponse, ExtraListError, TData>({
        queryKey: queryKeyFn({ path: '/extra/', operationId: 'extraList', variables }),
        queryFn: ({ signal }) => fetchExtraList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ExtraCleanupError = Fetcher.ErrorWrapper<undefined>;

export type ExtraCleanupVariables = {
    body: RequestBodies.ExtraProcedureAnnotation;
} & AceContext['fetcherOptions'];

/**
 * Deletes extra annotations that are not linked to any existing procedure.
 */
export const fetchExtraCleanup = (variables: ExtraCleanupVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ExtraCleanup, ExtraCleanupError, RequestBodies.ExtraProcedureAnnotation, {}, {}, {}>({
        url: '/extra/cleanup/',
        method: 'post',
        ...variables,
        signal,
    });

/**
 * Deletes extra annotations that are not linked to any existing procedure.
 */
export const useExtraCleanup = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.ExtraCleanup, ExtraCleanupError, ExtraCleanupVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ExtraCleanup, ExtraCleanupError, ExtraCleanupVariables>({
        mutationFn: (variables: ExtraCleanupVariables) => fetchExtraCleanup({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ExtraComputeMissingError = Fetcher.ErrorWrapper<undefined>;

export type ExtraComputeMissingVariables = {
    body: RequestBodies.ExtraProcedureAnnotation;
} & AceContext['fetcherOptions'];

/**
 * Procedure-wise extra annotations, quite expensive and so computed once for every completed procedures,
 * and stored in a table, retrievable via this endpoint. It contains data such as cumulated time of
 * network_level=0 or VM<50%, etc...
 * Will compute missing annotations.
 */
export const fetchExtraComputeMissing = (variables: ExtraComputeMissingVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ExtraComputeMissing, ExtraComputeMissingError, RequestBodies.ExtraProcedureAnnotation, {}, {}, {}>(
        { url: '/extra/compute_missing/', method: 'post', ...variables, signal },
    );

/**
 * Procedure-wise extra annotations, quite expensive and so computed once for every completed procedures,
 * and stored in a table, retrievable via this endpoint. It contains data such as cumulated time of
 * network_level=0 or VM<50%, etc...
 * Will compute missing annotations.
 */
export const useExtraComputeMissing = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ExtraComputeMissing,
            ExtraComputeMissingError,
            ExtraComputeMissingVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ExtraComputeMissing, ExtraComputeMissingError, ExtraComputeMissingVariables>({
        mutationFn: (variables: ExtraComputeMissingVariables) =>
            fetchExtraComputeMissing({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ExtraMissingError = Fetcher.ErrorWrapper<undefined>;

export type ExtraMissingVariables = AceContext['fetcherOptions'];

/**
 * Counts the number of procedures that are missing extra annotations.
 */
export const fetchExtraMissing = (variables: ExtraMissingVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ExtraMissingCount, ExtraMissingError, undefined, {}, {}, {}>({
        url: '/extra/missing/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Counts the number of procedures that are missing extra annotations.
 */
export const useExtraMissing = <TData = Schemas.ExtraMissingCount>(
    variables: ExtraMissingVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ExtraMissingCount, ExtraMissingError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ExtraMissingCount, ExtraMissingError, TData>({
        queryKey: queryKeyFn({ path: '/extra/missing/', operationId: 'extraMissing', variables }),
        queryFn: ({ signal }) => fetchExtraMissing({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ExtraTrackListQueryParams = {
    /**
     * Job ID of the celery task computing the missing annotations
     */
    job: string;
};

export type ExtraTrackListError = Fetcher.ErrorWrapper<undefined>;

export type ExtraTrackListVariables = {
    queryParams: ExtraTrackListQueryParams;
} & AceContext['fetcherOptions'];

export const fetchExtraTrackList = (variables: ExtraTrackListVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ExtraTrack, ExtraTrackListError, undefined, {}, ExtraTrackListQueryParams, {}>({
        url: '/extra/track/',
        method: 'get',
        ...variables,
        signal,
    });

export const useExtraTrackList = <TData = Schemas.ExtraTrack>(
    variables: ExtraTrackListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ExtraTrack, ExtraTrackListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ExtraTrack, ExtraTrackListError, TData>({
        queryKey: queryKeyFn({ path: '/extra/track/', operationId: 'extraTrackList', variables }),
        queryFn: ({ signal }) => fetchExtraTrackList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ExtraModelsListError = Fetcher.ErrorWrapper<undefined>;

export type ExtraModelsListResponse = Schemas.TimelineExtraModel[];

export type ExtraModelsListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing extra models to display if available in the timeline.
 */
export const fetchExtraModelsList = (variables: ExtraModelsListVariables, signal?: AbortSignal) =>
    aceFetch<ExtraModelsListResponse, ExtraModelsListError, undefined, {}, {}, {}>({
        url: '/extra_models/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing extra models to display if available in the timeline.
 */
export const useExtraModelsList = <TData = ExtraModelsListResponse>(
    variables: ExtraModelsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ExtraModelsListResponse, ExtraModelsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ExtraModelsListResponse, ExtraModelsListError, TData>({
        queryKey: queryKeyFn({ path: '/extra_models/', operationId: 'extraModelsList', variables }),
        queryFn: ({ signal }) => fetchExtraModelsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ExtraModelsReadPathParams = {
    /**
     * A unique integer value identifying this timeline extra model.
     */
    id: number;
};

export type ExtraModelsReadError = Fetcher.ErrorWrapper<undefined>;

export type ExtraModelsReadVariables = {
    pathParams: ExtraModelsReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing extra models to display if available in the timeline.
 */
export const fetchExtraModelsRead = (variables: ExtraModelsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.TimelineExtraModel, ExtraModelsReadError, undefined, {}, {}, ExtraModelsReadPathParams>({
        url: '/extra_models/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing extra models to display if available in the timeline.
 */
export const useExtraModelsRead = <TData = Schemas.TimelineExtraModel>(
    variables: ExtraModelsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.TimelineExtraModel, ExtraModelsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.TimelineExtraModel, ExtraModelsReadError, TData>({
        queryKey: queryKeyFn({ path: '/extra_models/{id}/', operationId: 'extraModelsRead', variables }),
        queryFn: ({ signal }) => fetchExtraModelsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type FindListQueryParams = {
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
};

export type FindListError = Fetcher.ErrorWrapper<undefined>;

export type FindListResponse = {
    count: number;
    /**
     * @format uri
     */
    next?: string | null;
    /**
     * @format uri
     */
    previous?: string | null;
    results: Schemas.Procedure[];
};

export type FindListVariables = {
    queryParams?: FindListQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Search endpoint that returns a list of procedures matching a specific query set via get
 * parameters such as status=STARTED.
 */
export const fetchFindList = (variables: FindListVariables, signal?: AbortSignal) =>
    aceFetch<FindListResponse, FindListError, undefined, {}, FindListQueryParams, {}>({
        url: '/find/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Search endpoint that returns a list of procedures matching a specific query set via get
 * parameters such as status=STARTED.
 */
export const useFindList = <TData = FindListResponse>(
    variables: FindListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<FindListResponse, FindListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<FindListResponse, FindListError, TData>({
        queryKey: queryKeyFn({ path: '/find/', operationId: 'findList', variables }),
        queryFn: ({ signal }) => fetchFindList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type FindWithCaseStatusQueryParams = {
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string;
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
    /**
     * Case status to filter procedures by
     */
    case_status: string;
};

export type FindWithCaseStatusError = Fetcher.ErrorWrapper<undefined>;

export type FindWithCaseStatusResponse = {
    count?: number;
    next?: {
        offset?: number;
        limit?: number;
    };
    results: Schemas.Procedure[];
};

export type FindWithCaseStatusVariables = {
    queryParams: FindWithCaseStatusQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Retrieve a list of procedures that have a specific status.
 */
export const fetchFindWithCaseStatus = (variables: FindWithCaseStatusVariables, signal?: AbortSignal) =>
    aceFetch<FindWithCaseStatusResponse, FindWithCaseStatusError, undefined, {}, FindWithCaseStatusQueryParams, {}>({
        url: '/find/with_case_status/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Retrieve a list of procedures that have a specific status.
 */
export const useFindWithCaseStatus = <TData = FindWithCaseStatusResponse>(
    variables: FindWithCaseStatusVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<FindWithCaseStatusResponse, FindWithCaseStatusError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<FindWithCaseStatusResponse, FindWithCaseStatusError, TData>({
        queryKey: queryKeyFn({ path: '/find/with_case_status/', operationId: 'findWithCaseStatus', variables }),
        queryFn: ({ signal }) => fetchFindWithCaseStatus({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type FramesReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type FramesReadError = Fetcher.ErrorWrapper<undefined>;

export type FramesReadVariables = {
    pathParams: FramesReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Gives frames (cropped and full) corresponding to all diagnoses taken in one procedure,
 * it uses signed urls to point to the images stored in the recording bucket.
 * These include audit frames without cropped frame (when diagnosis was taken with no detection),
 * in this case, only the full frame will be given.
 */
export const fetchFramesRead = (variables: FramesReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.FrameFetching, FramesReadError, undefined, {}, {}, FramesReadPathParams>({
        url: '/frames/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Gives frames (cropped and full) corresponding to all diagnoses taken in one procedure,
 * it uses signed urls to point to the images stored in the recording bucket.
 * These include audit frames without cropped frame (when diagnosis was taken with no detection),
 * in this case, only the full frame will be given.
 */
export const useFramesRead = <TData = Schemas.FrameFetching>(
    variables: FramesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.FrameFetching, FramesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.FrameFetching, FramesReadError, TData>({
        queryKey: queryKeyFn({ path: '/frames/{procedureId}/', operationId: 'framesRead', variables }),
        queryFn: ({ signal }) => fetchFramesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type FullFramesReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type FullFramesReadError = Fetcher.ErrorWrapper<undefined>;

export type FullFramesReadVariables = {
    pathParams: FullFramesReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for only retrieving audit frames (full frames) in a procedure, also uses signed url pointing to
 * the frame in the recording bucket.
 */
export const fetchFullFramesRead = (variables: FullFramesReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.FullFrameFetching, FullFramesReadError, undefined, {}, {}, FullFramesReadPathParams>({
        url: '/full_frames/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for only retrieving audit frames (full frames) in a procedure, also uses signed url pointing to
 * the frame in the recording bucket.
 */
export const useFullFramesRead = <TData = Schemas.FullFrameFetching>(
    variables: FullFramesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.FullFrameFetching, FullFramesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.FullFrameFetching, FullFramesReadError, TData>({
        queryKey: queryKeyFn({ path: '/full_frames/{procedureId}/', operationId: 'fullFramesRead', variables }),
        queryFn: ({ signal }) => fetchFullFramesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgCheckConnectionReadPathParams = {
    app: string;
};

export type GgCheckConnectionReadError = Fetcher.ErrorWrapper<undefined>;

export type GgCheckConnectionReadVariables = {
    pathParams: GgCheckConnectionReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgCheckConnectionRead = (variables: GgCheckConnectionReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.GGCheckConnection, GgCheckConnectionReadError, undefined, {}, {}, GgCheckConnectionReadPathParams>(
        { url: '/gg/check_connection/{app}', method: 'get', ...variables, signal },
    );

export const useGgCheckConnectionRead = <TData = Schemas.GGCheckConnection>(
    variables: GgCheckConnectionReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GGCheckConnection, GgCheckConnectionReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GGCheckConnection, GgCheckConnectionReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/check_connection/{app}', operationId: 'ggCheckConnectionRead', variables }),
        queryFn: ({ signal }) => fetchGgCheckConnectionRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgClearCacheListError = Fetcher.ErrorWrapper<undefined>;

export type GgClearCacheListVariables = AceContext['fetcherOptions'];

/**
 * Clear all caches so that they are updated on next request
 */
export const fetchGgClearCacheList = (variables: GgClearCacheListVariables, signal?: AbortSignal) =>
    aceFetch<undefined, GgClearCacheListError, undefined, {}, {}, {}>({
        url: '/gg/clear_cache',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Clear all caches so that they are updated on next request
 */
export const useGgClearCacheList = <TData = undefined>(
    variables: GgClearCacheListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, GgClearCacheListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, GgClearCacheListError, TData>({
        queryKey: queryKeyFn({ path: '/gg/clear_cache', operationId: 'ggClearCacheList', variables }),
        queryFn: ({ signal }) => fetchGgClearCacheList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgEnrollmentsReadPathParams = {
    app: string;
};

export type GgEnrollmentsReadError = Fetcher.ErrorWrapper<undefined>;

export type GgEnrollmentsReadResponse = Schemas.GGEnrollment[];

export type GgEnrollmentsReadVariables = {
    pathParams: GgEnrollmentsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgEnrollmentsRead = (variables: GgEnrollmentsReadVariables, signal?: AbortSignal) =>
    aceFetch<GgEnrollmentsReadResponse, GgEnrollmentsReadError, undefined, {}, {}, GgEnrollmentsReadPathParams>({
        url: '/gg/enrollments/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgEnrollmentsRead = <TData = GgEnrollmentsReadResponse>(
    variables: GgEnrollmentsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GgEnrollmentsReadResponse, GgEnrollmentsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GgEnrollmentsReadResponse, GgEnrollmentsReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/enrollments/{app}', operationId: 'ggEnrollmentsRead', variables }),
        queryFn: ({ signal }) => fetchGgEnrollmentsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgExploreListError = Fetcher.ErrorWrapper<undefined>;

export type GgExploreListVariables = AceContext['fetcherOptions'];

export const fetchGgExploreList = (variables: GgExploreListVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.GGExplore, GgExploreListError, undefined, {}, {}, {}>({
        url: '/gg/explore/',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgExploreList = <TData = Schemas.GGExplore>(
    variables: GgExploreListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GGExplore, GgExploreListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GGExplore, GgExploreListError, TData>({
        queryKey: queryKeyFn({ path: '/gg/explore/', operationId: 'ggExploreList', variables }),
        queryFn: ({ signal }) => fetchGgExploreList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgExportPackageReadPathParams = {
    app: string;
};

export type GgExportPackageReadError = Fetcher.ErrorWrapper<undefined>;

export type GgExportPackageReadVariables = {
    pathParams: GgExportPackageReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgExportPackageRead = (variables: GgExportPackageReadVariables, signal?: AbortSignal) =>
    aceFetch<undefined, GgExportPackageReadError, undefined, {}, {}, GgExportPackageReadPathParams>({
        url: '/gg/export_package/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgExportPackageRead = <TData = undefined>(
    variables: GgExportPackageReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, GgExportPackageReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, GgExportPackageReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/export_package/{app}', operationId: 'ggExportPackageRead', variables }),
        queryFn: ({ signal }) => fetchGgExportPackageRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgExportPackageCreatePathParams = {
    app: string;
};

export type GgExportPackageCreateError = Fetcher.ErrorWrapper<undefined>;

export type GgExportPackageCreateResponse = Schemas.GGExportQuery[];

export type GgExportPackageCreateRequestBody = {
    /**
     * @default queries
     */
    exportType?: 'queries' | 'forms';
    /**
     * @default false
     */
    forceRefresh?: boolean;
};

export type GgExportPackageCreateVariables = {
    body?: GgExportPackageCreateRequestBody;
    pathParams: GgExportPackageCreatePathParams;
} & AceContext['fetcherOptions'];

export const fetchGgExportPackageCreate = (variables: GgExportPackageCreateVariables, signal?: AbortSignal) =>
    aceFetch<
        GgExportPackageCreateResponse,
        GgExportPackageCreateError,
        GgExportPackageCreateRequestBody,
        {},
        {},
        GgExportPackageCreatePathParams
    >({ url: '/gg/export_package/{app}', method: 'post', ...variables, signal });

export const useGgExportPackageCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            GgExportPackageCreateResponse,
            GgExportPackageCreateError,
            GgExportPackageCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        GgExportPackageCreateResponse,
        GgExportPackageCreateError,
        GgExportPackageCreateVariables
    >({
        mutationFn: (variables: GgExportPackageCreateVariables) =>
            fetchGgExportPackageCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type GgFormAnswersReadPathParams = {
    app: string;
};

export type GgFormAnswersReadError = Fetcher.ErrorWrapper<undefined>;

export type GgFormAnswersReadResponse = Schemas.GGFormAnswer[];

export type GgFormAnswersReadVariables = {
    pathParams: GgFormAnswersReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgFormAnswersRead = (variables: GgFormAnswersReadVariables, signal?: AbortSignal) =>
    aceFetch<GgFormAnswersReadResponse, GgFormAnswersReadError, undefined, {}, {}, GgFormAnswersReadPathParams>({
        url: '/gg/form_answers/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgFormAnswersRead = <TData = GgFormAnswersReadResponse>(
    variables: GgFormAnswersReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GgFormAnswersReadResponse, GgFormAnswersReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GgFormAnswersReadResponse, GgFormAnswersReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/form_answers/{app}', operationId: 'ggFormAnswersRead', variables }),
        queryFn: ({ signal }) => fetchGgFormAnswersRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgFormsReadPathParams = {
    app: string;
};

export type GgFormsReadError = Fetcher.ErrorWrapper<undefined>;

export type GgFormsReadResponse = Schemas.GGForm[];

export type GgFormsReadVariables = {
    pathParams: GgFormsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgFormsRead = (variables: GgFormsReadVariables, signal?: AbortSignal) =>
    aceFetch<GgFormsReadResponse, GgFormsReadError, undefined, {}, {}, GgFormsReadPathParams>({
        url: '/gg/forms/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgFormsRead = <TData = GgFormsReadResponse>(
    variables: GgFormsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GgFormsReadResponse, GgFormsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GgFormsReadResponse, GgFormsReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/forms/{app}', operationId: 'ggFormsRead', variables }),
        queryFn: ({ signal }) => fetchGgFormsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgOldestCachedDataListError = Fetcher.ErrorWrapper<undefined>;

export type GgOldestCachedDataListVariables = AceContext['fetcherOptions'];

/**
 * Get the oldest cached data
 */
export const fetchGgOldestCachedDataList = (variables: GgOldestCachedDataListVariables, signal?: AbortSignal) =>
    aceFetch<string, GgOldestCachedDataListError, undefined, {}, {}, {}>({
        url: '/gg/oldest_cached_data',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Get the oldest cached data
 */
export const useGgOldestCachedDataList = <TData = string>(
    variables: GgOldestCachedDataListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<string, GgOldestCachedDataListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<string, GgOldestCachedDataListError, TData>({
        queryKey: queryKeyFn({ path: '/gg/oldest_cached_data', operationId: 'ggOldestCachedDataList', variables }),
        queryFn: ({ signal }) => fetchGgOldestCachedDataList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgProcessesReadPathParams = {
    app: string;
};

export type GgProcessesReadError = Fetcher.ErrorWrapper<undefined>;

export type GgProcessesReadResponse = Schemas.GGProcess[];

export type GgProcessesReadVariables = {
    pathParams: GgProcessesReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgProcessesRead = (variables: GgProcessesReadVariables, signal?: AbortSignal) =>
    aceFetch<GgProcessesReadResponse, GgProcessesReadError, undefined, {}, {}, GgProcessesReadPathParams>({
        url: '/gg/processes/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgProcessesRead = <TData = GgProcessesReadResponse>(
    variables: GgProcessesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GgProcessesReadResponse, GgProcessesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GgProcessesReadResponse, GgProcessesReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/processes/{app}', operationId: 'ggProcessesRead', variables }),
        queryFn: ({ signal }) => fetchGgProcessesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgSitesReadPathParams = {
    app: string;
};

export type GgSitesReadError = Fetcher.ErrorWrapper<undefined>;

export type GgSitesReadResponse = Schemas.GGSite[];

export type GgSitesReadVariables = {
    pathParams: GgSitesReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgSitesRead = (variables: GgSitesReadVariables, signal?: AbortSignal) =>
    aceFetch<GgSitesReadResponse, GgSitesReadError, undefined, {}, {}, GgSitesReadPathParams>({
        url: '/gg/sites/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgSitesRead = <TData = GgSitesReadResponse>(
    variables: GgSitesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GgSitesReadResponse, GgSitesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GgSitesReadResponse, GgSitesReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/sites/{app}', operationId: 'ggSitesRead', variables }),
        queryFn: ({ signal }) => fetchGgSitesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgSubjectsReadPathParams = {
    app: string;
};

export type GgSubjectsReadError = Fetcher.ErrorWrapper<undefined>;

export type GgSubjectsReadResponse = Schemas.GGSubject[];

export type GgSubjectsReadVariables = {
    pathParams: GgSubjectsReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchGgSubjectsRead = (variables: GgSubjectsReadVariables, signal?: AbortSignal) =>
    aceFetch<GgSubjectsReadResponse, GgSubjectsReadError, undefined, {}, {}, GgSubjectsReadPathParams>({
        url: '/gg/subjects/{app}',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgSubjectsRead = <TData = GgSubjectsReadResponse>(
    variables: GgSubjectsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GgSubjectsReadResponse, GgSubjectsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GgSubjectsReadResponse, GgSubjectsReadError, TData>({
        queryKey: queryKeyFn({ path: '/gg/subjects/{app}', operationId: 'ggSubjectsRead', variables }),
        queryFn: ({ signal }) => fetchGgSubjectsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgSummaryListError = Fetcher.ErrorWrapper<undefined>;

export type GgSummaryListVariables = AceContext['fetcherOptions'];

export const fetchGgSummaryList = (variables: GgSummaryListVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.GGSummary, GgSummaryListError, undefined, {}, {}, {}>({
        url: '/gg/summary/',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgSummaryList = <TData = Schemas.GGSummary>(
    variables: GgSummaryListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GGSummary, GgSummaryListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GGSummary, GgSummaryListError, TData>({
        queryKey: queryKeyFn({ path: '/gg/summary/', operationId: 'ggSummaryList', variables }),
        queryFn: ({ signal }) => fetchGgSummaryList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgSummaryDiagnosisPolypDataError = Fetcher.ErrorWrapper<undefined>;

export type GgSummaryDiagnosisPolypDataVariables = AceContext['fetcherOptions'];

export const fetchGgSummaryDiagnosisPolypData = (
    variables: GgSummaryDiagnosisPolypDataVariables,
    signal?: AbortSignal,
) =>
    aceFetch<undefined, GgSummaryDiagnosisPolypDataError, undefined, {}, {}, {}>({
        url: '/gg/summary/diagnosis_polyp_data/',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgSummaryDiagnosisPolypData = <TData = undefined>(
    variables: GgSummaryDiagnosisPolypDataVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, GgSummaryDiagnosisPolypDataError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, GgSummaryDiagnosisPolypDataError, TData>({
        queryKey: queryKeyFn({
            path: '/gg/summary/diagnosis_polyp_data/',
            operationId: 'ggSummaryDiagnosisPolypData',
            variables,
        }),
        queryFn: ({ signal }) => fetchGgSummaryDiagnosisPolypData({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GgSummaryHistologyPolypDataError = Fetcher.ErrorWrapper<undefined>;

export type GgSummaryHistologyPolypDataVariables = AceContext['fetcherOptions'];

export const fetchGgSummaryHistologyPolypData = (
    variables: GgSummaryHistologyPolypDataVariables,
    signal?: AbortSignal,
) =>
    aceFetch<undefined, GgSummaryHistologyPolypDataError, undefined, {}, {}, {}>({
        url: '/gg/summary/histology_polyp_data/',
        method: 'get',
        ...variables,
        signal,
    });

export const useGgSummaryHistologyPolypData = <TData = undefined>(
    variables: GgSummaryHistologyPolypDataVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<undefined, GgSummaryHistologyPolypDataError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<undefined, GgSummaryHistologyPolypDataError, TData>({
        queryKey: queryKeyFn({
            path: '/gg/summary/histology_polyp_data/',
            operationId: 'ggSummaryHistologyPolypData',
            variables,
        }),
        queryFn: ({ signal }) => fetchGgSummaryHistologyPolypData({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GraphReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type GraphReadQueryParams = {
    /**
     * Name of attribute key of the desired plotting data
     */
    plot?: string;
    /**
     * Target number of data points after sub-sampling. It is clamped to a maximum of 500 points.
     *
     * @default 100
     */
    sampling?: number;
    /**
     * Name of the aggregate method used for the resampling.
     *
     * @default mean
     */
    aggregate?: 'min' | 'mean' | 'max' | 'median' | 'sum' | 'std' | 'var';
};

export type GraphReadError = Fetcher.ErrorWrapper<undefined>;

export type GraphReadVariables = {
    pathParams: GraphReadPathParams;
    queryParams?: GraphReadQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Returns sub-sampled and evenly spaced data points of a plot such as "network_level" calculated
 * using pandas on raw Caddie attributes data points.
 */
export const fetchGraphRead = (variables: GraphReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.GraphPoints, GraphReadError, undefined, {}, GraphReadQueryParams, GraphReadPathParams>({
        url: '/graph/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns sub-sampled and evenly spaced data points of a plot such as "network_level" calculated
 * using pandas on raw Caddie attributes data points.
 */
export const useGraphRead = <TData = Schemas.GraphPoints>(
    variables: GraphReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GraphPoints, GraphReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GraphPoints, GraphReadError, TData>({
        queryKey: queryKeyFn({ path: '/graph/{procedureId}/', operationId: 'graphRead', variables }),
        queryFn: ({ signal }) => fetchGraphRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GroupMetagroupsListError = Fetcher.ErrorWrapper<undefined>;

export type GroupMetagroupsListResponse = Schemas.MetaUserGroup[];

export type GroupMetagroupsListVariables = AceContext['fetcherOptions'];

/**
 * Lists all MetaGroups, which are used to group users and/or subgroups (recursive model)
 * together under a chosen group type. These grouping data is used to get the different
 * stat tabs in the summary. The subgroups will be flattened with the users into a single "users" array,
 * no cycle checks are made so if the call crashes it is most likely due to this (you need to fix the meta groups
 * in the django admin panel in that case).
 */
export const fetchGroupMetagroupsList = (variables: GroupMetagroupsListVariables, signal?: AbortSignal) =>
    aceFetch<GroupMetagroupsListResponse, GroupMetagroupsListError, undefined, {}, {}, {}>({
        url: '/group/metagroups/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Lists all MetaGroups, which are used to group users and/or subgroups (recursive model)
 * together under a chosen group type. These grouping data is used to get the different
 * stat tabs in the summary. The subgroups will be flattened with the users into a single "users" array,
 * no cycle checks are made so if the call crashes it is most likely due to this (you need to fix the meta groups
 * in the django admin panel in that case).
 */
export const useGroupMetagroupsList = <TData = GroupMetagroupsListResponse>(
    variables: GroupMetagroupsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GroupMetagroupsListResponse, GroupMetagroupsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GroupMetagroupsListResponse, GroupMetagroupsListError, TData>({
        queryKey: queryKeyFn({ path: '/group/metagroups/', operationId: 'groupMetagroupsList', variables }),
        queryFn: ({ signal }) => fetchGroupMetagroupsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GroupTestUsersListError = Fetcher.ErrorWrapper<undefined>;

export type GroupTestUsersListResponse = Schemas.ReportUser[];

export type GroupTestUsersListVariables = AceContext['fetcherOptions'];

/**
 * Lists Caddie Users (ReportUser model) which are part of test MetaGroups, i.e. test users.
 */
export const fetchGroupTestUsersList = (variables: GroupTestUsersListVariables, signal?: AbortSignal) =>
    aceFetch<GroupTestUsersListResponse, GroupTestUsersListError, undefined, {}, {}, {}>({
        url: '/group/test/users/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Lists Caddie Users (ReportUser model) which are part of test MetaGroups, i.e. test users.
 */
export const useGroupTestUsersList = <TData = GroupTestUsersListResponse>(
    variables: GroupTestUsersListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GroupTestUsersListResponse, GroupTestUsersListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GroupTestUsersListResponse, GroupTestUsersListError, TData>({
        queryKey: queryKeyFn({ path: '/group/test/users/', operationId: 'groupTestUsersList', variables }),
        queryFn: ({ signal }) => fetchGroupTestUsersList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GroupTypesListError = Fetcher.ErrorWrapper<undefined>;

export type GroupTypesListResponse = Schemas.UserGroupType[];

export type GroupTypesListVariables = AceContext['fetcherOptions'];

/**
 * Types of meta groups, e.g. "site", "user", "all", "trust"...
 */
export const fetchGroupTypesList = (variables: GroupTypesListVariables, signal?: AbortSignal) =>
    aceFetch<GroupTypesListResponse, GroupTypesListError, undefined, {}, {}, {}>({
        url: '/group/types/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Types of meta groups, e.g. "site", "user", "all", "trust"...
 */
export const useGroupTypesList = <TData = GroupTypesListResponse>(
    variables: GroupTypesListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GroupTypesListResponse, GroupTypesListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GroupTypesListResponse, GroupTypesListError, TData>({
        queryKey: queryKeyFn({ path: '/group/types/', operationId: 'groupTypesList', variables }),
        queryFn: ({ signal }) => fetchGroupTypesList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GroupUsersListError = Fetcher.ErrorWrapper<undefined>;

export type GroupUsersListResponse = Schemas.ReportUser[];

export type GroupUsersListVariables = AceContext['fetcherOptions'];

/**
 * Caddie users (from ReportUser model, not to be confused with caddie report users), e.g. CADDIE-UCLH-001
 */
export const fetchGroupUsersList = (variables: GroupUsersListVariables, signal?: AbortSignal) =>
    aceFetch<GroupUsersListResponse, GroupUsersListError, undefined, {}, {}, {}>({
        url: '/group/users/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Caddie users (from ReportUser model, not to be confused with caddie report users), e.g. CADDIE-UCLH-001
 */
export const useGroupUsersList = <TData = GroupUsersListResponse>(
    variables: GroupUsersListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GroupUsersListResponse, GroupUsersListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GroupUsersListResponse, GroupUsersListError, TData>({
        queryKey: queryKeyFn({ path: '/group/users/', operationId: 'groupUsersList', variables }),
        queryFn: ({ signal }) => fetchGroupUsersList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type GroupsListError = Fetcher.ErrorWrapper<undefined>;

export type GroupsListResponse = Schemas.Group[];

export type GroupsListVariables = AceContext['fetcherOptions'];

export const fetchGroupsList = (variables: GroupsListVariables, signal?: AbortSignal) =>
    aceFetch<GroupsListResponse, GroupsListError, undefined, {}, {}, {}>({
        url: '/groups/',
        method: 'get',
        ...variables,
        signal,
    });

export const useGroupsList = <TData = GroupsListResponse>(
    variables: GroupsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<GroupsListResponse, GroupsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<GroupsListResponse, GroupsListError, TData>({
        queryKey: queryKeyFn({ path: '/groups/', operationId: 'groupsList', variables }),
        queryFn: ({ signal }) => fetchGroupsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type HospitalsListError = Fetcher.ErrorWrapper<undefined>;

export type HospitalsListResponse = Schemas.Hospital[];

export type HospitalsListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for getting hospital information such as acronym, address, logo...
 */
export const fetchHospitalsList = (variables: HospitalsListVariables, signal?: AbortSignal) =>
    aceFetch<HospitalsListResponse, HospitalsListError, undefined, {}, {}, {}>({
        url: '/hospitals/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for getting hospital information such as acronym, address, logo...
 */
export const useHospitalsList = <TData = HospitalsListResponse>(
    variables: HospitalsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<HospitalsListResponse, HospitalsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<HospitalsListResponse, HospitalsListError, TData>({
        queryKey: queryKeyFn({ path: '/hospitals/', operationId: 'hospitalsList', variables }),
        queryFn: ({ signal }) => fetchHospitalsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type HospitalsReadPathParams = {
    /**
     * A unique integer value identifying this hospital.
     */
    id: number;
};

export type HospitalsReadError = Fetcher.ErrorWrapper<undefined>;

export type HospitalsReadVariables = {
    pathParams: HospitalsReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for getting hospital information such as acronym, address, logo...
 */
export const fetchHospitalsRead = (variables: HospitalsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Hospital, HospitalsReadError, undefined, {}, {}, HospitalsReadPathParams>({
        url: '/hospitals/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for getting hospital information such as acronym, address, logo...
 */
export const useHospitalsRead = <TData = Schemas.Hospital>(
    variables: HospitalsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Hospital, HospitalsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Hospital, HospitalsReadError, TData>({
        queryKey: queryKeyFn({ path: '/hospitals/{id}/', operationId: 'hospitalsRead', variables }),
        queryFn: ({ signal }) => fetchHospitalsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ImagesReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ImagesReadQueryParams = {
    /**
     * Key to fetch the image frame from the recording bucket, e.g. polypID, caecum, retroflexion...
     */
    key: string;
    /**
     * Value to filter images by, disabled if not provided
     */
    value?: string;
    /**
     * Number of image frames to fetch, defaults to all
     */
    count?: number;
    /**
     * Whether to only fetch frames with blobs (default: False)
     *
     * @default false
     */
    filter_blobs?: boolean;
};

export type ImagesReadError = Fetcher.ErrorWrapper<undefined>;

export type ImagesReadVariables = {
    pathParams: ImagesReadPathParams;
    queryParams: ImagesReadQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for retrieving image frames (any full frames e.g. audit, caecum, retroflexion,
 * uses signed url pointing to the frame in the recording bucket.
 */
export const fetchImagesRead = (variables: ImagesReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ImageFrameFetching, ImagesReadError, undefined, {}, ImagesReadQueryParams, ImagesReadPathParams>({
        url: '/images/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for retrieving image frames (any full frames e.g. audit, caecum, retroflexion,
 * uses signed url pointing to the frame in the recording bucket.
 */
export const useImagesRead = <TData = Schemas.ImageFrameFetching>(
    variables: ImagesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ImageFrameFetching, ImagesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ImageFrameFetching, ImagesReadError, TData>({
        queryKey: queryKeyFn({ path: '/images/{procedureId}/', operationId: 'imagesRead', variables }),
        queryFn: ({ signal }) => fetchImagesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type MouseDagrunsListError = Fetcher.ErrorWrapper<undefined>;

export type MouseDagrunsListResponse = Schemas.DagRun[];

export type MouseDagrunsListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing dag runs.
 */
export const fetchMouseDagrunsList = (variables: MouseDagrunsListVariables, signal?: AbortSignal) =>
    aceFetch<MouseDagrunsListResponse, MouseDagrunsListError, undefined, {}, {}, {}>({
        url: '/mouse/dagruns/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing dag runs.
 */
export const useMouseDagrunsList = <TData = MouseDagrunsListResponse>(
    variables: MouseDagrunsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<MouseDagrunsListResponse, MouseDagrunsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<MouseDagrunsListResponse, MouseDagrunsListError, TData>({
        queryKey: queryKeyFn({ path: '/mouse/dagruns/', operationId: 'mouseDagrunsList', variables }),
        queryFn: ({ signal }) => fetchMouseDagrunsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type MouseDagrunsCreateError = Fetcher.ErrorWrapper<undefined>;

export type MouseDagrunsCreateVariables = {
    body: RequestBodies.DagRun;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing dag runs.
 */
export const fetchMouseDagrunsCreate = (variables: MouseDagrunsCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.DagRun, MouseDagrunsCreateError, RequestBodies.DagRun, {}, {}, {}>({
        url: '/mouse/dagruns/',
        method: 'post',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing dag runs.
 */
export const useMouseDagrunsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.DagRun, MouseDagrunsCreateError, MouseDagrunsCreateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.DagRun, MouseDagrunsCreateError, MouseDagrunsCreateVariables>({
        mutationFn: (variables: MouseDagrunsCreateVariables) =>
            fetchMouseDagrunsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type MouseDagrunsReadPathParams = {
    /**
     * A unique integer value identifying this dag run.
     */
    id: number;
};

export type MouseDagrunsReadError = Fetcher.ErrorWrapper<undefined>;

export type MouseDagrunsReadVariables = {
    pathParams: MouseDagrunsReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing dag runs.
 */
export const fetchMouseDagrunsRead = (variables: MouseDagrunsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.DagRun, MouseDagrunsReadError, undefined, {}, {}, MouseDagrunsReadPathParams>({
        url: '/mouse/dagruns/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing dag runs.
 */
export const useMouseDagrunsRead = <TData = Schemas.DagRun>(
    variables: MouseDagrunsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.DagRun, MouseDagrunsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.DagRun, MouseDagrunsReadError, TData>({
        queryKey: queryKeyFn({ path: '/mouse/dagruns/{id}/', operationId: 'mouseDagrunsRead', variables }),
        queryFn: ({ signal }) => fetchMouseDagrunsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type MouseDagrunsUpdatePathParams = {
    /**
     * A unique integer value identifying this dag run.
     */
    id: number;
};

export type MouseDagrunsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type MouseDagrunsUpdateVariables = {
    body: RequestBodies.DagRun;
    pathParams: MouseDagrunsUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing dag runs.
 */
export const fetchMouseDagrunsUpdate = (variables: MouseDagrunsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.DagRun, MouseDagrunsUpdateError, RequestBodies.DagRun, {}, {}, MouseDagrunsUpdatePathParams>({
        url: '/mouse/dagruns/{id}/',
        method: 'put',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing dag runs.
 */
export const useMouseDagrunsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.DagRun, MouseDagrunsUpdateError, MouseDagrunsUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.DagRun, MouseDagrunsUpdateError, MouseDagrunsUpdateVariables>({
        mutationFn: (variables: MouseDagrunsUpdateVariables) =>
            fetchMouseDagrunsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type MouseDagrunsPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this dag run.
     */
    id: number;
};

export type MouseDagrunsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type MouseDagrunsPartialUpdateVariables = {
    body: RequestBodies.DagRun;
    pathParams: MouseDagrunsPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing dag runs.
 */
export const fetchMouseDagrunsPartialUpdate = (variables: MouseDagrunsPartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.DagRun,
        MouseDagrunsPartialUpdateError,
        RequestBodies.DagRun,
        {},
        {},
        MouseDagrunsPartialUpdatePathParams
    >({ url: '/mouse/dagruns/{id}/', method: 'patch', ...variables, signal });

/**
 * Endpoint for managing dag runs.
 */
export const useMouseDagrunsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.DagRun,
            MouseDagrunsPartialUpdateError,
            MouseDagrunsPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.DagRun, MouseDagrunsPartialUpdateError, MouseDagrunsPartialUpdateVariables>({
        mutationFn: (variables: MouseDagrunsPartialUpdateVariables) =>
            fetchMouseDagrunsPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type MouseDagrunsDeletePathParams = {
    /**
     * A unique integer value identifying this dag run.
     */
    id: number;
};

export type MouseDagrunsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type MouseDagrunsDeleteVariables = {
    pathParams: MouseDagrunsDeletePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing dag runs.
 */
export const fetchMouseDagrunsDelete = (variables: MouseDagrunsDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, MouseDagrunsDeleteError, undefined, {}, {}, MouseDagrunsDeletePathParams>({
        url: '/mouse/dagruns/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing dag runs.
 */
export const useMouseDagrunsDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, MouseDagrunsDeleteError, MouseDagrunsDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, MouseDagrunsDeleteError, MouseDagrunsDeleteVariables>({
        mutationFn: (variables: MouseDagrunsDeleteVariables) =>
            fetchMouseDagrunsDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type NotificationsListError = Fetcher.ErrorWrapper<undefined>;

export type NotificationsListResponse = Schemas.ReportNotification[];

export type NotificationsListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const fetchNotificationsList = (variables: NotificationsListVariables, signal?: AbortSignal) =>
    aceFetch<NotificationsListResponse, NotificationsListError, undefined, {}, {}, {}>({
        url: '/notifications/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const useNotificationsList = <TData = NotificationsListResponse>(
    variables: NotificationsListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<NotificationsListResponse, NotificationsListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<NotificationsListResponse, NotificationsListError, TData>({
        queryKey: queryKeyFn({ path: '/notifications/', operationId: 'notificationsList', variables }),
        queryFn: ({ signal }) => fetchNotificationsList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type NotificationsCreateError = Fetcher.ErrorWrapper<undefined>;

export type NotificationsCreateVariables = {
    body: RequestBodies.ReportNotification;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const fetchNotificationsCreate = (variables: NotificationsCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ReportNotification, NotificationsCreateError, RequestBodies.ReportNotification, {}, {}, {}>({
        url: '/notifications/',
        method: 'post',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const useNotificationsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ReportNotification,
            NotificationsCreateError,
            NotificationsCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ReportNotification, NotificationsCreateError, NotificationsCreateVariables>({
        mutationFn: (variables: NotificationsCreateVariables) =>
            fetchNotificationsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type NotificationsReadPathParams = {
    /**
     * A unique integer value identifying this report notification.
     */
    id: number;
};

export type NotificationsReadError = Fetcher.ErrorWrapper<undefined>;

export type NotificationsReadVariables = {
    pathParams: NotificationsReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const fetchNotificationsRead = (variables: NotificationsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ReportNotification, NotificationsReadError, undefined, {}, {}, NotificationsReadPathParams>({
        url: '/notifications/{id}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const useNotificationsRead = <TData = Schemas.ReportNotification>(
    variables: NotificationsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ReportNotification, NotificationsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ReportNotification, NotificationsReadError, TData>({
        queryKey: queryKeyFn({ path: '/notifications/{id}/', operationId: 'notificationsRead', variables }),
        queryFn: ({ signal }) => fetchNotificationsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type NotificationsUpdatePathParams = {
    /**
     * A unique integer value identifying this report notification.
     */
    id: number;
};

export type NotificationsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type NotificationsUpdateVariables = {
    body: RequestBodies.ReportNotification;
    pathParams: NotificationsUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const fetchNotificationsUpdate = (variables: NotificationsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.ReportNotification,
        NotificationsUpdateError,
        RequestBodies.ReportNotification,
        {},
        {},
        NotificationsUpdatePathParams
    >({ url: '/notifications/{id}/', method: 'put', ...variables, signal });

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const useNotificationsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ReportNotification,
            NotificationsUpdateError,
            NotificationsUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.ReportNotification, NotificationsUpdateError, NotificationsUpdateVariables>({
        mutationFn: (variables: NotificationsUpdateVariables) =>
            fetchNotificationsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type NotificationsPartialUpdatePathParams = {
    /**
     * A unique integer value identifying this report notification.
     */
    id: number;
};

export type NotificationsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type NotificationsPartialUpdateVariables = {
    body: RequestBodies.ReportNotification;
    pathParams: NotificationsPartialUpdatePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const fetchNotificationsPartialUpdate = (variables: NotificationsPartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.ReportNotification,
        NotificationsPartialUpdateError,
        RequestBodies.ReportNotification,
        {},
        {},
        NotificationsPartialUpdatePathParams
    >({ url: '/notifications/{id}/', method: 'patch', ...variables, signal });

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const useNotificationsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ReportNotification,
            NotificationsPartialUpdateError,
            NotificationsPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.ReportNotification,
        NotificationsPartialUpdateError,
        NotificationsPartialUpdateVariables
    >({
        mutationFn: (variables: NotificationsPartialUpdateVariables) =>
            fetchNotificationsPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type NotificationsDeletePathParams = {
    /**
     * A unique integer value identifying this report notification.
     */
    id: number;
};

export type NotificationsDeleteError = Fetcher.ErrorWrapper<undefined>;

export type NotificationsDeleteVariables = {
    pathParams: NotificationsDeletePathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const fetchNotificationsDelete = (variables: NotificationsDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, NotificationsDeleteError, undefined, {}, {}, NotificationsDeletePathParams>({
        url: '/notifications/{id}/',
        method: 'delete',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing notifications sent to users when an answering comment is created or modified.
 */
export const useNotificationsDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, NotificationsDeleteError, NotificationsDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, NotificationsDeleteError, NotificationsDeleteVariables>({
        mutationFn: (variables: NotificationsDeleteVariables) =>
            fetchNotificationsDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type PerformanceSearchQueryParams = {
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
    /**
     * Doctor name
     */
    doctor?: string;
    /**
     * Site name
     */
    site?: string;
};

export type PerformanceSearchError = Fetcher.ErrorWrapper<undefined>;

export type PerformanceSearchResponse = {
    count: number;
    /**
     * @format uri
     */
    next?: string | null;
    /**
     * @format uri
     */
    previous?: string | null;
    results: Schemas.Procedure518b984a[];
};

export type PerformanceSearchVariables = {
    queryParams?: PerformanceSearchQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Search for procedures by doctor name or site
 */
export const fetchPerformanceSearch = (variables: PerformanceSearchVariables, signal?: AbortSignal) =>
    aceFetch<PerformanceSearchResponse, PerformanceSearchError, undefined, {}, PerformanceSearchQueryParams, {}>({
        url: '/performance/search/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Search for procedures by doctor name or site
 */
export const usePerformanceSearch = <TData = PerformanceSearchResponse>(
    variables: PerformanceSearchVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<PerformanceSearchResponse, PerformanceSearchError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<PerformanceSearchResponse, PerformanceSearchError, TData>({
        queryKey: queryKeyFn({ path: '/performance/search/', operationId: 'performanceSearch', variables }),
        queryFn: ({ signal }) => fetchPerformanceSearch({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDownloadCoCreationDataReadQueryParams = {
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
};

export type ProceduresDownloadCoCreationDataReadError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDownloadCoCreationDataReadResponse = {
    count: number;
    /**
     * @format uri
     */
    next?: string | null;
    /**
     * @format uri
     */
    previous?: string | null;
    results: Schemas.Procedure4ab406b9[];
};

export type ProceduresDownloadCoCreationDataReadVariables = {
    queryParams?: ProceduresDownloadCoCreationDataReadQueryParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDownloadCoCreationDataRead = (
    variables: ProceduresDownloadCoCreationDataReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ProceduresDownloadCoCreationDataReadResponse,
        ProceduresDownloadCoCreationDataReadError,
        undefined,
        {},
        ProceduresDownloadCoCreationDataReadQueryParams,
        {}
    >({ url: '/procedures/download_co_creation_data/', method: 'get', ...variables, signal });

export const useProceduresDownloadCoCreationDataRead = <TData = ProceduresDownloadCoCreationDataReadResponse>(
    variables: ProceduresDownloadCoCreationDataReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ProceduresDownloadCoCreationDataReadResponse,
            ProceduresDownloadCoCreationDataReadError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ProceduresDownloadCoCreationDataReadResponse,
        ProceduresDownloadCoCreationDataReadError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/procedures/download_co_creation_data/',
            operationId: 'proceduresDownloadCoCreationDataRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresDownloadCoCreationDataRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDownloadCoCreationDataCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDownloadCoCreationDataCreateVariables = {
    body: RequestBodies.Procedure4ab406b9;
} & AceContext['fetcherOptions'];

export const fetchProceduresDownloadCoCreationDataCreate = (
    variables: ProceduresDownloadCoCreationDataCreateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.Procedure4ab406b9,
        ProceduresDownloadCoCreationDataCreateError,
        RequestBodies.Procedure4ab406b9,
        {},
        {},
        {}
    >({ url: '/procedures/download_co_creation_data/', method: 'post', ...variables, signal });

export const useProceduresDownloadCoCreationDataCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.Procedure4ab406b9,
            ProceduresDownloadCoCreationDataCreateError,
            ProceduresDownloadCoCreationDataCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.Procedure4ab406b9,
        ProceduresDownloadCoCreationDataCreateError,
        ProceduresDownloadCoCreationDataCreateVariables
    >({
        mutationFn: (variables: ProceduresDownloadCoCreationDataCreateVariables) =>
            fetchProceduresDownloadCoCreationDataCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresDownloadMergedHistologyDataReadQueryParams = {
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
};

export type ProceduresDownloadMergedHistologyDataReadError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDownloadMergedHistologyDataReadResponse = {
    count: number;
    /**
     * @format uri
     */
    next?: string | null;
    /**
     * @format uri
     */
    previous?: string | null;
    results: Schemas.Procedure4ab406b9[];
};

export type ProceduresDownloadMergedHistologyDataReadVariables = {
    queryParams?: ProceduresDownloadMergedHistologyDataReadQueryParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDownloadMergedHistologyDataRead = (
    variables: ProceduresDownloadMergedHistologyDataReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ProceduresDownloadMergedHistologyDataReadResponse,
        ProceduresDownloadMergedHistologyDataReadError,
        undefined,
        {},
        ProceduresDownloadMergedHistologyDataReadQueryParams,
        {}
    >({ url: '/procedures/download_merged_histology_data/', method: 'get', ...variables, signal });

export const useProceduresDownloadMergedHistologyDataRead = <TData = ProceduresDownloadMergedHistologyDataReadResponse>(
    variables: ProceduresDownloadMergedHistologyDataReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            ProceduresDownloadMergedHistologyDataReadResponse,
            ProceduresDownloadMergedHistologyDataReadError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        ProceduresDownloadMergedHistologyDataReadResponse,
        ProceduresDownloadMergedHistologyDataReadError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/procedures/download_merged_histology_data/',
            operationId: 'proceduresDownloadMergedHistologyDataRead',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchProceduresDownloadMergedHistologyDataRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDownloadMergedHistologyDataCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDownloadMergedHistologyDataCreateVariables = {
    body: RequestBodies.Procedure4ab406b9;
} & AceContext['fetcherOptions'];

export const fetchProceduresDownloadMergedHistologyDataCreate = (
    variables: ProceduresDownloadMergedHistologyDataCreateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.Procedure4ab406b9,
        ProceduresDownloadMergedHistologyDataCreateError,
        RequestBodies.Procedure4ab406b9,
        {},
        {},
        {}
    >({ url: '/procedures/download_merged_histology_data/', method: 'post', ...variables, signal });

export const useProceduresDownloadMergedHistologyDataCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.Procedure4ab406b9,
            ProceduresDownloadMergedHistologyDataCreateError,
            ProceduresDownloadMergedHistologyDataCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.Procedure4ab406b9,
        ProceduresDownloadMergedHistologyDataCreateError,
        ProceduresDownloadMergedHistologyDataCreateVariables
    >({
        mutationFn: (variables: ProceduresDownloadMergedHistologyDataCreateVariables) =>
            fetchProceduresDownloadMergedHistologyDataCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresDownloadPolypTimelineAnnotationsReadQueryParams = {
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
    /**
     * Job id to check the status of the task
     */
    job?: string;
};

export type ProceduresDownloadPolypTimelineAnnotationsReadError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: {
              state?: string;
          };
      }
    | {
          status: 500;
          payload: {
              state?: string;
              error?: string;
          };
      }
>;

export type ProceduresDownloadPolypTimelineAnnotationsReadVariables = {
    queryParams?: ProceduresDownloadPolypTimelineAnnotationsReadQueryParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDownloadPolypTimelineAnnotationsRead = (
    variables: ProceduresDownloadPolypTimelineAnnotationsReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        | {
              /**
               * @format binary
               */
              csv?: Blob;
          }
        | {
              state?: string;
          },
        ProceduresDownloadPolypTimelineAnnotationsReadError,
        undefined,
        {},
        ProceduresDownloadPolypTimelineAnnotationsReadQueryParams,
        {}
    >({ url: '/procedures/download_polyp_timeline_annotations/', method: 'get', ...variables, signal });

export const useProceduresDownloadPolypTimelineAnnotationsRead = <
    TData =
        | {
              /**
               * @format binary
               */
              csv?: Blob;
          }
        | {
              state?: string;
          },
>(
    variables: ProceduresDownloadPolypTimelineAnnotationsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            | {
                  /**
                   * @format binary
                   */
                  csv?: Blob;
              }
            | {
                  state?: string;
              },
            ProceduresDownloadPolypTimelineAnnotationsReadError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        | {
              /**
               * @format binary
               */
              csv?: Blob;
          }
        | {
              state?: string;
          },
        ProceduresDownloadPolypTimelineAnnotationsReadError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/procedures/download_polyp_timeline_annotations/',
            operationId: 'proceduresDownloadPolypTimelineAnnotationsRead',
            variables,
        }),
        queryFn: ({ signal }) =>
            fetchProceduresDownloadPolypTimelineAnnotationsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDownloadPolypTimelineAnnotationsCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDownloadPolypTimelineAnnotationsCreateResponse = {
    job?: string;
};

export type ProceduresDownloadPolypTimelineAnnotationsCreateVariables = {
    body: RequestBodies.Procedure4ab406b9;
} & AceContext['fetcherOptions'];

export const fetchProceduresDownloadPolypTimelineAnnotationsCreate = (
    variables: ProceduresDownloadPolypTimelineAnnotationsCreateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ProceduresDownloadPolypTimelineAnnotationsCreateResponse,
        ProceduresDownloadPolypTimelineAnnotationsCreateError,
        RequestBodies.Procedure4ab406b9,
        {},
        {},
        {}
    >({ url: '/procedures/download_polyp_timeline_annotations/', method: 'post', ...variables, signal });

export const useProceduresDownloadPolypTimelineAnnotationsCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            ProceduresDownloadPolypTimelineAnnotationsCreateResponse,
            ProceduresDownloadPolypTimelineAnnotationsCreateError,
            ProceduresDownloadPolypTimelineAnnotationsCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        ProceduresDownloadPolypTimelineAnnotationsCreateResponse,
        ProceduresDownloadPolypTimelineAnnotationsCreateError,
        ProceduresDownloadPolypTimelineAnnotationsCreateVariables
    >({
        mutationFn: (variables: ProceduresDownloadPolypTimelineAnnotationsCreateVariables) =>
            fetchProceduresDownloadPolypTimelineAnnotationsCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresLatestQueryParams = {
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
};

export type ProceduresLatestError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresLatestVariables = {
    queryParams?: ProceduresLatestQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Lists all procedures that happened in the latest 24 hours ordered with descending start time.
 */
export const fetchProceduresLatest = (variables: ProceduresLatestVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.LatestProcedures, ProceduresLatestError, undefined, {}, ProceduresLatestQueryParams, {}>({
        url: '/procedures/latest/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Lists all procedures that happened in the latest 24 hours ordered with descending start time.
 */
export const useProceduresLatest = <TData = Schemas.LatestProcedures>(
    variables: ProceduresLatestVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.LatestProcedures, ProceduresLatestError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.LatestProcedures, ProceduresLatestError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/latest/', operationId: 'proceduresLatest', variables }),
        queryFn: ({ signal }) => fetchProceduresLatest({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresQuickSearchQueryParams = {
    /**
     * A page number within the paginated result set.
     */
    page?: number;
    /**
     * Number of results to return per page.
     */
    page_size?: number;
};

export type ProceduresQuickSearchError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresQuickSearchResponse = {
    count: number;
    /**
     * @format uri
     */
    next?: string | null;
    /**
     * @format uri
     */
    previous?: string | null;
    results: Schemas.Procedure4ab406b9[];
};

export type ProceduresQuickSearchVariables = {
    queryParams?: ProceduresQuickSearchQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Quick search endpoint that returns a list of procedures matching the given search term.
 */
export const fetchProceduresQuickSearch = (variables: ProceduresQuickSearchVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresQuickSearchResponse,
        ProceduresQuickSearchError,
        undefined,
        {},
        ProceduresQuickSearchQueryParams,
        {}
    >({ url: '/procedures/quick_search/', method: 'get', ...variables, signal });

/**
 * Quick search endpoint that returns a list of procedures matching the given search term.
 */
export const useProceduresQuickSearch = <TData = ProceduresQuickSearchResponse>(
    variables: ProceduresQuickSearchVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresQuickSearchResponse, ProceduresQuickSearchError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresQuickSearchResponse, ProceduresQuickSearchError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/quick_search/', operationId: 'proceduresQuickSearch', variables }),
        queryFn: ({ signal }) => fetchProceduresQuickSearch({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresReadError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresReadVariables = {
    pathParams: ProceduresReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Returns all procedure info and predictions (it can be quite a lot). Reserved for admins.
 */
export const fetchProceduresRead = (variables: ProceduresReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Procedure4ab406b9, ProceduresReadError, undefined, {}, {}, ProceduresReadPathParams>({
        url: '/procedures/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns all procedure info and predictions (it can be quite a lot). Reserved for admins.
 */
export const useProceduresRead = <TData = Schemas.Procedure4ab406b9>(
    variables: ProceduresReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Procedure4ab406b9, ProceduresReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Procedure4ab406b9, ProceduresReadError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/{procedureId}/', operationId: 'proceduresRead', variables }),
        queryFn: ({ signal }) => fetchProceduresRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresCaecumPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresCaecumError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresCaecumVariables = {
    pathParams: ProceduresCaecumPathParams;
} & AceContext['fetcherOptions'];

/**
 * Return first caecum prediction having child attribute caecum=yes for a procedure. Essentially
 * returns the prediction with timestamp if caecum is detected otherwise nothing.
 */
export const fetchProceduresCaecum = (variables: ProceduresCaecumVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Prediction, ProceduresCaecumError, undefined, {}, {}, ProceduresCaecumPathParams>({
        url: '/procedures/{procedureId}/caecum/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Return first caecum prediction having child attribute caecum=yes for a procedure. Essentially
 * returns the prediction with timestamp if caecum is detected otherwise nothing.
 */
export const useProceduresCaecum = <TData = Schemas.Prediction>(
    variables: ProceduresCaecumVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Prediction, ProceduresCaecumError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Prediction, ProceduresCaecumError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/{procedureId}/caecum/', operationId: 'proceduresCaecum', variables }),
        queryFn: ({ signal }) => fetchProceduresCaecum({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresCaecumDebugPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresCaecumDebugError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresCaecumDebugResponse = Schemas.Prediction[];

export type ProceduresCaecumDebugVariables = {
    pathParams: ProceduresCaecumDebugPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresCaecumDebug = (variables: ProceduresCaecumDebugVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresCaecumDebugResponse,
        ProceduresCaecumDebugError,
        undefined,
        {},
        {},
        ProceduresCaecumDebugPathParams
    >({ url: '/procedures/{procedureId}/caecum_debug/', method: 'get', ...variables, signal });

export const useProceduresCaecumDebug = <TData = ProceduresCaecumDebugResponse>(
    variables: ProceduresCaecumDebugVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresCaecumDebugResponse, ProceduresCaecumDebugError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresCaecumDebugResponse, ProceduresCaecumDebugError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/caecum_debug/',
            operationId: 'proceduresCaecumDebug',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresCaecumDebug({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresCommentsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresCommentsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresCommentsResponse = Schemas.ReportComment[];

export type ProceduresCommentsVariables = {
    pathParams: ProceduresCommentsPathParams;
} & AceContext['fetcherOptions'];

/**
 * Lists all comments of the procedure.
 */
export const fetchProceduresComments = (variables: ProceduresCommentsVariables, signal?: AbortSignal) =>
    aceFetch<ProceduresCommentsResponse, ProceduresCommentsError, undefined, {}, {}, ProceduresCommentsPathParams>({
        url: '/procedures/{procedureId}/comments/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Lists all comments of the procedure.
 */
export const useProceduresComments = <TData = ProceduresCommentsResponse>(
    variables: ProceduresCommentsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresCommentsResponse, ProceduresCommentsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresCommentsResponse, ProceduresCommentsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/comments/',
            operationId: 'proceduresComments',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresComments({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDetectionsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresDetectionsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDetectionsResponse = Schemas.Detection[];

export type ProceduresDetectionsVariables = {
    pathParams: ProceduresDetectionsPathParams;
} & AceContext['fetcherOptions'];

/**
 * Lists all detection boxes of a procedure. Ordered by timestamp.
 */
export const fetchProceduresDetections = (variables: ProceduresDetectionsVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresDetectionsResponse,
        ProceduresDetectionsError,
        undefined,
        {},
        {},
        ProceduresDetectionsPathParams
    >({ url: '/procedures/{procedureId}/detections/', method: 'get', ...variables, signal });

/**
 * Lists all detection boxes of a procedure. Ordered by timestamp.
 */
export const useProceduresDetections = <TData = ProceduresDetectionsResponse>(
    variables: ProceduresDetectionsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresDetectionsResponse, ProceduresDetectionsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresDetectionsResponse, ProceduresDetectionsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/detections/',
            operationId: 'proceduresDetections',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresDetections({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDetectionsPolygonPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresDetectionsPolygonError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDetectionsPolygonResponse = Schemas.DetectionPolygon[];

export type ProceduresDetectionsPolygonVariables = {
    pathParams: ProceduresDetectionsPolygonPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDetectionsPolygon = (
    variables: ProceduresDetectionsPolygonVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ProceduresDetectionsPolygonResponse,
        ProceduresDetectionsPolygonError,
        undefined,
        {},
        {},
        ProceduresDetectionsPolygonPathParams
    >({ url: '/procedures/{procedureId}/detections_polygon/', method: 'get', ...variables, signal });

export const useProceduresDetectionsPolygon = <TData = ProceduresDetectionsPolygonResponse>(
    variables: ProceduresDetectionsPolygonVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresDetectionsPolygonResponse, ProceduresDetectionsPolygonError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresDetectionsPolygonResponse, ProceduresDetectionsPolygonError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/detections_polygon/',
            operationId: 'proceduresDetectionsPolygon',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresDetectionsPolygon({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDoctorReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresDoctorReadError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDoctorReadVariables = {
    pathParams: ProceduresDoctorReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDoctorRead = (variables: ProceduresDoctorReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.ProcedureDoctor, ProceduresDoctorReadError, undefined, {}, {}, ProceduresDoctorReadPathParams>({
        url: '/procedures/{procedureId}/doctor/',
        method: 'get',
        ...variables,
        signal,
    });

export const useProceduresDoctorRead = <TData = Schemas.ProcedureDoctor>(
    variables: ProceduresDoctorReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.ProcedureDoctor, ProceduresDoctorReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.ProcedureDoctor, ProceduresDoctorReadError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/doctor/',
            operationId: 'proceduresDoctorRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresDoctorRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresDoctorCreatePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresDoctorCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDoctorCreateVariables = {
    body: RequestBodies.Doctor;
    pathParams: ProceduresDoctorCreatePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDoctorCreate = (variables: ProceduresDoctorCreateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.ProcedureDoctor,
        ProceduresDoctorCreateError,
        RequestBodies.Doctor,
        {},
        {},
        ProceduresDoctorCreatePathParams
    >({ url: '/procedures/{procedureId}/doctor/', method: 'post', ...variables, signal });

export const useProceduresDoctorCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ProcedureDoctor,
            ProceduresDoctorCreateError,
            ProceduresDoctorCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.ProcedureDoctor,
        ProceduresDoctorCreateError,
        ProceduresDoctorCreateVariables
    >({
        mutationFn: (variables: ProceduresDoctorCreateVariables) =>
            fetchProceduresDoctorCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresDoctorUpdatePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresDoctorUpdateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDoctorUpdateVariables = {
    body: RequestBodies.Doctor;
    pathParams: ProceduresDoctorUpdatePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDoctorUpdate = (variables: ProceduresDoctorUpdateVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.ProcedureDoctor,
        ProceduresDoctorUpdateError,
        RequestBodies.Doctor,
        {},
        {},
        ProceduresDoctorUpdatePathParams
    >({ url: '/procedures/{procedureId}/doctor/', method: 'put', ...variables, signal });

export const useProceduresDoctorUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.ProcedureDoctor,
            ProceduresDoctorUpdateError,
            ProceduresDoctorUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.ProcedureDoctor,
        ProceduresDoctorUpdateError,
        ProceduresDoctorUpdateVariables
    >({
        mutationFn: (variables: ProceduresDoctorUpdateVariables) =>
            fetchProceduresDoctorUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresDoctorDeletePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresDoctorDeleteError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresDoctorDeleteVariables = {
    pathParams: ProceduresDoctorDeletePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresDoctorDelete = (variables: ProceduresDoctorDeleteVariables, signal?: AbortSignal) =>
    aceFetch<undefined, ProceduresDoctorDeleteError, undefined, {}, {}, ProceduresDoctorDeletePathParams>({
        url: '/procedures/{procedureId}/doctor/',
        method: 'delete',
        ...variables,
        signal,
    });

export const useProceduresDoctorDelete = (
    options?: Omit<
        reactQuery.UseMutationOptions<undefined, ProceduresDoctorDeleteError, ProceduresDoctorDeleteVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<undefined, ProceduresDoctorDeleteError, ProceduresDoctorDeleteVariables>({
        mutationFn: (variables: ProceduresDoctorDeleteVariables) =>
            fetchProceduresDoctorDelete({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresEventsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresEventsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresEventsResponse = Schemas.ProcedureEvent[];

export type ProceduresEventsVariables = {
    pathParams: ProceduresEventsPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresEvents = (variables: ProceduresEventsVariables, signal?: AbortSignal) =>
    aceFetch<ProceduresEventsResponse, ProceduresEventsError, undefined, {}, {}, ProceduresEventsPathParams>({
        url: '/procedures/{procedureId}/events/',
        method: 'get',
        ...variables,
        signal,
    });

export const useProceduresEvents = <TData = ProceduresEventsResponse>(
    variables: ProceduresEventsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresEventsResponse, ProceduresEventsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresEventsResponse, ProceduresEventsError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/{procedureId}/events/', operationId: 'proceduresEvents', variables }),
        queryFn: ({ signal }) => fetchProceduresEvents({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresExtraDetectionsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresExtraDetectionsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresExtraDetectionsResponse = Schemas.AttributeD7e6d55b[];

export type ProceduresExtraDetectionsVariables = {
    pathParams: ProceduresExtraDetectionsPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresExtraDetections = (variables: ProceduresExtraDetectionsVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresExtraDetectionsResponse,
        ProceduresExtraDetectionsError,
        undefined,
        {},
        {},
        ProceduresExtraDetectionsPathParams
    >({ url: '/procedures/{procedureId}/extra_detections/', method: 'get', ...variables, signal });

export const useProceduresExtraDetections = <TData = ProceduresExtraDetectionsResponse>(
    variables: ProceduresExtraDetectionsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresExtraDetectionsResponse, ProceduresExtraDetectionsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresExtraDetectionsResponse, ProceduresExtraDetectionsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/extra_detections/',
            operationId: 'proceduresExtraDetections',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresExtraDetections({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresFalseAlarmsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresFalseAlarmsQueryParams = {
    /**
     * Whether we count the alarms in the withdrawal phase only
     *
     * @default true
     */
    only_withdrawal?: boolean;
    /**
     * Threshold for merging alarms
     *
     * @default 200
     */
    threshold?: number;
};

export type ProceduresFalseAlarmsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresFalseAlarmsVariables = {
    pathParams: ProceduresFalseAlarmsPathParams;
    queryParams?: ProceduresFalseAlarmsQueryParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresFalseAlarms = (variables: ProceduresFalseAlarmsVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.FalseAlarm,
        ProceduresFalseAlarmsError,
        undefined,
        {},
        ProceduresFalseAlarmsQueryParams,
        ProceduresFalseAlarmsPathParams
    >({ url: '/procedures/{procedureId}/false_alarms/', method: 'get', ...variables, signal });

export const useProceduresFalseAlarms = <TData = Schemas.FalseAlarm>(
    variables: ProceduresFalseAlarmsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.FalseAlarm, ProceduresFalseAlarmsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.FalseAlarm, ProceduresFalseAlarmsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/false_alarms/',
            operationId: 'proceduresFalseAlarms',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresFalseAlarms({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresFreezeFramesPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresFreezeFramesError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresFreezeFramesResponse = Schemas.Prediction[];

export type ProceduresFreezeFramesVariables = {
    pathParams: ProceduresFreezeFramesPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresFreezeFrames = (variables: ProceduresFreezeFramesVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresFreezeFramesResponse,
        ProceduresFreezeFramesError,
        undefined,
        {},
        {},
        ProceduresFreezeFramesPathParams
    >({ url: '/procedures/{procedureId}/freeze_frames/', method: 'get', ...variables, signal });

export const useProceduresFreezeFrames = <TData = ProceduresFreezeFramesResponse>(
    variables: ProceduresFreezeFramesVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresFreezeFramesResponse, ProceduresFreezeFramesError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresFreezeFramesResponse, ProceduresFreezeFramesError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/freeze_frames/',
            operationId: 'proceduresFreezeFrames',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresFreezeFrames({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresGenerateAnnotationsXmlReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresGenerateAnnotationsXmlReadQueryParams = {
    /**
     * Job id to check the status of the task
     */
    job?: string;
};

export type ProceduresGenerateAnnotationsXmlReadError = Fetcher.ErrorWrapper<
    | {
          status: 400;
          payload: {
              state?: string;
          };
      }
    | {
          status: 500;
          payload: {
              state?: string;
              error?: string;
          };
      }
>;

export type ProceduresGenerateAnnotationsXmlReadVariables = {
    pathParams: ProceduresGenerateAnnotationsXmlReadPathParams;
    queryParams?: ProceduresGenerateAnnotationsXmlReadQueryParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresGenerateAnnotationsXmlRead = (
    variables: ProceduresGenerateAnnotationsXmlReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        | {
              /**
               * @format binary
               */
              xml?: Blob;
          }
        | {
              state?: string;
          },
        ProceduresGenerateAnnotationsXmlReadError,
        undefined,
        {},
        ProceduresGenerateAnnotationsXmlReadQueryParams,
        ProceduresGenerateAnnotationsXmlReadPathParams
    >({ url: '/procedures/{procedureId}/generate_annotations_xml/', method: 'get', ...variables, signal });

export const useProceduresGenerateAnnotationsXmlRead = <
    TData =
        | {
              /**
               * @format binary
               */
              xml?: Blob;
          }
        | {
              state?: string;
          },
>(
    variables: ProceduresGenerateAnnotationsXmlReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<
            | {
                  /**
                   * @format binary
                   */
                  xml?: Blob;
              }
            | {
                  state?: string;
              },
            ProceduresGenerateAnnotationsXmlReadError,
            TData
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<
        | {
              /**
               * @format binary
               */
              xml?: Blob;
          }
        | {
              state?: string;
          },
        ProceduresGenerateAnnotationsXmlReadError,
        TData
    >({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/generate_annotations_xml/',
            operationId: 'proceduresGenerateAnnotationsXmlRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresGenerateAnnotationsXmlRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresGenerateAnnotationsXmlCreatePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresGenerateAnnotationsXmlCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresGenerateAnnotationsXmlCreateResponse = {
    job?: string;
};

export type ProceduresGenerateAnnotationsXmlCreateVariables = {
    body: RequestBodies.Procedure4ab406b9;
    pathParams: ProceduresGenerateAnnotationsXmlCreatePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresGenerateAnnotationsXmlCreate = (
    variables: ProceduresGenerateAnnotationsXmlCreateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        ProceduresGenerateAnnotationsXmlCreateResponse,
        ProceduresGenerateAnnotationsXmlCreateError,
        RequestBodies.Procedure4ab406b9,
        {},
        {},
        ProceduresGenerateAnnotationsXmlCreatePathParams
    >({ url: '/procedures/{procedureId}/generate_annotations_xml/', method: 'post', ...variables, signal });

export const useProceduresGenerateAnnotationsXmlCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            ProceduresGenerateAnnotationsXmlCreateResponse,
            ProceduresGenerateAnnotationsXmlCreateError,
            ProceduresGenerateAnnotationsXmlCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        ProceduresGenerateAnnotationsXmlCreateResponse,
        ProceduresGenerateAnnotationsXmlCreateError,
        ProceduresGenerateAnnotationsXmlCreateVariables
    >({
        mutationFn: (variables: ProceduresGenerateAnnotationsXmlCreateVariables) =>
            fetchProceduresGenerateAnnotationsXmlCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresLogsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresLogsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresLogsResponse = Schemas.ProcedureLog[];

export type ProceduresLogsVariables = {
    pathParams: ProceduresLogsPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresLogs = (variables: ProceduresLogsVariables, signal?: AbortSignal) =>
    aceFetch<ProceduresLogsResponse, ProceduresLogsError, undefined, {}, {}, ProceduresLogsPathParams>({
        url: '/procedures/{procedureId}/logs/',
        method: 'get',
        ...variables,
        signal,
    });

export const useProceduresLogs = <TData = ProceduresLogsResponse>(
    variables: ProceduresLogsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresLogsResponse, ProceduresLogsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresLogsResponse, ProceduresLogsError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/{procedureId}/logs/', operationId: 'proceduresLogs', variables }),
        queryFn: ({ signal }) => fetchProceduresLogs({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresPredictionsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresPredictionsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresPredictionsResponse = Schemas.Prediction[];

export type ProceduresPredictionsVariables = {
    pathParams: ProceduresPredictionsPathParams;
} & AceContext['fetcherOptions'];

/**
 * Lists all predictions with at least on child detection for a procedure. Ordered by timestamp.
 */
export const fetchProceduresPredictions = (variables: ProceduresPredictionsVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresPredictionsResponse,
        ProceduresPredictionsError,
        undefined,
        {},
        {},
        ProceduresPredictionsPathParams
    >({ url: '/procedures/{procedureId}/predictions/', method: 'get', ...variables, signal });

/**
 * Lists all predictions with at least on child detection for a procedure. Ordered by timestamp.
 */
export const useProceduresPredictions = <TData = ProceduresPredictionsResponse>(
    variables: ProceduresPredictionsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresPredictionsResponse, ProceduresPredictionsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresPredictionsResponse, ProceduresPredictionsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/predictions/',
            operationId: 'proceduresPredictions',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresPredictions({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresRecordingsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRecordingsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRecordingsVariables = {
    pathParams: ProceduresRecordingsPathParams;
} & AceContext['fetcherOptions'];

/**
 * Returns the first recording linked to the procedure if any (used to retrieve patient_id).
 */
export const fetchProceduresRecordings = (variables: ProceduresRecordingsVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Procedure4ab406b9, ProceduresRecordingsError, undefined, {}, {}, ProceduresRecordingsPathParams>({
        url: '/procedures/{procedureId}/recordings/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns the first recording linked to the procedure if any (used to retrieve patient_id).
 */
export const useProceduresRecordings = <TData = Schemas.Procedure4ab406b9>(
    variables: ProceduresRecordingsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Procedure4ab406b9, ProceduresRecordingsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Procedure4ab406b9, ProceduresRecordingsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/recordings/',
            operationId: 'proceduresRecordings',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresRecordings({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresRequestCatUploadReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestCatUploadReadError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestCatUploadReadVariables = {
    pathParams: ProceduresRequestCatUploadReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestCatUploadRead = (
    variables: ProceduresRequestCatUploadReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.Procedure4ab406b9,
        ProceduresRequestCatUploadReadError,
        undefined,
        {},
        {},
        ProceduresRequestCatUploadReadPathParams
    >({ url: '/procedures/{procedureId}/request_cat_upload/', method: 'get', ...variables, signal });

export const useProceduresRequestCatUploadRead = <TData = Schemas.Procedure4ab406b9>(
    variables: ProceduresRequestCatUploadReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Procedure4ab406b9, ProceduresRequestCatUploadReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Procedure4ab406b9, ProceduresRequestCatUploadReadError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/request_cat_upload/',
            operationId: 'proceduresRequestCatUploadRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresRequestCatUploadRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresRequestCatUploadCreatePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestCatUploadCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestCatUploadCreateVariables = {
    body: RequestBodies.Procedure4ab406b9;
    pathParams: ProceduresRequestCatUploadCreatePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestCatUploadCreate = (
    variables: ProceduresRequestCatUploadCreateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.Procedure4ab406b9,
        ProceduresRequestCatUploadCreateError,
        RequestBodies.Procedure4ab406b9,
        {},
        {},
        ProceduresRequestCatUploadCreatePathParams
    >({ url: '/procedures/{procedureId}/request_cat_upload/', method: 'post', ...variables, signal });

export const useProceduresRequestCatUploadCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.Procedure4ab406b9,
            ProceduresRequestCatUploadCreateError,
            ProceduresRequestCatUploadCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.Procedure4ab406b9,
        ProceduresRequestCatUploadCreateError,
        ProceduresRequestCatUploadCreateVariables
    >({
        mutationFn: (variables: ProceduresRequestCatUploadCreateVariables) =>
            fetchProceduresRequestCatUploadCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresRequestDetectionPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestDetectionError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestDetectionVariables = {
    body: Schemas.DetectionRequest;
    pathParams: ProceduresRequestDetectionPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestDetection = (variables: ProceduresRequestDetectionVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.DetectionRequest,
        ProceduresRequestDetectionError,
        Schemas.DetectionRequest,
        {},
        {},
        ProceduresRequestDetectionPathParams
    >({ url: '/procedures/{procedureId}/request_detection/', method: 'post', ...variables, signal });

export const useProceduresRequestDetection = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.DetectionRequest,
            ProceduresRequestDetectionError,
            ProceduresRequestDetectionVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.DetectionRequest,
        ProceduresRequestDetectionError,
        ProceduresRequestDetectionVariables
    >({
        mutationFn: (variables: ProceduresRequestDetectionVariables) =>
            fetchProceduresRequestDetection({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresRequestDiagnosisPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestDiagnosisError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestDiagnosisVariables = {
    body: Schemas.DiagnosisRequest;
    pathParams: ProceduresRequestDiagnosisPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestDiagnosis = (variables: ProceduresRequestDiagnosisVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.DiagnosisRequest,
        ProceduresRequestDiagnosisError,
        Schemas.DiagnosisRequest,
        {},
        {},
        ProceduresRequestDiagnosisPathParams
    >({ url: '/procedures/{procedureId}/request_diagnosis/', method: 'post', ...variables, signal });

export const useProceduresRequestDiagnosis = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.DiagnosisRequest,
            ProceduresRequestDiagnosisError,
            ProceduresRequestDiagnosisVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.DiagnosisRequest,
        ProceduresRequestDiagnosisError,
        ProceduresRequestDiagnosisVariables
    >({
        mutationFn: (variables: ProceduresRequestDiagnosisVariables) =>
            fetchProceduresRequestDiagnosis({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresRequestFreezeFramePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestFreezeFrameError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestFreezeFrameVariables = {
    body: RequestBodies.FreezeFrameRequest;
    pathParams: ProceduresRequestFreezeFramePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestFreezeFrame = (
    variables: ProceduresRequestFreezeFrameVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.FreezeFrameRequest,
        ProceduresRequestFreezeFrameError,
        RequestBodies.FreezeFrameRequest,
        {},
        {},
        ProceduresRequestFreezeFramePathParams
    >({ url: '/procedures/{procedureId}/request_freeze_frame/', method: 'post', ...variables, signal });

export const useProceduresRequestFreezeFrame = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.FreezeFrameRequest,
            ProceduresRequestFreezeFrameError,
            ProceduresRequestFreezeFrameVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.FreezeFrameRequest,
        ProceduresRequestFreezeFrameError,
        ProceduresRequestFreezeFrameVariables
    >({
        mutationFn: (variables: ProceduresRequestFreezeFrameVariables) =>
            fetchProceduresRequestFreezeFrame({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresRequestNbiDetectionPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestNbiDetectionError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestNbiDetectionVariables = {
    body: RequestBodies.FreezeFrameRequest;
    pathParams: ProceduresRequestNbiDetectionPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestNbiDetection = (
    variables: ProceduresRequestNbiDetectionVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.FreezeFrameRequest,
        ProceduresRequestNbiDetectionError,
        RequestBodies.FreezeFrameRequest,
        {},
        {},
        ProceduresRequestNbiDetectionPathParams
    >({ url: '/procedures/{procedureId}/request_nbi_detection/', method: 'post', ...variables, signal });

export const useProceduresRequestNbiDetection = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.FreezeFrameRequest,
            ProceduresRequestNbiDetectionError,
            ProceduresRequestNbiDetectionVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.FreezeFrameRequest,
        ProceduresRequestNbiDetectionError,
        ProceduresRequestNbiDetectionVariables
    >({
        mutationFn: (variables: ProceduresRequestNbiDetectionVariables) =>
            fetchProceduresRequestNbiDetection({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresRequestVideoHashReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestVideoHashReadError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestVideoHashReadVariables = {
    pathParams: ProceduresRequestVideoHashReadPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestVideoHashRead = (
    variables: ProceduresRequestVideoHashReadVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.Procedure4ab406b9,
        ProceduresRequestVideoHashReadError,
        undefined,
        {},
        {},
        ProceduresRequestVideoHashReadPathParams
    >({ url: '/procedures/{procedureId}/request_video_hash/', method: 'get', ...variables, signal });

export const useProceduresRequestVideoHashRead = <TData = Schemas.Procedure4ab406b9>(
    variables: ProceduresRequestVideoHashReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Procedure4ab406b9, ProceduresRequestVideoHashReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Procedure4ab406b9, ProceduresRequestVideoHashReadError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/request_video_hash/',
            operationId: 'proceduresRequestVideoHashRead',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresRequestVideoHashRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresRequestVideoHashCreatePathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresRequestVideoHashCreateError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresRequestVideoHashCreateVariables = {
    body: RequestBodies.Procedure4ab406b9;
    pathParams: ProceduresRequestVideoHashCreatePathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresRequestVideoHashCreate = (
    variables: ProceduresRequestVideoHashCreateVariables,
    signal?: AbortSignal,
) =>
    aceFetch<
        Schemas.Procedure4ab406b9,
        ProceduresRequestVideoHashCreateError,
        RequestBodies.Procedure4ab406b9,
        {},
        {},
        ProceduresRequestVideoHashCreatePathParams
    >({ url: '/procedures/{procedureId}/request_video_hash/', method: 'post', ...variables, signal });

export const useProceduresRequestVideoHashCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.Procedure4ab406b9,
            ProceduresRequestVideoHashCreateError,
            ProceduresRequestVideoHashCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.Procedure4ab406b9,
        ProceduresRequestVideoHashCreateError,
        ProceduresRequestVideoHashCreateVariables
    >({
        mutationFn: (variables: ProceduresRequestVideoHashCreateVariables) =>
            fetchProceduresRequestVideoHashCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type ProceduresStatusPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresStatusError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresStatusResponse = Schemas.Prediction[];

export type ProceduresStatusVariables = {
    pathParams: ProceduresStatusPathParams;
} & AceContext['fetcherOptions'];

/**
 * Lists all predictions related to detection_status attributes.
 */
export const fetchProceduresStatus = (variables: ProceduresStatusVariables, signal?: AbortSignal) =>
    aceFetch<ProceduresStatusResponse, ProceduresStatusError, undefined, {}, {}, ProceduresStatusPathParams>({
        url: '/procedures/{procedureId}/status/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Lists all predictions related to detection_status attributes.
 */
export const useProceduresStatus = <TData = ProceduresStatusResponse>(
    variables: ProceduresStatusVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresStatusResponse, ProceduresStatusError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresStatusResponse, ProceduresStatusError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/{procedureId}/status/', operationId: 'proceduresStatus', variables }),
        queryFn: ({ signal }) => fetchProceduresStatus({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresStreamPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresStreamError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresStreamVariables = {
    pathParams: ProceduresStreamPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint to request a JWT token that can be used to open a websocket connection with CADDIE
 * that streams real time data from the procedure if it is currently ongoing.
 */
export const fetchProceduresStream = (variables: ProceduresStreamVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.CaddieStreamToken, ProceduresStreamError, undefined, {}, {}, ProceduresStreamPathParams>({
        url: '/procedures/{procedureId}/stream/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint to request a JWT token that can be used to open a websocket connection with CADDIE
 * that streams real time data from the procedure if it is currently ongoing.
 */
export const useProceduresStream = <TData = Schemas.CaddieStreamToken>(
    variables: ProceduresStreamVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.CaddieStreamToken, ProceduresStreamError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.CaddieStreamToken, ProceduresStreamError, TData>({
        queryKey: queryKeyFn({ path: '/procedures/{procedureId}/stream/', operationId: 'proceduresStream', variables }),
        queryFn: ({ signal }) => fetchProceduresStream({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ProceduresTimelineEditsPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type ProceduresTimelineEditsError = Fetcher.ErrorWrapper<undefined>;

export type ProceduresTimelineEditsResponse = Schemas.TimelineEdit[];

export type ProceduresTimelineEditsVariables = {
    pathParams: ProceduresTimelineEditsPathParams;
} & AceContext['fetcherOptions'];

export const fetchProceduresTimelineEdits = (variables: ProceduresTimelineEditsVariables, signal?: AbortSignal) =>
    aceFetch<
        ProceduresTimelineEditsResponse,
        ProceduresTimelineEditsError,
        undefined,
        {},
        {},
        ProceduresTimelineEditsPathParams
    >({ url: '/procedures/{procedureId}/timeline_edits/', method: 'get', ...variables, signal });

export const useProceduresTimelineEdits = <TData = ProceduresTimelineEditsResponse>(
    variables: ProceduresTimelineEditsVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<ProceduresTimelineEditsResponse, ProceduresTimelineEditsError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<ProceduresTimelineEditsResponse, ProceduresTimelineEditsError, TData>({
        queryKey: queryKeyFn({
            path: '/procedures/{procedureId}/timeline_edits/',
            operationId: 'proceduresTimelineEdits',
            variables,
        }),
        queryFn: ({ signal }) => fetchProceduresTimelineEdits({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type SourcesListError = Fetcher.ErrorWrapper<undefined>;

export type SourcesListResponse = Schemas.ReportDatabase[];

export type SourcesListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for listing all available report databases.
 */
export const fetchSourcesList = (variables: SourcesListVariables, signal?: AbortSignal) =>
    aceFetch<SourcesListResponse, SourcesListError, undefined, {}, {}, {}>({
        url: '/sources/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for listing all available report databases.
 */
export const useSourcesList = <TData = SourcesListResponse>(
    variables: SourcesListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<SourcesListResponse, SourcesListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<SourcesListResponse, SourcesListError, TData>({
        queryKey: queryKeyFn({ path: '/sources/', operationId: 'sourcesList', variables }),
        queryFn: ({ signal }) => fetchSourcesList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type StreamGraphReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type StreamGraphReadQueryParams = {
    /**
     * Name of attribute key of the desired plotting data
     */
    plot?: string;
    /**
     * Name of the aggregate method used for the resampling.
     *
     * @default mean
     */
    aggregate?: 'min' | 'mean' | 'max' | 'median' | 'sum' | 'std' | 'var';
};

export type StreamGraphReadError = Fetcher.ErrorWrapper<undefined>;

export type StreamGraphReadVariables = {
    pathParams: StreamGraphReadPathParams;
    queryParams?: StreamGraphReadQueryParams;
} & AceContext['fetcherOptions'];

/**
 * Returns a data point of a plot such as "network_level" calculated
 * using pandas on the latest raw Caddie attributes data points, this way by continuously querying this endpoint
 * an approximation of the final plot can be rendered. This is useful for streaming plots since using the
 * original plot view would make points jump around a lot due to the constant fixed resampling.
 * The downside is that---by not knowing the duration of the procedure---the plot might end up being too detailed.
 */
export const fetchStreamGraphRead = (variables: StreamGraphReadVariables, signal?: AbortSignal) =>
    aceFetch<
        Schemas.GraphStream,
        StreamGraphReadError,
        undefined,
        {},
        StreamGraphReadQueryParams,
        StreamGraphReadPathParams
    >({ url: '/stream/graph/{procedureId}/', method: 'get', ...variables, signal });

/**
 * Returns a data point of a plot such as "network_level" calculated
 * using pandas on the latest raw Caddie attributes data points, this way by continuously querying this endpoint
 * an approximation of the final plot can be rendered. This is useful for streaming plots since using the
 * original plot view would make points jump around a lot due to the constant fixed resampling.
 * The downside is that---by not knowing the duration of the procedure---the plot might end up being too detailed.
 */
export const useStreamGraphRead = <TData = Schemas.GraphStream>(
    variables: StreamGraphReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.GraphStream, StreamGraphReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.GraphStream, StreamGraphReadError, TData>({
        queryKey: queryKeyFn({ path: '/stream/graph/{procedureId}/', operationId: 'streamGraphRead', variables }),
        queryFn: ({ signal }) => fetchStreamGraphRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type SummaryProceduresListError = Fetcher.ErrorWrapper<undefined>;

export type SummaryProceduresListResponse = Schemas.Procedure[];

export type SummaryProceduresListVariables = AceContext['fetcherOptions'];

/**
 * Admin endpoint for listing all procedures along with their patient_id.
 */
export const fetchSummaryProceduresList = (variables: SummaryProceduresListVariables, signal?: AbortSignal) =>
    aceFetch<SummaryProceduresListResponse, SummaryProceduresListError, undefined, {}, {}, {}>({
        url: '/summary/procedures/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Admin endpoint for listing all procedures along with their patient_id.
 */
export const useSummaryProceduresList = <TData = SummaryProceduresListResponse>(
    variables: SummaryProceduresListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<SummaryProceduresListResponse, SummaryProceduresListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<SummaryProceduresListResponse, SummaryProceduresListError, TData>({
        queryKey: queryKeyFn({ path: '/summary/procedures/', operationId: 'summaryProceduresList', variables }),
        queryFn: ({ signal }) => fetchSummaryProceduresList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type SummaryProceduresMaxDurationError = Fetcher.ErrorWrapper<undefined>;

export type SummaryProceduresMaxDurationResponse = Schemas.ProcedureB85ec314[];

export type SummaryProceduresMaxDurationVariables = AceContext['fetcherOptions'];

/**
 * Same as list but with total procedure duration computed for all procedure.
 */
export const fetchSummaryProceduresMaxDuration = (
    variables: SummaryProceduresMaxDurationVariables,
    signal?: AbortSignal,
) =>
    aceFetch<SummaryProceduresMaxDurationResponse, SummaryProceduresMaxDurationError, undefined, {}, {}, {}>({
        url: '/summary/procedures/max_duration/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Same as list but with total procedure duration computed for all procedure.
 */
export const useSummaryProceduresMaxDuration = <TData = SummaryProceduresMaxDurationResponse>(
    variables: SummaryProceduresMaxDurationVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<SummaryProceduresMaxDurationResponse, SummaryProceduresMaxDurationError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<SummaryProceduresMaxDurationResponse, SummaryProceduresMaxDurationError, TData>({
        queryKey: queryKeyFn({
            path: '/summary/procedures/max_duration/',
            operationId: 'summaryProceduresMaxDuration',
            variables,
        }),
        queryFn: ({ signal }) => fetchSummaryProceduresMaxDuration({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type SummaryProceduresWithAdenomaError = Fetcher.ErrorWrapper<undefined>;

export type SummaryProceduresWithAdenomaResponse = Schemas.Procedure[];

export type SummaryProceduresWithAdenomaVariables = AceContext['fetcherOptions'];

/**
 * Same as list but only the procedures with at least one Adenoma diagnosis.
 */
export const fetchSummaryProceduresWithAdenoma = (
    variables: SummaryProceduresWithAdenomaVariables,
    signal?: AbortSignal,
) =>
    aceFetch<SummaryProceduresWithAdenomaResponse, SummaryProceduresWithAdenomaError, undefined, {}, {}, {}>({
        url: '/summary/procedures/with_adenoma/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Same as list but only the procedures with at least one Adenoma diagnosis.
 */
export const useSummaryProceduresWithAdenoma = <TData = SummaryProceduresWithAdenomaResponse>(
    variables: SummaryProceduresWithAdenomaVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<SummaryProceduresWithAdenomaResponse, SummaryProceduresWithAdenomaError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<SummaryProceduresWithAdenomaResponse, SummaryProceduresWithAdenomaError, TData>({
        queryKey: queryKeyFn({
            path: '/summary/procedures/with_adenoma/',
            operationId: 'summaryProceduresWithAdenoma',
            variables,
        }),
        queryFn: ({ signal }) => fetchSummaryProceduresWithAdenoma({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type SummaryProceduresWithDetectionError = Fetcher.ErrorWrapper<undefined>;

export type SummaryProceduresWithDetectionResponse = Schemas.Procedure[];

export type SummaryProceduresWithDetectionVariables = AceContext['fetcherOptions'];

/**
 * Same as list but only the procedures with at least one detection object.
 */
export const fetchSummaryProceduresWithDetection = (
    variables: SummaryProceduresWithDetectionVariables,
    signal?: AbortSignal,
) =>
    aceFetch<SummaryProceduresWithDetectionResponse, SummaryProceduresWithDetectionError, undefined, {}, {}, {}>({
        url: '/summary/procedures/with_detection/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Same as list but only the procedures with at least one detection object.
 */
export const useSummaryProceduresWithDetection = <TData = SummaryProceduresWithDetectionResponse>(
    variables: SummaryProceduresWithDetectionVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<SummaryProceduresWithDetectionResponse, SummaryProceduresWithDetectionError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<SummaryProceduresWithDetectionResponse, SummaryProceduresWithDetectionError, TData>({
        queryKey: queryKeyFn({
            path: '/summary/procedures/with_detection/',
            operationId: 'summaryProceduresWithDetection',
            variables,
        }),
        queryFn: ({ signal }) => fetchSummaryProceduresWithDetection({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type TimeReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type TimeReadError = Fetcher.ErrorWrapper<undefined>;

export type TimeReadVariables = {
    pathParams: TimeReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Gives start and time.end of procedure if they exist, for an ongoing procedure the end will be estimated
 * from the last prediction written to the DB for the procedure and time.estimate will
 * be true.
 * This endpoint also gives the status of the procedure (COMPLETED, STARTED, ...), this should be
 * split to another endpoint.
 */
export const fetchTimeRead = (variables: TimeReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Time, TimeReadError, undefined, {}, {}, TimeReadPathParams>({
        url: '/time/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Gives start and time.end of procedure if they exist, for an ongoing procedure the end will be estimated
 * from the last prediction written to the DB for the procedure and time.estimate will
 * be true.
 * This endpoint also gives the status of the procedure (COMPLETED, STARTED, ...), this should be
 * split to another endpoint.
 */
export const useTimeRead = <TData = Schemas.Time>(
    variables: TimeReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Time, TimeReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Time, TimeReadError, TData>({
        queryKey: queryKeyFn({ path: '/time/{procedureId}/', operationId: 'timeRead', variables }),
        queryFn: ({ signal }) => fetchTimeRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type TokenCreateError = Fetcher.ErrorWrapper<undefined>;

export type TokenCreateVariables = {
    body: Schemas.TokenObtainPairResponse;
} & AceContext['fetcherOptions'];

export const fetchTokenCreate = (variables: TokenCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.TokenObtainPairResponse, TokenCreateError, Schemas.TokenObtainPairResponse, {}, {}, {}>({
        url: '/token/',
        method: 'post',
        ...variables,
        signal,
    });

export const useTokenCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.TokenObtainPairResponse, TokenCreateError, TokenCreateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.TokenObtainPairResponse, TokenCreateError, TokenCreateVariables>({
        mutationFn: (variables: TokenCreateVariables) => fetchTokenCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type TokenRefreshCreateError = Fetcher.ErrorWrapper<undefined>;

export type TokenRefreshCreateVariables = {
    body: Schemas.TokenRefresh;
} & AceContext['fetcherOptions'];

export const fetchTokenRefreshCreate = (variables: TokenRefreshCreateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.TokenRefreshResponse, TokenRefreshCreateError, Schemas.TokenRefresh, {}, {}, {}>({
        url: '/token/refresh/',
        method: 'post',
        ...variables,
        signal,
    });

export const useTokenRefreshCreate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.TokenRefreshResponse,
            TokenRefreshCreateError,
            TokenRefreshCreateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.TokenRefreshResponse, TokenRefreshCreateError, TokenRefreshCreateVariables>({
        mutationFn: (variables: TokenRefreshCreateVariables) =>
            fetchTokenRefreshCreate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type UserListError = Fetcher.ErrorWrapper<undefined>;

export type UserListVariables = AceContext['fetcherOptions'];

/**
 * Returns the currently logged-in user.
 */
export const fetchUserList = (variables: UserListVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.User, UserListError, undefined, {}, {}, {}>({
        url: '/user/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns the currently logged-in user.
 */
export const useUserList = <TData = Schemas.User>(
    variables: UserListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.User, UserListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.User, UserListError, TData>({
        queryKey: queryKeyFn({ path: '/user/', operationId: 'userList', variables }),
        queryFn: ({ signal }) => fetchUserList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type UserSettingsReadError = Fetcher.ErrorWrapper<undefined>;

export type UserSettingsReadVariables = AceContext['fetcherOptions'];

export const fetchUserSettingsRead = (variables: UserSettingsReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AccountSettings, UserSettingsReadError, undefined, {}, {}, {}>({
        url: '/user/settings/',
        method: 'get',
        ...variables,
        signal,
    });

export const useUserSettingsRead = <TData = Schemas.AccountSettings>(
    variables: UserSettingsReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AccountSettings, UserSettingsReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AccountSettings, UserSettingsReadError, TData>({
        queryKey: queryKeyFn({ path: '/user/settings/', operationId: 'userSettingsRead', variables }),
        queryFn: ({ signal }) => fetchUserSettingsRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type UserSettingsUpdateError = Fetcher.ErrorWrapper<undefined>;

export type UserSettingsUpdateVariables = {
    body?: RequestBodies.AccountSettings;
} & AceContext['fetcherOptions'];

export const fetchUserSettingsUpdate = (variables: UserSettingsUpdateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AccountSettings, UserSettingsUpdateError, RequestBodies.AccountSettings, {}, {}, {}>({
        url: '/user/settings/',
        method: 'put',
        ...variables,
        signal,
    });

export const useUserSettingsUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<Schemas.AccountSettings, UserSettingsUpdateError, UserSettingsUpdateVariables>,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<Schemas.AccountSettings, UserSettingsUpdateError, UserSettingsUpdateVariables>({
        mutationFn: (variables: UserSettingsUpdateVariables) =>
            fetchUserSettingsUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type UserSettingsPartialUpdateError = Fetcher.ErrorWrapper<undefined>;

export type UserSettingsPartialUpdateVariables = {
    body?: RequestBodies.AccountSettings;
} & AceContext['fetcherOptions'];

export const fetchUserSettingsPartialUpdate = (variables: UserSettingsPartialUpdateVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AccountSettings, UserSettingsPartialUpdateError, RequestBodies.AccountSettings, {}, {}, {}>({
        url: '/user/settings/',
        method: 'patch',
        ...variables,
        signal,
    });

export const useUserSettingsPartialUpdate = (
    options?: Omit<
        reactQuery.UseMutationOptions<
            Schemas.AccountSettings,
            UserSettingsPartialUpdateError,
            UserSettingsPartialUpdateVariables
        >,
        'mutationFn'
    >,
) => {
    const { fetcherOptions } = useAceContext();
    return reactQuery.useMutation<
        Schemas.AccountSettings,
        UserSettingsPartialUpdateError,
        UserSettingsPartialUpdateVariables
    >({
        mutationFn: (variables: UserSettingsPartialUpdateVariables) =>
            fetchUserSettingsPartialUpdate({ ...fetcherOptions, ...variables }),
        ...options,
    });
};

export type UsersListError = Fetcher.ErrorWrapper<undefined>;

export type UsersListResponse = Schemas.UserInfo[];

export type UsersListVariables = AceContext['fetcherOptions'];

export const fetchUsersList = (variables: UsersListVariables, signal?: AbortSignal) =>
    aceFetch<UsersListResponse, UsersListError, undefined, {}, {}, {}>({
        url: '/users/',
        method: 'get',
        ...variables,
        signal,
    });

export const useUsersList = <TData = UsersListResponse>(
    variables: UsersListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<UsersListResponse, UsersListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<UsersListResponse, UsersListError, TData>({
        queryKey: queryKeyFn({ path: '/users/', operationId: 'usersList', variables }),
        queryFn: ({ signal }) => fetchUsersList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type VideoReadPathParams = {
    /**
     * Unique identifier of the procedure.
     */
    procedureId: string;
};

export type VideoReadError = Fetcher.ErrorWrapper<undefined>;

export type VideoReadVariables = {
    pathParams: VideoReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Gives the procedure video (if it exists) with a signed url to the recording bucket, the start and end
 * timestamps, the detection area used in caddie, and the width and height of the video.
 * If the video cannot be found, a reason other than "OK" will be provided.
 */
export const fetchVideoRead = (variables: VideoReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.Video, VideoReadError, undefined, {}, {}, VideoReadPathParams>({
        url: '/video/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Gives the procedure video (if it exists) with a signed url to the recording bucket, the start and end
 * timestamps, the detection area used in caddie, and the width and height of the video.
 * If the video cannot be found, a reason other than "OK" will be provided.
 */
export const useVideoRead = <TData = Schemas.Video>(
    variables: VideoReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.Video, VideoReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.Video, VideoReadError, TData>({
        queryKey: queryKeyFn({ path: '/video/{procedureId}/', operationId: 'videoRead', variables }),
        queryFn: ({ signal }) => fetchVideoRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type VideoHashesListError = Fetcher.ErrorWrapper<undefined>;

export type VideoHashesListResponse = Schemas.VideoHash[];

export type VideoHashesListVariables = AceContext['fetcherOptions'];

/**
 * Endpoint for managing video hashes.
 */
export const fetchVideoHashesList = (variables: VideoHashesListVariables, signal?: AbortSignal) =>
    aceFetch<VideoHashesListResponse, VideoHashesListError, undefined, {}, {}, {}>({
        url: '/video_hashes/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing video hashes.
 */
export const useVideoHashesList = <TData = VideoHashesListResponse>(
    variables: VideoHashesListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<VideoHashesListResponse, VideoHashesListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<VideoHashesListResponse, VideoHashesListError, TData>({
        queryKey: queryKeyFn({ path: '/video_hashes/', operationId: 'videoHashesList', variables }),
        queryFn: ({ signal }) => fetchVideoHashesList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type VideoHashesCaddieSummaryError = Fetcher.ErrorWrapper<undefined>;

export type VideoHashesCaddieSummaryResponse = Schemas.VideoHashSummary[];

export type VideoHashesCaddieSummaryVariables = AceContext['fetcherOptions'];

/**
 * Returns a list of uploaded videos hashes with patient id and hospital name.
 */
export const fetchVideoHashesCaddieSummary = (variables: VideoHashesCaddieSummaryVariables, signal?: AbortSignal) =>
    aceFetch<VideoHashesCaddieSummaryResponse, VideoHashesCaddieSummaryError, undefined, {}, {}, {}>({
        url: '/video_hashes/caddie_summary/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns a list of uploaded videos hashes with patient id and hospital name.
 */
export const useVideoHashesCaddieSummary = <TData = VideoHashesCaddieSummaryResponse>(
    variables: VideoHashesCaddieSummaryVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<VideoHashesCaddieSummaryResponse, VideoHashesCaddieSummaryError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<VideoHashesCaddieSummaryResponse, VideoHashesCaddieSummaryError, TData>({
        queryKey: queryKeyFn({
            path: '/video_hashes/caddie_summary/',
            operationId: 'videoHashesCaddieSummary',
            variables,
        }),
        queryFn: ({ signal }) => fetchVideoHashesCaddieSummary({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type VideoHashesReadPathParams = {
    /**
     * UUID of the procedure on which the video was uploaded
     */
    procedureId: string;
};

export type VideoHashesReadError = Fetcher.ErrorWrapper<undefined>;

export type VideoHashesReadVariables = {
    pathParams: VideoHashesReadPathParams;
} & AceContext['fetcherOptions'];

/**
 * Endpoint for managing video hashes.
 */
export const fetchVideoHashesRead = (variables: VideoHashesReadVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.VideoHash, VideoHashesReadError, undefined, {}, {}, VideoHashesReadPathParams>({
        url: '/video_hashes/{procedureId}/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Endpoint for managing video hashes.
 */
export const useVideoHashesRead = <TData = Schemas.VideoHash>(
    variables: VideoHashesReadVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.VideoHash, VideoHashesReadError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.VideoHash, VideoHashesReadError, TData>({
        queryKey: queryKeyFn({ path: '/video_hashes/{procedureId}/', operationId: 'videoHashesRead', variables }),
        queryFn: ({ signal }) => fetchVideoHashesRead({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type ViewListError = Fetcher.ErrorWrapper<undefined>;

export type ViewListVariables = AceContext['fetcherOptions'];

/**
 * Returns a list of available components for the current logged-in user (admin can restrict the view
 * of certain elements for specific users).
 */
export const fetchViewList = (variables: ViewListVariables, signal?: AbortSignal) =>
    aceFetch<Schemas.AvailableComponents, ViewListError, undefined, {}, {}, {}>({
        url: '/view/',
        method: 'get',
        ...variables,
        signal,
    });

/**
 * Returns a list of available components for the current logged-in user (admin can restrict the view
 * of certain elements for specific users).
 */
export const useViewList = <TData = Schemas.AvailableComponents>(
    variables: ViewListVariables,
    options?: Omit<
        reactQuery.UseQueryOptions<Schemas.AvailableComponents, ViewListError, TData>,
        'queryKey' | 'queryFn' | 'initialData'
    >,
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useAceContext(options);
    return reactQuery.useQuery<Schemas.AvailableComponents, ViewListError, TData>({
        queryKey: queryKeyFn({ path: '/view/', operationId: 'viewList', variables }),
        queryFn: ({ signal }) => fetchViewList({ ...fetcherOptions, ...variables }, signal),
        ...options,
        ...queryOptions,
    });
};

export type QueryOperation =
    | {
          path: '/assessments/annotation_categories/';
          operationId: 'assessmentsAnnotationCategoriesList';
          variables: AssessmentsAnnotationCategoriesListVariables;
      }
    | {
          path: '/assessments/annotation_categories/{id}/';
          operationId: 'assessmentsAnnotationCategoriesRead';
          variables: AssessmentsAnnotationCategoriesReadVariables;
      }
    | {
          path: '/assessments/assessment_fields/';
          operationId: 'assessmentsAssessmentFieldsList';
          variables: AssessmentsAssessmentFieldsListVariables;
      }
    | {
          path: '/assessments/assessment_fields/{id}/';
          operationId: 'assessmentsAssessmentFieldsRead';
          variables: AssessmentsAssessmentFieldsReadVariables;
      }
    | {
          path: '/assessments/cases/';
          operationId: 'assessmentsCasesList';
          variables: AssessmentsCasesListVariables;
      }
    | {
          path: '/assessments/cases/by_procedure_id/';
          operationId: 'assessmentsCasesByProcedureId';
          variables: AssessmentsCasesByProcedureIdVariables;
      }
    | {
          path: '/assessments/cases/get_assessment_types/';
          operationId: 'assessmentsCasesGetAssessmentTypes';
          variables: AssessmentsCasesGetAssessmentTypesVariables;
      }
    | {
          path: '/assessments/cases/{id}/';
          operationId: 'assessmentsCasesRead';
          variables: AssessmentsCasesReadVariables;
      }
    | {
          path: '/assessments/cases/{id}/get_assessment_answers/';
          operationId: 'assessmentsCasesGetAssessmentAnswers';
          variables: AssessmentsCasesGetAssessmentAnswersVariables;
      }
    | {
          path: '/assessments/live_forms/';
          operationId: 'assessmentsLiveFormsList';
          variables: AssessmentsLiveFormsListVariables;
      }
    | {
          path: '/assessments/live_forms/{id}/';
          operationId: 'assessmentsLiveFormsRead';
          variables: AssessmentsLiveFormsReadVariables;
      }
    | {
          path: '/authorized/{procedureId}/';
          operationId: 'authorizedRead';
          variables: AuthorizedReadVariables;
      }
    | {
          path: '/avg/{procedureId}/';
          operationId: 'avgRead';
          variables: AvgReadVariables;
      }
    | {
          path: '/billing/';
          operationId: 'billingList';
          variables: BillingListVariables;
      }
    | {
          path: '/billing/{username}/';
          operationId: 'billingRead';
          variables: BillingReadVariables;
      }
    | {
          path: '/changelog/';
          operationId: 'changelogList';
          variables: ChangelogListVariables;
      }
    | {
          path: '/clinicalreport/eagle/';
          operationId: 'clinicalreportEagleList';
          variables: ClinicalreportEagleListVariables;
      }
    | {
          path: '/clinicalreport/eagle/download_patient_excel/';
          operationId: 'clinicalreportEagleDownloadPatientExcel';
          variables: ClinicalreportEagleDownloadPatientExcelVariables;
      }
    | {
          path: '/clinicalreport/eagle/download_report_excel/';
          operationId: 'clinicalreportEagleDownloadReportExcel';
          variables: ClinicalreportEagleDownloadReportExcelVariables;
      }
    | {
          path: '/clinicalreport/eagle/endoscopist_summary/';
          operationId: 'clinicalreportEagleEndoscopistSummary';
          variables: ClinicalreportEagleEndoscopistSummaryVariables;
      }
    | {
          path: '/clinicalreport/eagle/extended/';
          operationId: 'clinicalreportEagleExtended';
          variables: ClinicalreportEagleExtendedVariables;
      }
    | {
          path: '/clinicalreport/eagle/generate_patient_excel/';
          operationId: 'clinicalreportEagleGeneratePatientExcel';
          variables: ClinicalreportEagleGeneratePatientExcelVariables;
      }
    | {
          path: '/clinicalreport/eagle/get_total_recruitment/';
          operationId: 'clinicalreportEagleGetTotalRecruitment';
          variables: ClinicalreportEagleGetTotalRecruitmentVariables;
      }
    | {
          path: '/clinicalreport/eagle/histology_stats/';
          operationId: 'clinicalreportEagleHistologyStats';
          variables: ClinicalreportEagleHistologyStatsVariables;
      }
    | {
          path: '/clinicalreport/eagle/hospital_summary/';
          operationId: 'clinicalreportEagleHospitalSummary';
          variables: ClinicalreportEagleHospitalSummaryVariables;
      }
    | {
          path: '/clinicalreport/eagle/ignored_issues/';
          operationId: 'clinicalreportEagleIgnoredIssues';
          variables: ClinicalreportEagleIgnoredIssuesVariables;
      }
    | {
          path: '/clinicalreport/eagle/procedure_dates_by_site/';
          operationId: 'clinicalreportEagleProcedureDatesBySite';
          variables: ClinicalreportEagleProcedureDatesBySiteVariables;
      }
    | {
          path: '/clinicalreport/eagle/trial_summary_stats/';
          operationId: 'clinicalreportEagleTrialSummaryStats';
          variables: ClinicalreportEagleTrialSummaryStatsVariables;
      }
    | {
          path: '/clinicalreport/eagle/with_task_status/';
          operationId: 'clinicalreportEagleWithTaskStatus';
          variables: ClinicalreportEagleWithTaskStatusVariables;
      }
    | {
          path: '/clinicalreport/eagle/{id}/';
          operationId: 'clinicalreportEagleRead';
          variables: ClinicalreportEagleReadVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/';
          operationId: 'clinicalreportGgpatientList';
          variables: ClinicalreportGgpatientListVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/from_procedure_id/';
          operationId: 'clinicalreportGgpatientFromProcedureId';
          variables: ClinicalreportGgpatientFromProcedureIdVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/ignored_issues/';
          operationId: 'clinicalreportGgpatientIgnoredIssues';
          variables: ClinicalreportGgpatientIgnoredIssuesVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/related_comments/';
          operationId: 'clinicalreportGgpatientRelatedComments';
          variables: ClinicalreportGgpatientRelatedCommentsVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/related_procedures/';
          operationId: 'clinicalreportGgpatientRelatedProcedures';
          variables: ClinicalreportGgpatientRelatedProceduresVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/{id}/';
          operationId: 'clinicalreportGgpatientRead';
          variables: ClinicalreportGgpatientReadVariables;
      }
    | {
          path: '/clinicalreport/ggpatient/{id}/polyp_diagnosis/';
          operationId: 'clinicalreportGgpatientPolypDiagnosis';
          variables: ClinicalreportGgpatientPolypDiagnosisVariables;
      }
    | {
          path: '/comments/';
          operationId: 'commentsList';
          variables: CommentsListVariables;
      }
    | {
          path: '/comments/{id}/';
          operationId: 'commentsRead';
          variables: CommentsReadVariables;
      }
    | {
          path: '/dashboard_components/';
          operationId: 'dashboardComponentsList';
          variables: DashboardComponentsListVariables;
      }
    | {
          path: '/dashboard_components/{id}/';
          operationId: 'dashboardComponentsRead';
          variables: DashboardComponentsReadVariables;
      }
    | {
          path: '/dashboards/';
          operationId: 'dashboardsList';
          variables: DashboardsListVariables;
      }
    | {
          path: '/dashboards/{id}/';
          operationId: 'dashboardsRead';
          variables: DashboardsReadVariables;
      }
    | {
          path: '/debug/graph/{procedureId}/';
          operationId: 'debugGraphRead';
          variables: DebugGraphReadVariables;
      }
    | {
          path: '/doctors/';
          operationId: 'doctorsList';
          variables: DoctorsListVariables;
      }
    | {
          path: '/doctors/{id}/';
          operationId: 'doctorsRead';
          variables: DoctorsReadVariables;
      }
    | {
          path: '/download/annotation/{procedureId}/';
          operationId: 'downloadAnnotationRead';
          variables: DownloadAnnotationReadVariables;
      }
    | {
          path: '/download/metadata/{procedureId}/';
          operationId: 'downloadMetadataRead';
          variables: DownloadMetadataReadVariables;
      }
    | {
          path: '/download/{procedureId}/';
          operationId: 'downloadRead';
          variables: DownloadReadVariables;
      }
    | {
          path: '/edits/timeline/';
          operationId: 'editsTimelineList';
          variables: EditsTimelineListVariables;
      }
    | {
          path: '/edits/timeline/{id}/';
          operationId: 'editsTimelineRead';
          variables: EditsTimelineReadVariables;
      }
    | {
          path: '/empty/{procedureId}/';
          operationId: 'emptyRead';
          variables: EmptyReadVariables;
      }
    | {
          path: '/extra/';
          operationId: 'extraList';
          variables: ExtraListVariables;
      }
    | {
          path: '/extra/missing/';
          operationId: 'extraMissing';
          variables: ExtraMissingVariables;
      }
    | {
          path: '/extra/track/';
          operationId: 'extraTrackList';
          variables: ExtraTrackListVariables;
      }
    | {
          path: '/extra_models/';
          operationId: 'extraModelsList';
          variables: ExtraModelsListVariables;
      }
    | {
          path: '/extra_models/{id}/';
          operationId: 'extraModelsRead';
          variables: ExtraModelsReadVariables;
      }
    | {
          path: '/find/';
          operationId: 'findList';
          variables: FindListVariables;
      }
    | {
          path: '/find/with_case_status/';
          operationId: 'findWithCaseStatus';
          variables: FindWithCaseStatusVariables;
      }
    | {
          path: '/frames/{procedureId}/';
          operationId: 'framesRead';
          variables: FramesReadVariables;
      }
    | {
          path: '/full_frames/{procedureId}/';
          operationId: 'fullFramesRead';
          variables: FullFramesReadVariables;
      }
    | {
          path: '/gg/check_connection/{app}';
          operationId: 'ggCheckConnectionRead';
          variables: GgCheckConnectionReadVariables;
      }
    | {
          path: '/gg/clear_cache';
          operationId: 'ggClearCacheList';
          variables: GgClearCacheListVariables;
      }
    | {
          path: '/gg/enrollments/{app}';
          operationId: 'ggEnrollmentsRead';
          variables: GgEnrollmentsReadVariables;
      }
    | {
          path: '/gg/explore/';
          operationId: 'ggExploreList';
          variables: GgExploreListVariables;
      }
    | {
          path: '/gg/export_package/{app}';
          operationId: 'ggExportPackageRead';
          variables: GgExportPackageReadVariables;
      }
    | {
          path: '/gg/form_answers/{app}';
          operationId: 'ggFormAnswersRead';
          variables: GgFormAnswersReadVariables;
      }
    | {
          path: '/gg/forms/{app}';
          operationId: 'ggFormsRead';
          variables: GgFormsReadVariables;
      }
    | {
          path: '/gg/oldest_cached_data';
          operationId: 'ggOldestCachedDataList';
          variables: GgOldestCachedDataListVariables;
      }
    | {
          path: '/gg/processes/{app}';
          operationId: 'ggProcessesRead';
          variables: GgProcessesReadVariables;
      }
    | {
          path: '/gg/sites/{app}';
          operationId: 'ggSitesRead';
          variables: GgSitesReadVariables;
      }
    | {
          path: '/gg/subjects/{app}';
          operationId: 'ggSubjectsRead';
          variables: GgSubjectsReadVariables;
      }
    | {
          path: '/gg/summary/';
          operationId: 'ggSummaryList';
          variables: GgSummaryListVariables;
      }
    | {
          path: '/gg/summary/diagnosis_polyp_data/';
          operationId: 'ggSummaryDiagnosisPolypData';
          variables: GgSummaryDiagnosisPolypDataVariables;
      }
    | {
          path: '/gg/summary/histology_polyp_data/';
          operationId: 'ggSummaryHistologyPolypData';
          variables: GgSummaryHistologyPolypDataVariables;
      }
    | {
          path: '/graph/{procedureId}/';
          operationId: 'graphRead';
          variables: GraphReadVariables;
      }
    | {
          path: '/group/metagroups/';
          operationId: 'groupMetagroupsList';
          variables: GroupMetagroupsListVariables;
      }
    | {
          path: '/group/test/users/';
          operationId: 'groupTestUsersList';
          variables: GroupTestUsersListVariables;
      }
    | {
          path: '/group/types/';
          operationId: 'groupTypesList';
          variables: GroupTypesListVariables;
      }
    | {
          path: '/group/users/';
          operationId: 'groupUsersList';
          variables: GroupUsersListVariables;
      }
    | {
          path: '/groups/';
          operationId: 'groupsList';
          variables: GroupsListVariables;
      }
    | {
          path: '/hospitals/';
          operationId: 'hospitalsList';
          variables: HospitalsListVariables;
      }
    | {
          path: '/hospitals/{id}/';
          operationId: 'hospitalsRead';
          variables: HospitalsReadVariables;
      }
    | {
          path: '/images/{procedureId}/';
          operationId: 'imagesRead';
          variables: ImagesReadVariables;
      }
    | {
          path: '/mouse/dagruns/';
          operationId: 'mouseDagrunsList';
          variables: MouseDagrunsListVariables;
      }
    | {
          path: '/mouse/dagruns/{id}/';
          operationId: 'mouseDagrunsRead';
          variables: MouseDagrunsReadVariables;
      }
    | {
          path: '/notifications/';
          operationId: 'notificationsList';
          variables: NotificationsListVariables;
      }
    | {
          path: '/notifications/{id}/';
          operationId: 'notificationsRead';
          variables: NotificationsReadVariables;
      }
    | {
          path: '/performance/search/';
          operationId: 'performanceSearch';
          variables: PerformanceSearchVariables;
      }
    | {
          path: '/procedures/download_co_creation_data/';
          operationId: 'proceduresDownloadCoCreationDataRead';
          variables: ProceduresDownloadCoCreationDataReadVariables;
      }
    | {
          path: '/procedures/download_merged_histology_data/';
          operationId: 'proceduresDownloadMergedHistologyDataRead';
          variables: ProceduresDownloadMergedHistologyDataReadVariables;
      }
    | {
          path: '/procedures/download_polyp_timeline_annotations/';
          operationId: 'proceduresDownloadPolypTimelineAnnotationsRead';
          variables: ProceduresDownloadPolypTimelineAnnotationsReadVariables;
      }
    | {
          path: '/procedures/latest/';
          operationId: 'proceduresLatest';
          variables: ProceduresLatestVariables;
      }
    | {
          path: '/procedures/quick_search/';
          operationId: 'proceduresQuickSearch';
          variables: ProceduresQuickSearchVariables;
      }
    | {
          path: '/procedures/{procedureId}/';
          operationId: 'proceduresRead';
          variables: ProceduresReadVariables;
      }
    | {
          path: '/procedures/{procedureId}/caecum/';
          operationId: 'proceduresCaecum';
          variables: ProceduresCaecumVariables;
      }
    | {
          path: '/procedures/{procedureId}/caecum_debug/';
          operationId: 'proceduresCaecumDebug';
          variables: ProceduresCaecumDebugVariables;
      }
    | {
          path: '/procedures/{procedureId}/comments/';
          operationId: 'proceduresComments';
          variables: ProceduresCommentsVariables;
      }
    | {
          path: '/procedures/{procedureId}/detections/';
          operationId: 'proceduresDetections';
          variables: ProceduresDetectionsVariables;
      }
    | {
          path: '/procedures/{procedureId}/detections_polygon/';
          operationId: 'proceduresDetectionsPolygon';
          variables: ProceduresDetectionsPolygonVariables;
      }
    | {
          path: '/procedures/{procedureId}/doctor/';
          operationId: 'proceduresDoctorRead';
          variables: ProceduresDoctorReadVariables;
      }
    | {
          path: '/procedures/{procedureId}/events/';
          operationId: 'proceduresEvents';
          variables: ProceduresEventsVariables;
      }
    | {
          path: '/procedures/{procedureId}/extra_detections/';
          operationId: 'proceduresExtraDetections';
          variables: ProceduresExtraDetectionsVariables;
      }
    | {
          path: '/procedures/{procedureId}/false_alarms/';
          operationId: 'proceduresFalseAlarms';
          variables: ProceduresFalseAlarmsVariables;
      }
    | {
          path: '/procedures/{procedureId}/freeze_frames/';
          operationId: 'proceduresFreezeFrames';
          variables: ProceduresFreezeFramesVariables;
      }
    | {
          path: '/procedures/{procedureId}/generate_annotations_xml/';
          operationId: 'proceduresGenerateAnnotationsXmlRead';
          variables: ProceduresGenerateAnnotationsXmlReadVariables;
      }
    | {
          path: '/procedures/{procedureId}/logs/';
          operationId: 'proceduresLogs';
          variables: ProceduresLogsVariables;
      }
    | {
          path: '/procedures/{procedureId}/predictions/';
          operationId: 'proceduresPredictions';
          variables: ProceduresPredictionsVariables;
      }
    | {
          path: '/procedures/{procedureId}/recordings/';
          operationId: 'proceduresRecordings';
          variables: ProceduresRecordingsVariables;
      }
    | {
          path: '/procedures/{procedureId}/request_cat_upload/';
          operationId: 'proceduresRequestCatUploadRead';
          variables: ProceduresRequestCatUploadReadVariables;
      }
    | {
          path: '/procedures/{procedureId}/request_video_hash/';
          operationId: 'proceduresRequestVideoHashRead';
          variables: ProceduresRequestVideoHashReadVariables;
      }
    | {
          path: '/procedures/{procedureId}/status/';
          operationId: 'proceduresStatus';
          variables: ProceduresStatusVariables;
      }
    | {
          path: '/procedures/{procedureId}/stream/';
          operationId: 'proceduresStream';
          variables: ProceduresStreamVariables;
      }
    | {
          path: '/procedures/{procedureId}/timeline_edits/';
          operationId: 'proceduresTimelineEdits';
          variables: ProceduresTimelineEditsVariables;
      }
    | {
          path: '/sources/';
          operationId: 'sourcesList';
          variables: SourcesListVariables;
      }
    | {
          path: '/stream/graph/{procedureId}/';
          operationId: 'streamGraphRead';
          variables: StreamGraphReadVariables;
      }
    | {
          path: '/summary/procedures/';
          operationId: 'summaryProceduresList';
          variables: SummaryProceduresListVariables;
      }
    | {
          path: '/summary/procedures/max_duration/';
          operationId: 'summaryProceduresMaxDuration';
          variables: SummaryProceduresMaxDurationVariables;
      }
    | {
          path: '/summary/procedures/with_adenoma/';
          operationId: 'summaryProceduresWithAdenoma';
          variables: SummaryProceduresWithAdenomaVariables;
      }
    | {
          path: '/summary/procedures/with_detection/';
          operationId: 'summaryProceduresWithDetection';
          variables: SummaryProceduresWithDetectionVariables;
      }
    | {
          path: '/time/{procedureId}/';
          operationId: 'timeRead';
          variables: TimeReadVariables;
      }
    | {
          path: '/user/';
          operationId: 'userList';
          variables: UserListVariables;
      }
    | {
          path: '/user/settings/';
          operationId: 'userSettingsRead';
          variables: UserSettingsReadVariables;
      }
    | {
          path: '/users/';
          operationId: 'usersList';
          variables: UsersListVariables;
      }
    | {
          path: '/video/{procedureId}/';
          operationId: 'videoRead';
          variables: VideoReadVariables;
      }
    | {
          path: '/video_hashes/';
          operationId: 'videoHashesList';
          variables: VideoHashesListVariables;
      }
    | {
          path: '/video_hashes/caddie_summary/';
          operationId: 'videoHashesCaddieSummary';
          variables: VideoHashesCaddieSummaryVariables;
      }
    | {
          path: '/video_hashes/{procedureId}/';
          operationId: 'videoHashesRead';
          variables: VideoHashesReadVariables;
      }
    | {
          path: '/view/';
          operationId: 'viewList';
          variables: ViewListVariables;
      };
